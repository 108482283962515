import gql from 'graphql-tag'

export const GET_AGENCY_BOUNDARY = gql`
  query getAgencyBoundary($agencyName: String!) {
    getAgencyBoundary(agencyName: $agencyName) {
      type
      geometry {
        type
        coordinates
      }
      properties
    }
  }
`

// This gets all feature_sets and agency_boundary readable by the user's agency. We don't need to
// pass in the agencyName here since hasura's permissions restrict the user
// to read only the feature_sets it has permissions to read.
export const GET_AGENCY_RESOURCES = gql`
  query getAgencyResources {
    agency_boundary {
      geom
    }
    feature_set {
      data_source_name
      display_field
      display_name
      guid
      feature_schema
      editable
      rel_agency_feature_sets {
        agency_name
        can_write_features
        is_owner
      }
    }
  }
`

export const GET_FEATURE = gql`
  query getFeature($featureGuid: String!) {
    getFeature(featureGuid: $featureGuid) {
      geometry
      properties
      featureGuid
      featureSetGuid
      modifiedDate
      modifiedUserEmail
      # modifiedUserGuid
      originId
      version
      persistedDate
    }
  }
`

export const LIST_ATTACHMENTS_BY_FEATURE_GUID = gql`
  query listAttachmentsByFeatureGuid($featureGuid: String!) {
    listAttachmentsByFeatureGuid(featureGuid: $featureGuid) {
      attachmentGuid
      fileName
      fileType
      featurePropertyName
      uploadedUserId
      uploadedUserAgency
      uploadedDate
    }
  }
`
