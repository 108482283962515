/* @flow */
import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import * as Immutable from 'immutable'

const alertWrapperStyle = {
  position: 'fixed',
  top: '0px',
  width: '100%',
  height: '100%',
  zIndex: 40,
  backgroundColor: 'rgba(0,0,0,0.2)',
  overflowY: 'scroll',
  pointerEvents: 'all',
}

const alertStyle = {
  position: 'relative',
  backgroundColor: 'white',
  color: 'black',
  fontSize: '1.1rem',
  borderRadius: '8px',
  marginLeft: 'auto',
  marginRight: 'auto',
  boxShadow: '0 0 5px black',
  width: '400px',
  paddingTop: '1.5rem',
  top: '50%',
  transform: 'translateY(-50%)',
}

const titleStyle = {
  fontSize: '1.5rem',
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  paddingBottom: '1rem',
  textAlign: 'center',
}

const bodyStyle = {
  fontSize: '0.9rem',
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  paddingBottom: '1.1rem',
}

const buttonGroupStyle = {
  padding: '1.1rem',
  paddingTop: '1.1rem',
  textAlign: 'right',
  borderTop: '1px solid #aaa',
}

type Props = {
  interpolatedStyles: Object,
  dismissAlert: Function,
  alertName: string,
  title: ?string,
  message: ?string,
  options: Immutable.List<any>,
}

const Alert = ({ interpolatedStyles, dismissAlert, alertName, title, message, options }: Props) => (
  <div style={{ ...alertWrapperStyle, opacity: interpolatedStyles ? interpolatedStyles.opacity : null }}>
    <div style={alertStyle}>
      {title ? <div style={titleStyle}>{title}</div> : null}
      {message ? <div style={bodyStyle}>{message}</div> : null}
      <div style={buttonGroupStyle}>
        <ButtonGroup>
          {options.map((option, index) => (
            <Button key={index} onClick={() => dismissAlert(alertName, index)}>
              {option}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </div>
  </div>
)

export default Alert
