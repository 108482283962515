/* @flow */
import React from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import Modal from './SimpleModal'

const mapStateToProps = state => {
  return {
    userEmail: state.auth.user.email,
    agencyDisplayName: state.auth.agency ? state.auth.agency.get('displayName') : null,
  }
}

type Props = {
  close: Function,
  submitSignOut: Function,
  userEmail: ?string,
  agencyDisplayName: ?string,
  interpolatedStyles: Object,
}

export const AccountModal = ({ close, submitSignOut, userEmail, agencyDisplayName, interpolatedStyles }: Props) => (
  <Modal interpolatedStyles={interpolatedStyles} title="Account" closeModal={close}>
    <p>{`Email:  ${userEmail || ''}`}</p>
    <p>{`Agency:  ${agencyDisplayName || ''}`}</p>
    <Button onClick={submitSignOut}>Sign Out</Button>
  </Modal>
)

export default connect(mapStateToProps)(AccountModal)
