import { all, call, put, takeEvery } from 'redux-saga/effects'
import { makePattern } from './utils'
import { setServerTimeOffset } from '../actions/FirebaseActions'
import * as types from '../constants/ActionTypes'
import { firebaseRoot } from '../firebase'

function* getServerTimeOffset() {
  const serverTimeOffsetSnap = yield call(() => firebaseRoot.child('.info/serverTimeOffset').once('value'))
  const serverTimeOffset = serverTimeOffsetSnap.val()
  yield put(setServerTimeOffset(serverTimeOffset || 0))
}

export default function* firebaseSaga() {
  yield all([
    takeEvery(types.APP_START, getServerTimeOffset),
    takeEvery(makePattern(types.APP_STATE_CHANGED, { appState: 'active' }), getServerTimeOffset),
  ])
}
