import { delay } from 'redux-saga'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../constants/ActionTypes'
import { clearToastMessage } from '../../common/actions/ToastActions'

export function* handleSetToastMessage(action) {
  yield call(delay, action.duration)
  yield put(clearToastMessage())
}

export default function* toastSaga() {
  yield all([takeLatest(types.SET_TOAST_MESSAGE, handleSetToastMessage)])
}
