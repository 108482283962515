/* @flow */
import * as Immutable from 'immutable'

export const LocationProviderRecord = Immutable.Record({
  always: false,
  enabled: false,
  gps: false,
  network: false,
})

export default LocationProviderRecord
