/* @flow */
import React from 'react'
import { bindActionCreators } from 'redux'
import { Text, View, StyleSheet, TouchableHighlight } from 'react-native-web'
import { connect } from 'react-redux'
import * as selectors from '../../common/selectors'
import * as alertTypes from '../../common/constants/AlertTypes'
import {
  createAlert,
  popModal,
  openModalComponent,
  closeDetailWindow,
  openEditWindow,
  openGeometryEditingWindow,
  featureUnselected,
} from '../../common/actions'
import { Glyphicon } from 'react-bootstrap'
import { TOOLBAR_BACKGROUND_COLOR, GLYPH_COLOR, BUTTON_COLOR_HOVER } from '../styles'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: 55,
    justifyContent: 'flex-start',
    backgroundColor: TOOLBAR_BACKGROUND_COLOR,
  },
  toolbar: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flex: 1,
  },
  button: {
    flexDirection: 'row',
  },
  buttonView: {
    flexDirection: 'row',
    marginTop: 6,
    marginRight: 16,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
  },
  closeButtonView: {
    flexDirection: 'row',
    marginTop: 6,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 14,
    paddingRight: 20,
  },
  text: {
    fontSize: 22,
    justifyContent: 'center',
  },
  glyph: {
    justifyContent: 'center',
    fontSize: 22,
    marginLeft: 8,
    marginRight: 8,
  },
})

const mapStateToProps = state => {
  return {
    editMode: selectors.getEditMode(state),
    currentFeatureObject: selectors.getCurrentFeature(state),
    featureIsEditable: selectors.getCurrentFeatureIsEditable(state),
    gisDataSources: selectors.getGisDataSources(state),
    propertyOrder: selectors.getCurrentFeaturePropertyOrder(state),
    agencyName: selectors.getAgencyName(state),
    isTestAccountForRealAgency: selectors.getIsTestAccountForRealAgency(state),
    fetchFailure: selectors.getFetchFeatureStatus(state),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeDetailWindow,
      openEditWindow,
      openGeometryEditingWindow,
      createAlert,
      openModalComponent,
      popModal,
      featureUnselected,
    },
    dispatch
  )

type Props = {
  editMode: string,
  isTestAccountForRealAgency: boolean,
  agencyName: string,
  openEditWindow: Function,
  featureUnselected: Function,
  openGeometryEditingWindow: Function,
  closeDetailWindow: Function,
  featureIsEditable: boolean,
  createAlert: Function,
  submitForm: Function,
  fetchFailure: boolean,
}

class EditPanel extends React.PureComponent<Props> {
  render = () => {
    const { editMode } = this.props

    return (
      <View style={styles.container}>
        <View style={styles.toolbar}>
          {editMode === 'EDIT_ATTRIBUTES' ? (
            <TouchableHighlight
              className="edit-button"
              onPress={this.props.submitForm}
              underlayColor={BUTTON_COLOR_HOVER}
              style={styles.button}
            >
              <View style={styles.buttonView}>
                <Glyphicon
                  glyph="floppy-save"
                  style={{
                    justifyContent: 'center',
                    fontSize: 22,
                    marginLeft: 8,
                    marginRight: 8,
                    marginTop: 4,
                    color: GLYPH_COLOR,
                  }}
                />
                <Text style={styles.text}> Submit</Text>
              </View>
            </TouchableHighlight>
          ) : null}
          {editMode === 'VIEW_DETAILS' && this.props.featureIsEditable && !this.props.fetchFailure ? (
            <React.Fragment>
              <TouchableHighlight
                className="edit-button"
                onPress={() => this.editAttributes()}
                underlayColor={BUTTON_COLOR_HOVER}
                style={styles.button}
              >
                <View style={styles.buttonView}>
                  <Glyphicon
                    glyph="pencil"
                    style={{
                      justifyContent: 'center',
                      fontSize: 22,
                      marginLeft: 8,
                      marginRight: 8,
                      marginTop: 4,
                      color: GLYPH_COLOR,
                    }}
                  />
                  <Text style={styles.text}> Edit</Text>
                </View>
              </TouchableHighlight>
              <TouchableHighlight
                className="edit-button"
                onPress={() => this.moveFeature()}
                underlayColor={BUTTON_COLOR_HOVER}
                style={styles.button}
              >
                <View style={styles.buttonView}>
                  <Glyphicon
                    glyph="move"
                    style={{
                      justifyContent: 'center',
                      fontSize: 22,
                      marginLeft: 8,
                      marginRight: 8,
                      marginTop: 4,
                      color: GLYPH_COLOR,
                    }}
                  />
                  <Text style={styles.text}> Move</Text>
                </View>
              </TouchableHighlight>
              <TouchableHighlight
                className="edit-button"
                onPress={this.confirmDelete}
                underlayColor={BUTTON_COLOR_HOVER}
                style={styles.button}
              >
                <View style={styles.buttonView}>
                  <Glyphicon
                    glyph="trash"
                    style={{
                      justifyContent: 'center',
                      fontSize: 22,
                      marginLeft: 8,
                      marginRight: 8,
                      marginTop: 4,
                      color: GLYPH_COLOR,
                    }}
                  />
                  <Text style={styles.text}> Delete</Text>
                </View>
              </TouchableHighlight>
            </React.Fragment>
          ) : null}
          <TouchableHighlight
            className="edit-button"
            onPress={this.props.featureUnselected}
            underlayColor={BUTTON_COLOR_HOVER}
            style={styles.button}
          >
            <View style={styles.closeButtonView}>
              <Glyphicon
                glyph="remove"
                style={{
                  justifyContent: 'center',
                  fontSize: 22,
                  marginLeft: 8,
                  marginTop: 4,
                  color: GLYPH_COLOR,
                }}
              />
              <Text style={styles.text}> Close</Text>
            </View>
          </TouchableHighlight>
        </View>
      </View>
    )
  }

  onCancel() {
    this.props.closeDetailWindow()
  }

  confirmDelete = state => {
    if (this.props.isTestAccountForRealAgency) {
      this.props.createAlert(
        alertTypes.DELETE_FEATURE_ALERT,
        ['OK', 'Cancel'],
        'Delete Feature?',
        `You are currently logged into '${this.props.agencyName}', are you sure you want to delete the feature?`
      )
    } else {
      this.props.createAlert(
        alertTypes.DELETE_FEATURE_ALERT,
        ['OK', 'Cancel'],
        'Delete Feature?',
        'Are you sure you want to delete the feature?'
      )
    }
  }
  editAttributes = () => {
    if (this.props.isTestAccountForRealAgency) {
      this.props.createAlert(
        alertTypes.IS_AGI_FEATURE_EDIT_ALERT,
        ['Yes', 'Cancel'],
        'Warning for AGI user',
        `You are currently logged into '${this.props.agencyName}', do you want to continue to make an edit?`
      )
    } else {
      this.props.openEditWindow()
    }
  }
  moveFeature = () => {
    if (this.props.isTestAccountForRealAgency) {
      this.props.createAlert(
        alertTypes.IS_AGI_GEOM_EDIT_ALERT,
        ['Yes', 'Cancel'],
        'Warning for AGI user',
        `You are currently logged into '${this.props.agencyName}', do you want to continue to make an edit?`
      )
    } else {
      this.props.openGeometryEditingWindow()
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPanel)
