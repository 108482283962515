/* @flow */
import { createSelector } from 'reselect'
import * as Immutable from 'immutable'
import { getAgencyName, getCommonConfig, getAgencyConfig } from './auth'
import {
  getFeatureEdits,
  getFeatureEditIdsByDataSourceName,
  getFeatureSetGuidsByDataSourceName,
  getFormFeatureGuids,
} from './features'
import {
  getCurrentFeature,
  getShouldHighlightCurrentFeature,
  getGeometryEditingWindowOpen,
  getCurrentFeatureId,
  getAgencyBoundary,
  getFeatureSets,
} from './editor'
import {
  getMapType,
  getMapboxStyles,
  getFontSizeAdjustments,
  getShowDynamicLayers,
  getGisDataSourceToggleMap,
} from './map'
import { getEditableFeatureSetGuids } from './editor'
import * as geometryTypes from '../constants/GeometryTypes'
import { getGisLayerSourcesEdited, CURRENT_FEATURE_STYLE_LAYERS, getGisDataSources } from './gisDataSources'
import * as MapTypes from '../constants/MapTypes'
import { featureCollection, point } from '@turf/turf'
import platform from '../utils/platform' // eslint-disable-line import/no-unresolved
const SOURCE_ID_SEARCH_RESULT = 'search--result--location'
const AGENCY_EDITING_BOUNDARY = 'agency--editing--boundary'

const getSearchResultLocation = state => state.map.targetLocation

export const getDynamicSources = createSelector(
  getFeatureEdits,
  getFeatureEditIdsByDataSourceName,
  getFeatureSets,
  getGisLayerSourcesEdited,
  getCurrentFeature,
  getShouldHighlightCurrentFeature,
  getSearchResultLocation,
  getAgencyBoundary,
  (
    featureEdits,
    featureEditIdsByDataSourceName,
    featureSets,
    layerSourcesEdited,
    currentFeature,
    shouldHighlightCurrentFeature,
    searchResultLocation,
    agencyBoundary
  ) => {
    const sources = {}
    layerSourcesEdited.forEach(sourceName => {
      sources[sourceName] = {
        data: {
          type: 'FeatureCollection',
          features: [],
        },
        type: 'geojson',
      }
    })

    let searchLocationData = []
    if (searchResultLocation) {
      searchLocationData = [point([searchResultLocation.lng, searchResultLocation.lat])]
    }
    sources[SOURCE_ID_SEARCH_RESULT] = {
      data: featureCollection(searchLocationData),
      type: 'geojson',
    }
    sources[AGENCY_EDITING_BOUNDARY] = {
      data: agencyBoundary,
      type: 'geojson',
    }
    const currentFeatureKey = 'current--feature'
    sources[currentFeatureKey] = {
      data: {
        type: 'FeatureCollection',
        features: [],
      },
      type: 'geojson',
    }
    const currentFeaturePolygonKey = 'current--feature--polygon'
    sources[currentFeaturePolygonKey] = {
      data: {
        type: 'FeatureCollection',
        features: [],
      },
      type: 'geojson',
    }
    const currentFeatureLineKey = 'current--feature--line'
    sources[currentFeatureLineKey] = {
      data: {
        type: 'FeatureCollection',
        features: [],
      },
      type: 'geojson',
    }
    // TODO add logic to populate this source when no file is included
    const currentFeaturePolygonNoFileKey = 'current--feature--polygon--nofile'
    sources[currentFeaturePolygonNoFileKey] = {
      data: {
        type: 'FeatureCollection',
        features: [],
      },
      type: 'geojson',
    }
    const currentFeatureLineNoFileKey = 'current--feature--line--nofile'
    sources[currentFeatureLineNoFileKey] = {
      data: {
        type: 'FeatureCollection',
        features: [],
      },
      type: 'geojson',
    }

    for (const [dataSourceName, featureGuids] of Object.entries(featureEditIdsByDataSourceName)) {
      const featuresArray = []
      for (const featureGuid of featureGuids) {
        const featureEdit = featureEdits[featureGuid]
        const feature = { ...featureEdit.feature }
        feature.properties = feature.properties ? { ...feature.properties } : {}
        feature.properties.guid = featureGuid
        feature.properties.featureSetGuid = featureEdit.featureSetGuid
        // TODO: remove when icon is not needed anymore
        if (feature.properties.type) {
          feature.properties.icon = feature.properties.type.replace(/\s/g, '-').toLowerCase()
        }
        // Add 'hasAttachments' property to edited features that have attachments
        const featureSet = featureSets[featureEdit.featureSetGuid]
        const { attachmentPropertyNames } = featureSet.feature_schema
        if (attachmentPropertyNames) {
          attachmentPropertyNames.forEach(propertyName => {
            if (feature.properties[propertyName] && feature.properties[propertyName].length) {
              feature.properties.hasAttachments = true
            }
          })
        }
        if (feature.deleteTimestamp) {
          continue
        }
        featuresArray.push(feature)
      }

      const styleSourceName = `${dataSourceName}--edited`
      if (featuresArray.length > 0 && sources.hasOwnProperty(styleSourceName)) {
        sources[styleSourceName]['data']['features'] = featuresArray
      }
    }

    // should re-factor below time permitting. (Do we really need to create all these empty arrays?)
    if (shouldHighlightCurrentFeature && currentFeature && currentFeature.geometry) {
      if (currentFeature.geometry.type === geometryTypes.POINT) {
        const hasPoint = currentFeature.geometry.coordinates.length === 2
        if (hasPoint) {
          sources[currentFeatureKey]['data']['features'] = [currentFeature]
          sources[currentFeaturePolygonKey]['data']['features'] = []
        } else {
          sources[currentFeatureKey]['data']['features'] = []
          sources[currentFeatureLineKey]['data']['features'] = []
          sources[currentFeaturePolygonKey]['data']['features'] = []
        } // will want below for native....??
      } else if (
        currentFeature.geometry.type === geometryTypes.LINESTRING ||
        currentFeature.geometry.type === geometryTypes.LINE
      ) {
        // TODO: thicken line cancel after geomEditWindow open...
        if (currentFeature.geometry.coordinates.length === 1) {
          const transformedFeature = Object.assign({}, currentFeature)
          transformedFeature.geometry.coordinates = currentFeature.geometry.coordinates[0]
          transformedFeature.geometry.type = geometryTypes.POINT
          sources[currentFeatureKey]['data']['features'] = [transformedFeature]
          sources[currentFeatureLineKey]['data']['features'] = []
          sources[currentFeaturePolygonKey]['data']['features'] = []
        } else {
          sources[currentFeatureKey]['data']['features'] = []
          sources[currentFeatureLineKey]['data']['features'] = [currentFeature]
          sources[currentFeaturePolygonKey]['data']['features'] = []
        }
      } else if (currentFeature.geometry.type === geometryTypes.POLYGON) {
        const polygonSize = currentFeature.geometry.coordinates[0].length
        if (polygonSize > 3) {
          sources[currentFeatureKey]['data']['features'] = []
          sources[currentFeatureLineKey]['data']['features'] = []
          sources[currentFeaturePolygonKey]['data']['features'] = [currentFeature]
        } else if (polygonSize === 3) {
          const transformedFeature = Object.assign({}, currentFeature)
          // Since we are showing it as a line remove the closing point
          transformedFeature.geometry.coordinates = transformedFeature.geometry.coordinates[0].slice(0, -1)
          transformedFeature.geometry.type = geometryTypes.LINESTRING
          sources[currentFeatureKey]['data']['features'] = []
          sources[currentFeatureLineKey]['data']['features'] = [transformedFeature]
          sources[currentFeaturePolygonKey]['data']['features'] = []
        } else if (polygonSize === 2) {
          // Since we have a closing point a single polygon point will be size 2
          const transformedFeature = Object.assign({}, currentFeature)
          // Just grab the first point
          transformedFeature.geometry.coordinates = currentFeature.geometry.coordinates[0][0]
          transformedFeature.geometry.type = geometryTypes.POINT
          sources[currentFeatureKey]['data']['features'] = []
          sources[currentFeatureLineKey]['data']['features'] = [transformedFeature]
          sources[currentFeaturePolygonKey]['data']['features'] = []
        } else {
          sources[currentFeatureKey]['data']['features'] = []
          sources[currentFeatureLineKey]['data']['features'] = []
          sources[currentFeaturePolygonKey]['data']['features'] = []
        }
      } else if (currentFeature.geometry.type === geometryTypes.MULTIPOLYGON) {
        const polygonSize = currentFeature.geometry.coordinates[0][0].length
        if (polygonSize > 3) {
          sources[currentFeatureKey]['data']['features'] = []
          sources[currentFeatureLineKey]['data']['features'] = []
          sources[currentFeaturePolygonKey]['data']['features'] = [currentFeature]
        } else if (polygonSize === 3) {
          const transformedFeature = Object.assign({}, currentFeature)
          // Since we are showing it as a line remove the closing point
          transformedFeature.geometry.coordinates = transformedFeature.geometry.coordinates[0][0].slice(0, -1)
          transformedFeature.geometry.type = geometryTypes.LINESTRING
          sources[currentFeatureKey]['data']['features'] = []
          sources[currentFeatureLineKey]['data']['features'] = [transformedFeature]
          sources[currentFeaturePolygonKey]['data']['features'] = []
        } else if (polygonSize === 2) {
          // Since we have a closing point a single polygon point will be size 2
          const transformedFeature = Object.assign({}, currentFeature)
          // Just grab the first point
          transformedFeature.geometry.coordinates = currentFeature.geometry.coordinates[0][0][0]
          transformedFeature.geometry.type = geometryTypes.POINT
          sources[currentFeatureKey]['data']['features'] = []
          sources[currentFeatureLineKey]['data']['features'] = [transformedFeature]
          sources[currentFeaturePolygonKey]['data']['features'] = []
        } else {
          sources[currentFeatureKey]['data']['features'] = []
          sources[currentFeatureLineKey]['data']['features'] = []
          sources[currentFeaturePolygonKey]['data']['features'] = []
        }
      }
    }
    return Immutable.fromJS(sources)
  }
)

export const getGisDataSourceLayers = createSelector(
  [
    getGisDataSources,
    getCommonConfig,
    getAgencyConfig,
    getAgencyName,
    getGisDataSourceToggleMap,
    getFormFeatureGuids,
    getMapType,
    getCurrentFeatureId,
    getGeometryEditingWindowOpen,
    getEditableFeatureSetGuids,
    getFeatureSetGuidsByDataSourceName,
  ],
  (
    gisDataSources,
    commonConfig,
    agencyConfig,
    agencyName,
    toggleMap,
    formFeatureGuids,
    mapType,
    currentFeatureId,
    isEditingGeometry,
    editableFeatureSetGuids,
    featureSetGuidsByDataSourceName
  ) => {
    if (!(commonConfig && agencyConfig)) {
      return Immutable.List()
    }
    // get the data source order
    const commonGisDataSourceOrder = commonConfig.toJS()
    const agencyGisDataSourceOrder = agencyConfig.toJS()
    let order = []
    const _commonConfig = commonGisDataSourceOrder && commonGisDataSourceOrder.gisDataSourceOrder
    const _agencyConfig = agencyGisDataSourceOrder && agencyGisDataSourceOrder.gisDataSourceOrder
    if (_agencyConfig) {
      order = _agencyConfig
    } else if (_commonConfig) {
      order = _commonConfig
    }
    let gisDataSourceOrder = []
    const _gisDataSources = gisDataSources.toJS()
    if (_gisDataSources) {
      gisDataSourceOrder = [...order]
      for (const sourceName of Object.keys(_gisDataSources)) {
        // if a source isn't listed in the sourceOrder, add to the top so they display over everything else
        // except addresses, addresses should always be displayed on top of other features in the editor.
        if (gisDataSourceOrder.indexOf(sourceName) === -1) {
          gisDataSourceOrder.push(sourceName)
        }
      }
    }
    gisDataSourceOrder.push(gisDataSourceOrder.splice(gisDataSourceOrder.indexOf('address'), 1)[0])
    const dataSourceLayers = gisDataSourceOrder.reduce((acc, sourceName) => {
      const source = gisDataSources.get(sourceName)

      if (!source || !source.get('mapboxStyleLayers')) {
        return acc
      }

      if (
        toggleMap.get(sourceName) === false
        // || toggleMap.get(sourceName) === undefined
      ) {
        return acc
      }

      // Certain sources, like USNG, are not displayed with the rest of the layers
      // unless they are explicitly toggled on using the forthcoming layer list component
      if (source.get('visibleByDefault') === false && toggleMap.get(sourceName) !== true) {
        return acc
      }

      if (source.has('allowedBasemaps')) {
        if (!source.get('allowedBasemaps').get(mapType)) {
          return acc
        }
      }

      let layers = source.get('mapboxStyleLayers')

      // Set source to be new 'combined' tileset which is simply our self-hosted combined tileset
      layers = layers.map(lyr => {
        if (['composite', 'combined'].includes(lyr.get('source'))) {
          if (!featureSetGuidsByDataSourceName[sourceName]) {
            // It must be either data from the old system, or hosted on Mapbox composite
            return lyr
          }
          return lyr.set('source-layer', `${agencyName}__${sourceName}`).set('source', 'combined')
        }
        return lyr
      })

      // check for basemap-specific styles
      if (layers && !Immutable.List.isList(layers)) {
        if (layers.get(mapType)) {
          layers = layers.get(mapType)
        } else {
          layers = layers.get('default')
        }
      }
      // for layers with text labels, such as address points, the colors need to be changed
      // if using the dark style
      // TODO: remove this once everyone is on a version above 0.15.6
      if (mapType === MapTypes.DARK) {
        layers = layers.map(lyr => {
          if (lyr.get('type') === 'symbol' && lyr.has('layout') && lyr.hasIn(['layout', 'text-field'])) {
            return lyr.setIn(['paint', 'text-halo-color'], '#212121').setIn(['paint', 'text-color'], 'hsl(0, 0%, 78%)')
          }
          return lyr
        })
      }

      // The 'mutableLayer' passed into this must be the mutable map given by .withMutations callback
      const addFilterToLayer = ({ mutableLayer, filter, idSuffix }) => {
        if (idSuffix) {
          mutableLayer.set('id', `${mutableLayer.get('id')}--${idSuffix}`)
        }
        if (mutableLayer.get('filter')) {
          if (mutableLayer.get('filter').get(0) === 'all') {
            mutableLayer.get('filter').push(filter)
          } else {
            mutableLayer.set('filter', Immutable.fromJS(['all', mutableLayer.get('filter'), filter]))
          }
        } else {
          mutableLayer.set('filter', Immutable.fromJS(filter))
        }
      }

      const featureSetGuidsForThisDataSource = featureSetGuidsByDataSourceName[sourceName] || []
      const editableGuids = featureSetGuidsForThisDataSource.filter(v => editableFeatureSetGuids.includes(v))
      const notEditableGuids = featureSetGuidsForThisDataSource.filter(v => !editableFeatureSetGuids.includes(v))

      // If feature_sets of this dataSource aren't editable, skip adding the editable map layers
      if (!editableGuids.length) {
        return acc.concat(layers)
      }

      // If this dataSource is composed of at least 1 editable and at least 1 not-editable featureSet
      // make the not-editable features partially transparent to help the user distinguish
      if (notEditableGuids.length) {
        const editableFeatureSetsFilter = ['in', 'featureSetGuid', ...editableGuids]
        const editableLayers = layers.map(lyr => {
          return lyr.withMutations(_lyr => {
            addFilterToLayer({ mutableLayer: _lyr, filter: editableFeatureSetsFilter })
          })
        })
        const notEditableFeatureSetsFilter = ['in', 'featureSetGuid', ...notEditableGuids]
        const notEditableLayers = layers.map(lyr => {
          return lyr.withMutations(_lyr => {
            addFilterToLayer({
              mutableLayer: _lyr,
              filter: notEditableFeatureSetsFilter,
              idSuffix: 'not-editable',
            })
            if (_lyr.getIn(['layout', 'icon-image'])) {
              _lyr.setIn(['paint', 'icon-opacity'], 0.6)
            }
            if (_lyr.getIn(['paint', 'fill-color'])) {
              const normalOpacity = _lyr.getIn(['paint', 'fill-opacity']) || 1
              const newOpacity = normalOpacity * 0.7
              _lyr.setIn(['paint', 'fill-opacity'], newOpacity)
            }
            // TODO: we may want to set other -opacity properties, like text-opacity
          })
        })
        layers = notEditableLayers.concat(editableLayers)
      }

      const editedLayers = layers.map(lyr => {
        return lyr.withMutations(_lyr => {
          _lyr.set('id', `${_lyr.get('id')}--edited`)
          _lyr.delete('source-layer')
          _lyr.set('source', `${sourceName}--edited`)
          if (currentFeatureId && isEditingGeometry && platform.web) {
            addFilterToLayer({ mutableLayer: _lyr, filter: ['!in', 'guid', currentFeatureId] })
          }
        })
      })

      // If this data hasn't yet been exported to vector tiles, return early with just the editedLayers added
      const inVectorTileset = source.get('mapboxTileset') && source.get('mapboxTilesetLayer')
      if (!inVectorTileset) {
        return acc.concat(editedLayers)
      }

      // If we have edits for the features then filter them out of the vector layers
      if (layers) {
        if (formFeatureGuids && formFeatureGuids.size > 0) {
          layers = layers.map(layer => {
            if (layer.get('filter')) {
              return layer.set(
                'filter',
                Immutable.fromJS(['all', layer.get('filter'), ['!in', 'guid', ...formFeatureGuids]])
              )
            } else {
              return layer.set('filter', Immutable.fromJS(['!in', 'guid', ...formFeatureGuids]))
            }
          })
        }
      }
      return acc.concat(layers, editedLayers)
    }, Immutable.List())

    return dataSourceLayers
  }
)

export const getDynamicLayers = createSelector(
  [getGisDataSourceLayers],
  gisDataSourceLayers => {
    return Immutable.fromJS([
      ...CURRENT_FEATURE_STYLE_LAYERS,
      ...gisDataSourceLayers,
      {
        id: 'search_result_location',
        interactive: 'true',
        visibility: 'visible',
        source: SOURCE_ID_SEARCH_RESULT,
        type: 'symbol',
        layout: {
          'icon-image': 'ic_coordinates_pin',
          'icon-size': 0.6,
          'icon-ignore-placement': true,
        },
        paint: {},
      },
      {
        id: 'agency_editing_boundary',
        interactive: 'true',
        visibility: 'visible',
        source: AGENCY_EDITING_BOUNDARY,
        type: 'line',
        layout: { 'line-cap': 'round', 'line-join': 'round' },
        paint: {
          'line-color': 'hsl(64, 100%, 60%)',
          'line-width': 4,
          // 'line-opacity': 0.5, // TODO: shared agency boundaries will be slightly transparent
        },
      },
    ])
  }
)

// Get an array of strings listing the style layers for the agency
export const getGisDataSourceStyleLayerIds: (state: Object) => string | void = createSelector(
  [getGisDataSourceLayers],
  gisDataSourceLayers => {
    return gisDataSourceLayers.toJS().map(lyr => lyr.id)
  }
)

export const getMapboxStyle = createSelector(
  [getMapType, getMapboxStyles, getAgencyName],
  (mapType, styles, agencyName) => {
    if (!styles) {
      return null
    }

    return styles.get(mapType) || null
  }
)

const getMapboxStyleWithToggleableLayers = createSelector(
  [getMapboxStyle, getDynamicLayers, getShowDynamicLayers],
  (style, styleLayers, showDynamicLayers) => {
    if (!showDynamicLayers) {
      return style || null
    }
    return style && styleLayers ? style.update('layers', layers => layers.concat(styleLayers)) : null
  }
)

const backgroundLayerTypes = ['background', 'raster']

export const getMapStyleWithIvLayers = createSelector(
  getMapboxStyleWithToggleableLayers,
  getDynamicSources,
  getFontSizeAdjustments,
  (mapboxStyle, dynamicSources, fontSizeAdjustments) => {
    if (!mapboxStyle) {
      return null
    }

    const fontSizeMultiplier = Math.pow(1.2, fontSizeAdjustments)

    const backgroundLayers = mapboxStyle.get('layers').filter(lyr => backgroundLayerTypes.includes(lyr.get('type')))
    // include the iv--infrastructure with the nonSymbolLayers since we want dynamicLayers to display on top of them
    const symbolLayers = mapboxStyle
      .get('layers')
      .filter(lyr => lyr.get('type') === 'symbol' && !lyr.get('id').startsWith('iv--infrastructure'))
    const otherLayers = mapboxStyle
      .get('layers')
      .filter(
        lyr =>
          !backgroundLayerTypes.includes(lyr.get('type')) &&
          (lyr.get('type') !== 'symbol' || lyr.get('id').startsWith('iv--infrastructure'))
      )

    const layers = backgroundLayers.concat(otherLayers, symbolLayers)

    return Immutable.fromJS({
      ...mapboxStyle.toJS(),
      sources: {
        ...dynamicSources.toJS(),
        ...mapboxStyle.toJS().sources,
        // ...ivSources.toJS(),
      },
      layers,
    }).update('layers', layers => {
      if (fontSizeMultiplier === 1) {
        return layers
      }

      // if user has increased the font size by using Ctrl +/- on Electron,
      // update the font sizes in the map
      return layers.map(lyr => {
        const hasTextSize = lyr.has('layout') && lyr.get('layout').has('text-size')
        const hasIconSize = lyr.has('layout') && lyr.get('layout').has('icon-size')
        if (!hasTextSize && !hasIconSize) {
          return lyr
        }
        return lyr.withMutations(_lyr => {
          if (hasTextSize) {
            if (isNaN(lyr.get('layout').get('text-size'))) {
              // if NaN, text-size has multiple stops, must update each stop
              _lyr.updateIn(['layout', 'text-size', 'stops'], stops =>
                stops.map(stop => {
                  return stop.update(1, size => size * fontSizeMultiplier)
                })
              )
            } else {
              _lyr.updateIn(['layout', 'text-size'], size => size * fontSizeMultiplier)
            }
          }
          if (hasIconSize) {
            if (isNaN(lyr.get('layout').get('icon-size'))) {
              // if NaN, icon-size has multiple stops, must update each stop
              _lyr.updateIn(['layout', 'icon-size', 'stops'], stops =>
                stops.map(stop => {
                  return stop.update(1, size => size * fontSizeMultiplier)
                })
              )
            } else {
              _lyr.updateIn(['layout', 'icon-size'], size => size * fontSizeMultiplier)
            }
          }
        })
      })
    })
  }
)
