import * as Immutable from 'immutable'
import * as types from '../constants/ActionTypes'
import { createReducerFromHandlers } from './utils'
import * as options from '../constants/DownloadOptions'
import { REHYDRATE } from 'redux-persist/constants'
import platform from '../utils/platform' // eslint-disable-line import/no-unresolved

const DownloadOption = Immutable.Record({
  optionName: null, // unique name that identifies option
  displayName: null,
  downloadButtonText: 'Download',
  fileName: null,
  fileSize: null,
  selected: false,
  percent: 0,
  updateAutomatically: false,
})

export let initialState = new Immutable.OrderedMap({
  [options.DOWNLOAD_OPTION_SEARCH_DB]: new DownloadOption({
    displayName: 'Onboard Search Data',
    fileName: options.DOWNLOAD_FILE_NAME_SEARCH_DB,
  }),
  [options.DOWNLOAD_OPTION_PREPLANS]: new DownloadOption({
    displayName: 'Preplans',
    fileName: options.DOWNLOAD_FILE_NAME_PREPLANS,
  }),
  [options.DOWNLOAD_OPTION_OFFLINE_TILES]: new DownloadOption({ displayName: 'Offline Map Tiles' }),
})

// Electron doesn't yet support downloading tiles for offline
if (platform.electron) {
  initialState = initialState.delete(options.DOWNLOAD_OPTION_OFFLINE_TILES)
}

const handlers = {
  [REHYDRATE]: (state, action) => {
    const previousState = action.payload.download
    let newState = state
    if (previousState && previousState.tiles) {
      newState = newState.set(options.DOWNLOAD_OPTION_OFFLINE_TILES, new DownloadOption(previousState.tiles))
    }
    if (previousState) {
      for (const option in previousState) {
        newState = newState.update(option, opt =>
          opt.set('updateAutomatically', previousState[option].updateAutomatically)
        )
      }
    }
    return newState
  },

  [types.SELECT_DOWNLOAD_OPTION]: (state, { optionName, selected }) => {
    return state.update(optionName, opt => opt.set('selected', selected).set('fileSize', null))
  },
  [types.SET_DOWNLOAD_OPTION_PERCENT]: (state, action) => {
    if (!state.has(action.downloadOption)) {
      return state
    }
    return state.update(action.downloadOption, opt => opt.set('percent', action.percent))
  },
  // TODO: we might want to replace our usage of FILE_DOWNLOAD_PERCENT entirely with SET_DOWNLOAD_OPTION_PERCENT
  [types.FILE_DOWNLOAD_PERCENT]: (state, action) => {
    if (action.file === options.DOWNLOAD_FILE_NAME_SEARCH_DB) {
      return state.update(options.DOWNLOAD_OPTION_SEARCH_DB, opt => opt.set('percent', action.percent))
    }
    if (action.file === options.DOWNLOAD_FILE_NAME_PREPLANS) {
      return state.update(options.DOWNLOAD_OPTION_PREPLANS, opt => opt.set('percent', action.percent))
    }
    if (action.file === options.DOWNLOAD_OPTION_OFFLINE_TILES) {
      return state.update(options.DOWNLOAD_OPTION_OFFLINE_TILES, opt => opt.set('percent', action.percent))
    }
    return state
  },
  [types.SET_FILE_SIZE]: (state, action) => {
    const { fileSizesMap } = action
    let newState = state
    for (const file in fileSizesMap) {
      newState = newState.update(file, opt => opt.set('fileSize', fileSizesMap[file]))
    }
    return newState
  },
  [types.TOGGLE_UPDATE_AUTOMATICALLY]: (state, action) =>
    state.update(action.file, opt =>
      opt.set('updateAutomatically', !state.get(action.file).get('updateAutomatically'))
    ),
  [types.SIGN_OUT]: () => initialState,
}

export default createReducerFromHandlers(initialState, handlers)

// selectors moved to ../selectors dir
