import axios from 'axios'
import { applyMiddleware, createStore, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import * as Immutable from 'immutable'
import rootReducer from '../reducers'
import rootSaga from '../sagas/index.js'
import persistStore from './persistStore' // eslint-disable-line
import firebase from '../firebase'
import { idTokenChanged, unauthenticated } from '../../common/actions/AuthActions'
import * as config from '../config'
// import Reactotron from '../config/getPlatformReactotron' // eslint-disable-line import/no-unresolved

// TODO: we don't have this set up currently, but I'm leaving it in case we want to turn it on for debugging
const loggerMiddleware = createLogger({
  predicate: (getState, action) => {
    if (config.actionsToNotLog.includes(action.type)) return false
    return true
  },
  // transform logged Immutable objects into regular JS, so it is human readable
  stateTransformer: state => {
    const newState = {}
    for (const i of Object.keys(state)) {
      if (Immutable.Iterable.isIterable(state[i])) {
        newState[i] = state[i].toJS()
      } else {
        newState[i] = state[i]
      }
    }
    return newState
  },
  collapsed: true,
})

let sagaMiddleware
// if (process.env.NODE_ENV !== 'production' && config.useReactotron) {
//   const sagaMonitor = Reactotron.createSagaMonitor()
//   sagaMiddleware = createSagaMiddleware({ sagaMonitor })
// } else {
sagaMiddleware = createSagaMiddleware()
// }

export default function configureStore(initialState) {
  let middleware = [thunkMiddleware, sagaMiddleware]
  if (process.env.NODE_ENV !== 'production' && config.useReduxLogger) {
    middleware.push(loggerMiddleware) // uncomment to log actions to console - slows down dev considerably
  }
  const appliedMiddleware = applyMiddleware(...middleware)

  let store
  // if (process.env.NODE_ENV !== 'production' && config.useReactotron) {
  //   store = Reactotron.createStore(rootReducer, compose(appliedMiddleware))
  // } else {
  store = createStore(rootReducer, compose(appliedMiddleware))
  // }

  persistStore(store)
  sagaMiddleware.run(rootSaga)

  // this listens for any changes in authentication - startup, login, logout, and token refreshes.
  firebase.auth().onIdTokenChanged(async authData => {
    if (authData) {
      let idToken = ''
      try {
        // We expect this to throw an error when offline.
        idToken = await authData.getIdToken()
        const decodedIdToken = await authData.getIdTokenResult()
        const claims = decodedIdToken.claims['https://hasura.io/jwt/claims']
        if (!claims) {
          await axios({
            url: 'https://us-central1-incidentview.cloudfunctions.net/setHasuraClaims',
            method: 'POST',
            data: { idToken },
          })
          // Now that Firebase Auth has added Hasura claims to our token, refetch it from the server
          await authData.getIdToken(true)
          // The above getIdToken(true) causes onIdTokenChanged to be called again, so just return
          return
        }
      } catch (error) {
        // We expect to get here when we're offline, so we don't need to report this error.
        console.warn('Error in handleIdTokenChanged', error)
      }

      store.dispatch(idTokenChanged(authData._user || authData, idToken))
    } else {
      store.dispatch(unauthenticated())
    }
  })

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers')
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}
