/* @flow */
import { createSelector } from 'reselect'
import * as Immutable from 'immutable'
import { getActiveDataSourceName, getFeatureSets } from './editor'
import { getGisDataSourceToggleMap, getMapType } from './map'
import _ from 'lodash'
export const getGisDataSources = (state: Object): ?string => (state.gisDataSources ? state.gisDataSources : null)

export const getGisDataSourcesArray: (state: Object) => Array<Object> = createSelector(
  getGisDataSources,
  dataSourcesMap => {
    if (!dataSourcesMap) return []
    return Object.entries(dataSourcesMap.toJS()).map(([dataSourceName, dataSource]) => ({
      ...dataSource,
      dataSourceName,
    }))
  }
)

export const CURRENT_FEATURE_STYLE_LAYERS = [
  {
    id: 'iv--current--feature-shadow',
    interactive: 'true',
    visibility: 'visible',
    source: 'current--feature',
    type: 'circle',
    paint: {
      'circle-radius': 16,
      'circle-color': '#ff5722',
    },
  },
  {
    id: 'iv--current--feature',
    interactive: 'true',
    visibility: 'visible',
    source: 'current--feature',
    type: 'circle',
    paint: {
      'circle-radius': 14,
      'circle-color': '#ff9800',
      // TODO when strokes start working we can remove the above layer
      // 'circle-opacity': '.8',
      // 'circle-stroke-width': '12',
      // 'circle-stroke-color': '#ff5722',
    },
  },
  {
    id: 'iv--current--feature-polygon',
    interactive: 'true',
    visibility: 'visible',
    source: 'current--feature--polygon',
    type: 'fill',
    paint: {
      'fill-color': '#ff9800',
      'fill-opacity': 0.8,
    },
  },
  {
    id: 'iv--current--feature-polygon-line',
    interactive: 'true',
    visibility: 'visible',
    source: 'current--feature--polygon',
    type: 'line',
    paint: {
      'line-color': '#ff5722',
      'line-width': 2,
    },
  },
  {
    id: 'iv--current--feature-polygon-line-nofile',
    interactive: 'true',
    visibility: 'visible',
    source: 'current--feature--polygon--nofile',
    type: 'line',
    paint: {
      'line-color': '#ff8800',
      'line-dasharray': {
        base: 1,
        stops: [[0, [2, 1]], [16, [3, 2]]],
      },
      'line-width': {
        base: 1,
        stops: [[13, 2], [18, 3]],
      },
    },
  },
  {
    id: 'iv--current--feature-polygon-point',
    interactive: 'true',
    visibility: 'visible',
    source: 'current--feature--polygon',
    type: 'circle',
    paint: {
      'circle-radius': 3,
      'circle-color': '#ff5722',
    },
  },
  {
    id: 'iv--current--feature-line',
    interactive: 'true',
    visibility: 'visible',
    source: 'current--feature--line',
    type: 'line',
    paint: {
      'line-color': '#ff5722',
      'line-width': 2,
    },
  },
  {
    id: 'iv--current--feature-line-nofile',
    interactive: 'true',
    visibility: 'visible',
    source: 'current--feature--line--nofile',
    type: 'line',
    paint: {
      'line-color': '#ff8800',
      'line-dasharray': {
        base: 1,
        stops: [[0, [2, 1]], [16, [3, 2]]],
      },
      'line-width': {
        base: 1,
        stops: [[13, 2], [18, 3]],
      },
    },
  },
  {
    id: 'iv--current--feature-line-point',
    interactive: 'true',
    visibility: 'visible',
    source: 'current--feature--line',
    type: 'circle',
    paint: {
      'circle-radius': 3,
      'circle-color': '#ff5722',
    },
  },
]

// Get an array of strings listing the source layers for the agency
export const getMapboxLayerNamesByEditableGisSourceType: (state: Object) => Object = createSelector(
  getGisDataSources,
  getMapType,
  (gisDataSources, mapType) => {
    if (gisDataSources) {
      return gisDataSources.reduce((acc: Object, dataSource) => {
        if (!dataSource.get('editable') || !dataSource.get('postGisFeatureSet')) {
          return acc
        }
        let layers = dataSource.get('mapboxStyleLayers')
        if (layers) {
          // check for basemap-specific styles
          if (layers && !Immutable.List.isList(layers)) {
            if (layers.get(mapType)) {
              layers = layers.get(mapType)
            } else {
              layers = layers.get('default')
            }
          }

          let styleLayers = Object.values(layers.toJS())
          // Create new edited layers for each source layer
          styleLayers.forEach(styleLayer => {
            if (styleLayer) {
              const layerId = styleLayer['id']
              const sourceLayer = styleLayer['source-layer']
              if (!acc[sourceLayer]) {
                acc[sourceLayer] = []
              }
              acc[sourceLayer].push(layerId)
              acc[sourceLayer].push(`${layerId}--edited`)
            }
          })
        }
        return acc
      }, {})
    }
    return undefined
  }
)

export const getGisStyleNamesByTilesetLayerName: (state: Object) => Object = createSelector(
  getGisDataSources,
  getMapType,
  getGisDataSourceToggleMap,
  (gisDataSources, mapType, toggleMap) => {
    if (gisDataSources) {
      return gisDataSources.reduce(
        (acc: Object, source: Immutable.Map<string, *>, sourceName: string, layerName: string) => {
          // If the layer is hidden don't query it
          if (toggleMap.has(layerName)) {
            let isVisible = toggleMap.get(layerName)
            if (!isVisible) {
              return acc
            }
          }

          let layerVal = source.get('mapboxStyleLayers')
          if (layerVal) {
            // check for basemap-specific styles
            if (layerVal && !Immutable.List.isList(layerVal)) {
              if (layerVal.get(mapType)) {
                layerVal = layerVal.get(mapType)
              } else {
                layerVal = layerVal.get('default')
              }
            }

            let styleLayers = Object.values(layerVal.toJS())
            // Create new edited layers for each source layer
            styleLayers.forEach(styleLayer => {
              if (styleLayer && styleLayer['id']) {
                const layerKey = styleLayer['id']
                acc[layerKey] = sourceName
                acc[`${layerKey}--edited`] = sourceName
              }
            })
          }
          return acc
        },
        {}
      )
    }
  }
)

export const getGisSourceNamesByTilesetLayerName: (state: Object) => Object = createSelector(
  getGisDataSources,
  gisDataSources => {
    if (gisDataSources) {
      return gisDataSources.reduce((acc: Object, source: Immutable.Map<string, *>, sourceName: string) => {
        const tilesetLayerName = source.get('mapboxTilesetLayer')
        acc[tilesetLayerName] = sourceName
        return acc
      }, {})
    }
  }
)

export const getActiveDataSource: (state: Object) => Object = createSelector(
  [getGisDataSources, getActiveDataSourceName],
  (gisDataSources, activeDataSourceName) => gisDataSources.toJS()[activeDataSourceName]
)

export const getGisFeatureSchemas: (state: Object) => Immutable.Map | void = createSelector(
  getGisDataSources,
  gisDataSources => {
    if (gisDataSources) {
      return gisDataSources.reduce((acc: Immutable.Map, source: Immutable.Map, key: string) => {
        let schemaVal = source.get('schema')
        if (schemaVal) {
          acc = acc.set(key, schemaVal)
        }
        return acc
      }, Immutable.Map())
    }
    return undefined
  }
)

// Get an array of strings listing the source layers for the agency
export const getGisLayerSourcesEdited: (state: Object) => string | void = createSelector(
  getFeatureSets,
  featureSets => {
    if (featureSets) {
      return Object.values(featureSets).reduce((acc: Array<string>, featureSet) => {
        if (!featureSet.editable) {
          return acc
        }
        const editedSourceName = `${featureSet.data_source_name}--edited`
        if (acc.includes(editedSourceName)) {
          return acc
        }
        acc = acc.concat(editedSourceName)
        return acc
      }, [])
    }
    return []
  }
)

// Get an array of strings representing each gisDataSource in the agency
export const getGisDataSourceNames: (state: Object) => Array<string> = createSelector(
  getGisDataSources,
  gisDataSources => {
    if (gisDataSources) {
      return gisDataSources.keySeq().toJS()
    } else {
      return []
    }
  }
)

export const getEditableGisDataSources: (state: Object) => Immutable.Map<string, *> = createSelector(
  getGisDataSources,
  gisDataSources => {
    if (gisDataSources) {
      return gisDataSources.reduce((acc, dataSource, dataSourceName) => {
        if (dataSource.get('editable') && dataSource.get('postGisFeatureSet')) {
          return acc.set(dataSourceName, dataSource)
        }
        return acc
      }, Immutable.Map())
    }
  }
)

export const getEditableGisDataSourcesArray: (state: Object) => Array<Object> = createSelector(
  getEditableGisDataSources,
  gisDataSources => {
    if (gisDataSources) {
      return Object.entries(gisDataSources.toJS()).reduce((acc, [dataSourceName, value]) => {
        value.dataSourceName = dataSourceName
        acc.push(value)
        return acc
      }, [])
    }
  }
)

export const getEditableAndVisibleGisDataSources: (state: Object) => Array<Object> = createSelector(
  getEditableGisDataSources,
  getGisDataSourceToggleMap,
  (dataSourcesMap, toggleMap) => {
    if (toggleMap.size !== 0) {
      return Object.entries(dataSourcesMap.toJS()).reduce((acc, [dataSourceName, dataSource]) => {
        if (toggleMap.get(dataSourceName)) {
          acc.push({ ...dataSource, dataSourceName })
        }
        return acc
      }, [])
    } else {
      return Object.entries(dataSourcesMap.toJS()).reduce((acc, [dataSourceName, dataSource]) => {
        acc.push({ ...dataSource, dataSourceName })
        return acc
      }, [])
    }
  }
)

export const getEditableAndNotVisibleGisDataSourceNames: (state: Object) => Array<string> = createSelector(
  getEditableGisDataSources,
  getGisDataSourceToggleMap,
  (dataSourcesMap, toggleMap) => {
    if (toggleMap.size !== 0) {
      return Object.entries(dataSourcesMap.toJS()).reduce((acc, [dataSourceName, dataSource]) => {
        if (!toggleMap.get(dataSourceName)) {
          acc.push(dataSourceName)
        }
        return acc
      }, [])
    } else {
      return Object.entries(dataSourcesMap.toJS()).reduce((acc, [dataSourceName, dataSource]) => {
        acc.push(dataSourceName)
        return acc
      }, [])
    }
  }
)

export const getEditableGisDataSourceNames: (state: Object) => Array<string> = createSelector(
  getEditableGisDataSources,
  gisDataSources => {
    if (gisDataSources) {
      return Object.keys(gisDataSources.toJS())
    }
  }
)

export const getActiveDataSourceGeometryType: (state: Object) => string | void = createSelector(
  getGisDataSources,
  getActiveDataSourceName,
  (dataSources, activeDataSourceName) => {
    if (activeDataSourceName && dataSources) {
      return _.get(dataSources.toJS()[activeDataSourceName], 'schema.geometryType')
    }
    return undefined
  }
)

export const getActiveLayerIsEditable: (state: Object) => boolean = createSelector(
  getGisDataSources,
  getActiveDataSourceName,
  (gisDataSources, activeDataSourceName) => {
    if (gisDataSources && gisDataSources.has(activeDataSourceName)) {
      const dataSource = gisDataSources.get(activeDataSourceName)
      return dataSource.get('editable') && dataSource.get('postGisFeatureSet')
    }
    return false
  }
)
