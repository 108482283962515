/* @flow */
import { getActiveDataSourceName, getCurrentFeature } from './editor'
import { getGisFeatureSchemas } from './gisDataSources'
import { createSelector } from 'reselect'
import Validator from 'validatorjs'
import * as Immutable from 'immutable'

// Taken from: http://stackoverflow.com/questions/6525538/convert-utc-date-time-to-local-date-time-using-javascript
function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
  var offset = date.getTimezoneOffset() / 60
  var hours = date.getHours()
  newDate.setHours(hours - offset)
  return newDate
}

// Add or ammend properties on this fieldInfos object.
const appendToFieldInfos = function(fieldName, inFieldInfos, uiSchema) {
  let fieldInfos = { ...inFieldInfos }
  if (fieldInfos.enum) {
    fieldInfos.data = fieldInfos.enum
  }

  // Handle the  readOnly case:
  if (uiSchema) {
    let schema = uiSchema[fieldName]
    if (schema) {
      let uiWidget = schema['ui:widget']
      if (uiWidget && uiWidget === 'readonly') {
        fieldInfos.editable = false // The properties set here are passed in GenericForm's FieldOrFieldArray
      }
    }
  }
  // Handle the case where format is coming from the schema:
  if (fieldInfos.format) {
    switch (fieldInfos.format) {
      case 'date-time':
        fieldInfos.isDate = true
        break
      default:
        fieldInfos.format = (value, name) => value
        break
    }
  }

  // Add parse/format properties to convert to and from correct type:
  if (fieldInfos.isDate) {
    fieldInfos.parse = value => value.toString()

    fieldInfos.format = value => {
      return v => (value ? convertUTCDateToLocalDate(new Date(value)) : '')
    }
  } else {
    switch (fieldInfos.type) {
      case 'integer':
        fieldInfos.parse = value => parseInt(value)
        fieldInfos.format = value => (value ? value.toString() : '')
        break
      case 'number':
        fieldInfos.parse = value => parseFloat(value)
        fieldInfos.format = value => (value ? value.toString() : '')
        break
      default:
        fieldInfos.parse = value => value
        break
    }
  }

  return fieldInfos
}

// Validation:
export const getValidator = function(state: Object) {
  let fields = getActiveFields(state)
  return getValidatorFunc(fields)
}

export const getShouldShowSpinner = (state: Object): boolean => state.editForm.showEditSpinner

const getValidatorFunc = function(fields) {
  return (values, props) => {
    if (!fields) {
      return {}
    }
    let error = {}
    const vMap = values.toJS()
    fields.forEach(f => {
      let name = f.name
      let rules = f.validationRules
      let val = vMap[name]
      let errMsg = getErr(name, rules, val)
      if (errMsg) {
        error[name] = errMsg
      }
    }) // eo forEach
    return error
  }
}

const getErr = function(name, rules, val) {
  if (!rules || rules.length === 0) {
    return undefined
  }
  let validator = new Validator({ [name]: val }, { [name]: rules })
  return validator.passes() ? undefined : validator.errors.first(name)
}

const calcValidationRules = function(fieldName, fieldInfos, required) {
  let rVal = []
  if (required && required.indexOf(fieldName) > -1) {
    rVal.push('required')
  }

  if (!(fieldInfos.minimum == null)) {
    rVal.push(`min:${fieldInfos.minimum}`)
  }
  if (!(fieldInfos.maximum == null)) {
    rVal.push(`max:${fieldInfos.maximum}`)
  }

  if (fieldInfos.type === 'string') {
    rVal.push('string')
  }

  if (fieldInfos.type === 'number') {
    rVal.push('numeric')
  }

  if (fieldInfos.type === 'integer') {
    rVal.push('integer')
  }

  return rVal.join('|')
}

// eo  Validation:
// // Return an immutable stringified copy of the currentFeature.
// const toString = function(obj) {
//   for (var k in obj) {
//     obj[k] = obj[k].toString()
//   }
//   return obj
// }

const getName = (state: Object, props: Object) => props.name
const getArrayData = (state, props) => {
  let agiEditor = state.form.get('agiEditor').toJS()
  let rVal = agiEditor ? agiEditor.values : null
  return rVal
}

export const getDeleteIndices = function(state: Object) {
  // Return a sorted array with the largets indices first:
  return state.editForm.selectedMedia.sort((a, b) => a > b)
}

export const getMediaList = createSelector(
  getName,
  getArrayData,
  (name, dat) => {
    if (!dat) {
      return null
    }
    let rVal = dat[name]
    return rVal
  }
) // eo createSelector

export const getErrorPosition = function(state: Object) {
  return state.editForm.errorPosition
}

export const getShouldScroll = function(state: Object) {
  return state.editForm.shouldScroll
}

export const getActiveFields = createSelector(
  getActiveDataSourceName,
  getGisFeatureSchemas,
  (activeDataSourceName, formFields) => {
    if (!activeDataSourceName || !formFields) {
      return undefined
    }
    const layerInfoMap = formFields.get(activeDataSourceName)
    if (!layerInfoMap || !layerInfoMap.has('properties')) {
      return undefined
    }

    let uiSchema = layerInfoMap.get('uiSchema')
    return layerInfoMap.get('properties').reduce((acc: Object, schema: Immutable.Map, key: string) => {
      let requiredFields = layerInfoMap.get('required') ? layerInfoMap.get('required').toJS() : []
      const fieldInfos = appendToFieldInfos(key, schema.toJS(), uiSchema && uiSchema.toJS())
      const field = {
        name: `${key}`,
        label: fieldInfos.title,
        validationRules: calcValidationRules(key, fieldInfos, requiredFields),
        ...fieldInfos,
      }
      acc = acc.concat(field)
      return acc
    }, [])
  }
)

export const getInitialValues = createSelector(
  getActiveFields,
  getCurrentFeature,
  (fields, currentObject) => {
    if (currentObject && Object.entries(currentObject.properties).length > 0) {
      return currentObject.properties
    }

    let rVal = {}
    if (fields) {
      fields.forEach(f => {
        if (f.default) {
          rVal[f.name] = f.default
        } else if (f.type === 'boolean') {
          rVal[f.name] = false
        }
      })
    }

    return rVal
  }
)
