/* @flow */
import { createSelector } from 'reselect'
import { Map } from 'immutable'
import { DEMO_AGENCY } from '../constants'

export const getLoadingApp = (state: Object): boolean => state.auth.loadingApp
export const getAgencyName = (state: Object): string => state.auth.user.agencyName
export const getAgencyDisplayName = (state: Object): string => state.auth.agency.get('displayName')

/**
 * We are 'signedIn' if our agency is not DEMO_AGENCY
 */
export const getIsSignedIn = (state: Object): boolean => state.auth.user.agencyName !== DEMO_AGENCY

export const getIsDemoMode = (state: Object): boolean =>
  state.auth.user.agencyName === DEMO_AGENCY || !state.auth.user.agencyName

export const getHasAuthDataA = state => !!state.auth.authData

export const getHasAuthDataB = createSelector(
  state => state.auth.authData,
  authData => !!authData
)

export const getHasAgency = (state: Object): boolean => !!state.auth.agency
export const getUser = (state: Object): string => state.auth.user

export const getUserEmail = (state: Object): string => state.auth.user.email
export const getUserGuid = (state: Object): string => state.auth.user.uid
const regexAgiTestEmail = /iv\..*\..d@gmail\.com/i
const regexAlseageoEmail = /.*@alseageo\..*/g
const fakeAgencies = ['or_webeditordemo_fd', 'or_demo_fd', 'or_fake333_fd', 'or_fake2_fd', 'or_agi_fd', 'or_agi2_fd']
export const getIsTestAccountForRealAgency: (state: Object) => boolean = createSelector(
  [getUserEmail, getAgencyName],
  (email, agencyName) => {
    if (fakeAgencies.includes(agencyName)) {
      return false
    }
    return regexAgiTestEmail.test(email) || regexAlseageoEmail.test(email)
  }
)
export const getLoadingAuthRequest = (state: Object): boolean => state.auth.loadingAuthRequest
export const getLicenseAccepted = (state: Object): boolean => state.auth.licenseAccepted

export const getCommonConfig = (state: Object): Map<string, Object> => state.auth.commonConfig
export const getAgencyConfig = (state: Object): Map<string, Object> => state.auth.agencyConfig

export const getAvlDisplayGroups: (state: Object) => Array<string> = createSelector(
  getAgencyConfig,
  agencyConfig =>
    agencyConfig && agencyConfig.get('avlDisplayGroups') ? Object.keys(agencyConfig.get('avlDisplayGroups').toJS()) : []
)

export const getSharedAvlAgencies: (state: Object) => Array<string> = createSelector(
  getAgencyConfig,
  agencyConfig =>
    agencyConfig && agencyConfig.get('receivesSharedAvlFrom')
      ? Object.keys(agencyConfig.get('receivesSharedAvlFrom').toJS())
      : []
)
export const getAvlAgencies: (state: Object) => Array<string> = createSelector(
  getSharedAvlAgencies,
  getAgencyName,
  (sharedAvlAgencies, agencyName) => {
    sharedAvlAgencies.push(agencyName)
    return sharedAvlAgencies
  }
)
export const getShareAvlWithCadArray: (state: Object) => Array<string> = createSelector(
  getAgencyConfig,
  agencyConfig => {
    if (agencyConfig && agencyConfig.shareAvlWithCad) {
      return Object.keys(agencyConfig.shareAvlWithCad)
    }
    return []
  }
)
export const getAvlRemoveFromMapAfterTime = (state: Object): number =>
  state.auth.agencyConfig && state.auth.agencyConfig.get('avlRemoveFromMapAfterTime')
/**
 * Returns the combined config of both commonConfig and agencyConfig
 */
export const getConfig = createSelector(
  getCommonConfig,
  getAgencyConfig,
  (commonConfig, agencyConfig) => commonConfig && commonConfig.merge(agencyConfig)
)

export const getToken = (state: Object) => state.auth.token
