// This code has been cherry-picked from:
// https://github.com/uber/react-map-gl/blob/b4e46055c6e9d0ba967aef7bae753b5e9cc036d9/src/utils/fit-bounds.js and
// https://github.com/mapbox/mapbox-gl-js/blob/f7da63452bc88feffd963780ec375cc8ae765d07/js/geo/transform.js
// to run on React Native without requiring those libraries.

import * as turf from '@turf/turf'

const WORLD_SIZE = 512

const lngX = lng => ((180 + lng) * WORLD_SIZE) / 360
const latY = lat => {
  const y = (180 / Math.PI) * Math.log(Math.tan(Math.PI / 4 + (lat * Math.PI) / 360))
  return ((180 - y) * WORLD_SIZE) / 360
}

const xLng = x => (x * 360) / WORLD_SIZE - 180
const yLat = y => {
  const y2 = 180 - (y * 360) / WORLD_SIZE
  return (360 / Math.PI) * Math.atan(Math.exp((y2 * Math.PI) / 180)) - 90
}

const degSin = angleDeg => Math.sin((angleDeg * Math.PI) / 180)
const degCos = angleDeg => Math.cos((angleDeg * Math.PI) / 180)

const scaleZoom = scale => Math.log(scale) / Math.LN2

const fitBounds = (width, height, pt1, pt2, heading = 0, padding = 0) => {
  const bbox = turf.bbox(turf.featureCollection([pt1, pt2]))

  const screenWLng = lngX(bbox[0])
  const screenSLat = latY(bbox[1])
  const screenELng = lngX(bbox[2])
  const screenNLat = latY(bbox[3])

  const screenCenterLat = (screenSLat + screenNLat) / 2
  const screenCenterLng = (screenWLng + screenELng) / 2

  const centerLat = yLat(screenCenterLat)
  const centerLng = xLng(screenCenterLng)

  let sizeX = screenELng - screenWLng
  let sizeY = screenSLat - screenNLat

  // for nonzero heading, calculate instead the dimensions of the bounding box
  // pointed in the direction of the heading
  if (heading) {
    const hypotenuse = Math.sqrt(sizeX * sizeX + sizeY * sizeY)
    const bearing = turf.bearing(pt1, pt2)

    const theta = bearing - heading

    const adj = hypotenuse * degCos(theta)
    const opp = hypotenuse * degSin(theta)

    sizeX = opp
    sizeY = adj
  }

  const scaleX = (width - padding * 2) / sizeX
  const scaleY = (height - padding * 2) / sizeY

  const zoom = scaleZoom(Math.min(Math.abs(scaleX), Math.abs(scaleY)))

  return {
    latitude: centerLat,
    longitude: centerLng,
    zoom,
  }
}

export default fitBounds
