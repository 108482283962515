import axios from 'axios'
import { all, put, select, takeEvery } from 'redux-saga/effects'
import * as types from '../constants/ActionTypes'
import * as mapActions from '../actions/MapActions'
import { createAlert } from '../actions'
import * as selectors from '../selectors'
import { getCenterCoordinate } from '../selectors/map'
import platform from '../utils/platform' // eslint-disable-line import/no-unresolved
import { makePattern } from './utils'

function* fetchAgencyMapStyles(action) {
  try {
    const { data: agencyStyleJsons } = yield axios.get(
      'https://api.incidentview.com/v1/map/styles?useMapboxCustomSources=true'
    )
    if (agencyStyleJsons) {
      yield put(mapActions.getMapboxStylesSuccess(agencyStyleJsons))
    } else {
      const title = 'Map styles are missing'
      const msg =
        "Your account is missing map style information. You won't " +
        'be able to see your custom data until this is fixed.'
      yield put(createAlert('mapStylesMissingAlert', ['OK'], title, msg))
    }
  } catch (error) {
    const title = "Can't fetch map styles"
    const msg =
      "Unable to reach server to retrieve map styles. You won't " +
      'be able to see your custom data until this is fixed.'
    yield put(createAlert('mapStylesUnavailableAlert', ['OK'], title, msg))
  }
}

function* nativeUpdateMap() {
  const currentFeatureGeometryCenter = yield select(getCenterCoordinate)
  if (currentFeatureGeometryCenter && platform.native) {
    // TODO this is needed to fix an issue with the mapbox map not updating
    // until focus is gained, remove once the issue is resolved.
    const SHIFT = 0.000001
    let shiftedLng = currentFeatureGeometryCenter.longitude + SHIFT
    let shiftedLat = currentFeatureGeometryCenter.latitude + SHIFT
    yield put(mapActions.zoomToLocationAction(shiftedLat, shiftedLng))
  }
}

let _prevAgencyName
function* zoomToInitialLatLngIfNecessary(action) {
  const agencyName = yield select(selectors.getAgencyName)
  // This will only fire when the agency changes, but will NOT fire the very first time
  // the agency config is grabbed when the app starts -- which is exactly what we want
  // on Native. On Electron, we should fire this when the app starts as well.
  if ((_prevAgencyName || platform.electron) && _prevAgencyName !== agencyName) {
    const { lat, lng } = action.agencyConfig.initialLatLng
    yield put(mapActions.zoomToLocationAction(lat, lng, 12))
  }
  _prevAgencyName = agencyName
}

function* delayShowingDynamicLayers() {
  // yield call(delay, 2000)
  yield put(mapActions.showDynamicLayers())
}

export default function* mapSaga() {
  yield all([
    takeEvery(types.REGISTRATION_FLOW_SUCCESS, fetchAgencyMapStyles),
    takeEvery(types.REGISTRATION_FLOW_SUCCESS, zoomToInitialLatLngIfNecessary),
    takeEvery(types.MAP_STYLE_LOADED, delayShowingDynamicLayers),
    takeEvery(makePattern(types.APP_STATE_CHANGED, { appState: 'active' }), delayShowingDynamicLayers),
    takeEvery(types.GET_MAPBOX_STYLES_SUCCESS, delayShowingDynamicLayers),
    takeEvery(types.CANCEL_FEATURE_GEOMETRY_EDITS, nativeUpdateMap),
    takeEvery(types.CLEAR_FEATURE_GEOMETRY, nativeUpdateMap),
  ])
}
