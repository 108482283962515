/* @flow */
import React from 'react'
import Modal from './SimpleModal'
import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux'
import { Glyphicon, Button, ButtonToolbar, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import { Text, View } from 'react-native-web'
import * as selectors from '../../common/selectors'
// import { addEditPointLocation } from '../../common/actions/EditorActions'

const submitText = {
  fontSize: 22,
  justifyContent: 'center',
}

const textInputStyle = {
  fontSize: 12,
}

const exportButton = {
  alignSelf: 'stretch',
  flexDirection: 'row',
  marginTop: 6,
  marginRight: 16,
  paddingTop: 6,
  paddingBottom: 6,
  paddingLeft: 11,
  paddingRight: 11,
}
const coordinateText = `This tool uses the 'Decimal degrees' form of Latitude and Longitude values. 
If your data is in another coordiante format, please convert before entering. 

____________________________________________________________________________________________________

If you have problems using this tool please consider the following before contacting customer support:
* Your point may be in the wrong location because your coordinate values may not have enough significant figures (not accurate enough).
* What was the coordinate system that the data was collected in? -Incident View uses WGS84- Most GPS recievers use this but can be configured to use other systems. If another system was used the coordinates will need to be converted. 
* Double check your coordinate format and/or conversion method. 
`

const mapStateToProps = state => {
  return {
    currentLocation: state.map.currentLocation,
    currentGeometryType: selectors.getActiveDataSourceGeometryType(state),
    activeDataSourceName: selectors.getActiveDataSourceName(state),
  }
}

type Props = {
  interpolatedStyles: Object,
  closeAddEditPointLocation: Function,
  currentLocation: Object,
  activeDataSourceName: ?string,
  currentGeometryType: ?string,
  submitCoordinates: Function,
  addEditPointLocation: Function,
}

class InsertCoordinateModal extends React.Component<Props> {
  latitude: any
  longitude: any

  render = () => {
    const { submitCoordinates } = this.props
    return (
      <Modal
        title="Add Point Feature from Coordinate Values"
        closeModal={this.props.closeAddEditPointLocation}
        interpolatedStyles={this.props.interpolatedStyles}
      >
        <div style={{ height: '60vh', overflowY: 'scroll' }}>
          {coordinateText.split('\n').map((paragraph, i) => (
            <p key={i}>{paragraph}</p>
          ))}
          <div>
            <form
              ref="coordinateForm"
              onSubmit={e => {
                const lat = parseFloat(findDOMNode(this.refs.latitude).value)
                const lng = parseFloat(findDOMNode(this.refs.longitude).value)
                e.preventDefault()
                if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
                  submitCoordinates(lat, lng, this.props.currentGeometryType, this.props.activeDataSourceName)
                  this.props.closeAddEditPointLocation()
                } else {
                  alert(
                    'Coordinates are not in the correct format. Longitude must be between -180 and 180. Latitude must be between -90 and 90.'
                  )
                }
              }}
            >
              <View>
                <FormGroup controlId="longitude">
                  <ControlLabel>Longitude</ControlLabel>
                  <FormControl
                    ref="longitude"
                    type="longitude"
                    placeholder={'"' + this.props.currentLocation.lng.toFixed(4) + '"'}
                    style={textInputStyle}
                    autoFocus
                  />
                </FormGroup>
                <FormGroup controlId="latitude">
                  <ControlLabel>Latitude</ControlLabel>
                  <FormControl
                    ref="latitude"
                    type="latitude"
                    placeholder={'"' + this.props.currentLocation.lat.toFixed(4) + '"'}
                    style={textInputStyle}
                  />
                </FormGroup>
                <ButtonToolbar>
                  <Button type="submit" style={exportButton}>
                    <Glyphicon
                      glyph="ok-sign"
                      style={{
                        justifyContent: 'center',
                        fontSize: 22,
                        marginLeft: 4,
                        marginRight: 4,
                        marginTop: 2,
                      }}
                    />
                    <Text style={submitText}>&nbsp;&nbsp;SUBMIT COORDINATES</Text>
                  </Button>
                </ButtonToolbar>
              </View>
            </form>
          </div>
        </div>
      </Modal>
    )
  }
}

export default connect(mapStateToProps)(InsertCoordinateModal)
