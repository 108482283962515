import { SET_TOAST_MESSAGE, CLEAR_TOAST_MESSAGE } from '../constants/ActionTypes'
import { createReducerFromHandlers } from './utils'

const initialState = null

const handlers = {
  [SET_TOAST_MESSAGE]: (state, action) => action.message,
  [CLEAR_TOAST_MESSAGE]: (state, action) => null,
}

export default createReducerFromHandlers(initialState, handlers)
