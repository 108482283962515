/* @flow */
import * as Immutable from 'immutable'
import * as turf from '@turf/turf'

const LatLngRecord = Immutable.Record({
  lat: 0,
  lng: 0,
})

export default LatLngRecord

export const getLatLngGeoJson = (latLng: LatLngRecord) => {
  return turf.point([latLng.lng, latLng.lat])
}
