/* @flow */
import { createSelector } from 'reselect'
import * as options from '../constants/DownloadOptions'
import humanFileSize from '../utils/humanFileSize'

// selectors
export const getDownloadOptions = (state: Object) => {
  return state.download.map((opt, key) => opt.set('optionName', key))
}
export const getDownloadOptionsArray = createSelector(
  getDownloadOptions,
  (opts: Object) => opts.toList().toJS()
)
export const getDownloadOptionsArrayReadableSize = createSelector(
  getDownloadOptions,
  (opts: Object) =>
    opts
      .map(opt => opt.set('fileSize', humanFileSize(opt.get('fileSize'), true)))
      .toList()
      .toJS()
)
export const getFilesNotDownloadedArray = createSelector(
  getDownloadOptions,
  (opts: Object) =>
    opts
      .filter((opt, key) => key !== options.DOWNLOAD_OPTION_OFFLINE_TILES)
      .filter(opt => !opt.get('fileSize'))
      .toList()
      .toJS()
)

export const getDownloadOptionPreplans = createSelector(
  getDownloadOptions,
  (opts: Object) => opts.get(options.DOWNLOAD_OPTION_PREPLANS)
)

export const getDownloadOptionOfflineTiles = createSelector(
  getDownloadOptions,
  opts => opts.get(options.DOWNLOAD_OPTION_OFFLINE_TILES)
)

export const getOfflineTilesEnabled = createSelector(
  getDownloadOptionOfflineTiles,
  opt => opt.get('selected')
)

export const getDownloadDisplayNames = (state: Object): Map<string> => {
  return state.download.map(opt => opt.get('displayName'))
}
export const getUpdateAutomaticallySettings = (state: Object): Map<boolean> => {
  return state.download.map(opt => opt.get('updateAutomatically'))
}

// /*
// This might seem redundant, but it is intendid to be
// expanded upon with other future selectors.
//
// This also might not be the ideal location for it, and
// it might be best to move it in the future.
//  */
// export const getDownloadStatusMessage = state => {
//   return getAddressPointFileStreamingMessage(state)
// }

export const getFileList = (state: Object) => {
  // let list = getDownloadOptionsArray(state)
  // console.log(list)
  // console.log(list[0])
  // console.log(list[1])
  // if (list.length === 2) {
  //   return `${list[0].displayName} and ${list[1].displayName}`
  // } else {
  //   return list[0].displayName
  // }
  return state.download.address.displayName
}
