/* @flow */
import React from 'react'
import { connect } from 'react-redux'
import Modal from './SimpleModal'
import { getStyle } from '../containers/ModalBase'
import * as selectors from '../../common/selectors'
import { Switch, View, ScrollView, Button } from 'react-native-web'
import { clearCurrentFeatureObject, setToggleAllMapLayers, setToggleMapLayer } from '../../common/actions'
import { bindActionCreators } from 'redux'
import { NAVBAR_SHADOW_COLOR } from '../styles'

const mapStateToProps = state => {
  return {
    gisDataSourceToggleMap: selectors.getGisDataSourceToggleMap(state),
    gisDataSources: selectors.getGisDataSourcesArray(state),
    visibleLayerNames: selectors.getVisibleLayerNames(state),
    activeDataSourceName: selectors.getActiveDataSourceName(state),
  }
}
const rowStyle = {
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  borderColor: 'white',
  borderBottomWidth: 1,
  alignItems: 'center',
  marginTop: 8,
  paddingBottom: '2px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'left',
  textIndent: '45px',
}
const styles = {
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    borderColor: 'white',
    borderBottomWidth: 1,
    alignItems: 'center',
    marginTop: 2,
    paddingBottom: '2px',
    backgroundColor: 'white',
  },
  layerRow: {
    background: 'white',
  },
  rowText: {
    color: 'black',
    fontSize: 18,
    display: 'inline',
  },
  menuImage: {
    width: 40,
    height: 40,
    marginLeft: 4,
  },
  switch: {
    width: 40,
    height: 40,
  },
  background: {
    background: 'white',
    backgroundColor: 'white',
    color: 'white',
  },
}

// Local state to toggle all layers visible or not.
let allVisible = true

type Props = {
  closeLayerConfig: Function,
  hardwareUid: string,
  interpolatedStyles: Object,
  gisDataSources: Array<Object>,
  gisDataSourceToggleMap: any,
  activeDataSourceName: string,
  clearCurrentFeatureObject: Function,
  setToggleAllMapLayers: Function,
  setToggleMapLayer: Function,
}

const DefaultLayerRow = ({ toggleAllVisible }) => {
  return (
    <View style={styles.row}>
      <View style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'center', marginRight: 8 }}>
        <Button
          title={`Toggle All ${allVisible ? 'Off' : 'On'}`}
          color={NAVBAR_SHADOW_COLOR}
          onPress={toggleAllVisible}
        />
      </View>
    </View>
  )
}

class LayerConfig extends React.Component<Props> {
  render = () => {
    const { gisDataSources, gisDataSourceToggleMap, activeDataSourceName } = this.props
    const interpolatedStyles = getStyle
    return (
      <Modal
        style={{ display: 'flex', flex: 1, textAlign: 'center' }}
        interpolatedStyles={interpolatedStyles}
        key="layerConfig"
        closeModal={() => this.props.closeLayerConfig()}
        title="Layer Display"
      >
        <View style={{ display: 'flex' }}>
          <DefaultLayerRow
            toggleAllVisible={v => {
              allVisible = !allVisible
              if (!allVisible) {
                this.props.clearCurrentFeatureObject()
              }
              this.props.setToggleAllMapLayers(this.props.gisDataSources, allVisible)
            }}
          />
          <ScrollView style={{ height: '60vh' }}>
            {gisDataSources.map((source, ndx) => {
              let dataSourceName = source.dataSourceName
              let isVisible = gisDataSourceToggleMap.has(dataSourceName)
                ? gisDataSourceToggleMap.get(dataSourceName)
                : true
              return (
                <div
                  key={dataSourceName}
                  style={{
                    ...rowStyle,
                    backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/incidentview.appspot.com/o/layerIcons%2F${dataSourceName}.svg?alt=media')`,
                  }}
                >
                  <div style={{ ...styles.rowText, marginLeft: 20 }}>{source.displayName || dataSourceName}</div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flex: 1,
                      justifyContent: 'flex-end',
                      marginRight: 14,
                    }}
                  >
                    <Switch
                      style={{ ...styles.switch }}
                      value={isVisible}
                      onValueChange={v => {
                        if (dataSourceName === activeDataSourceName) {
                          this.props.clearCurrentFeatureObject()
                        }
                        this.props.setToggleMapLayer(dataSourceName, v)
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </ScrollView>
        </View>
      </Modal>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setToggleMapLayer,
      setToggleAllMapLayers,
      clearCurrentFeatureObject,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayerConfig)
