// TODO: actually come up with way to ID web browser

export function getHardwareUid() {
  const deviceName = 'IV_PLACEHOLDER_WEB_ID'
  return deviceName
}

export function getSystemInfo() {
  let output = 'PLACEHOLDER_WEB_SYSTEM_INFO'
  return output
}

export function getReactVersion() {
  return null
}
