/* @flow */
import React from 'react'
import Modal from './SimpleModal'

type Props = {
  closeUsersGuide: Function,
  interpolatedStyles: Object,
}

const UsersGuideModal = ({ closeUsersGuide, interpolatedStyles }: Props) => (
  <Modal title="User's Guide" closeModal={closeUsersGuide} interpolatedStyles={interpolatedStyles}>
    TODO users guide modal body
  </Modal>
)

export default UsersGuideModal
