import * as Immutable from 'immutable'
import { REHYDRATE } from 'redux-persist/constants'
import * as types from '../constants/ActionTypes'
import * as mapTypes from '../constants/MapTypes'
import LatLng from '../records/LatLng'
import LocationProvider from '../records/LocationProvider'
import MapboxStyle from '../records/MapboxStyle'
import { createReducerFromHandlers } from './utils'

export const ZoomLocation = Immutable.Record({
  lat: null,
  lng: null,
  zoom: 12,
})

const Bounds = Immutable.Record({
  southWest: new LatLng({}),
  northEast: new LatLng({}),
})

export const MapState = Immutable.Record({
  // eslint-disable-line new-cap
  cachedRegion: null,
  zoomToLocation: null,
  zoomToBounds: null,
  currentBounds: null,
  shouldTrackCurrentBounds: false,
  currentLocation: null, // new LatLng({lat: 44.566312, lng: -123.261708}), // for now, starting at Corvallis
  targetLocation: null,
  baseMapType: mapTypes.STREET,
  ivLayers: Immutable.List([]),
  mapboxStyles: Immutable.Map(),
  // We manage the data source toggling separately from gisDataSources since that comes from firebase
  gisDataSourceToggleMap: Immutable.Map(),
  component: 'mapbox-gl',
  currentLocationSimulator: false,
  previousLocation: null,
  locationProvider: null,
  // only used on Electron
  fontSizeAdjustments: 0,

  mapStyleLoaded: false,
  showDynamicLayers: false,

  trackUserLocation: true,
})

export const initialState = new MapState()

const handlers = {
  [REHYDRATE]: (state, action) => {
    const previousState = action.payload.map
    let newState = state
    if (previousState && previousState.currentLocation) {
      newState = newState.set('currentLocation', previousState.currentLocation)
    }

    if (previousState && previousState.cachedRegion) {
      newState = newState.set('cachedRegion', previousState.cachedRegion)
    }

    if (previousState && previousState.baseMapType) {
      newState = newState.set('baseMapType', previousState.baseMapType)
    }

    if (previousState && previousState.mapboxStyles) {
      newState = newState.set('mapboxStyles', Immutable.fromJS(previousState.mapboxStyles))
    }

    if (previousState && previousState.gisDataSourceToggleMap) {
      newState = newState.set('gisDataSourceToggleMap', Immutable.fromJS(previousState.gisDataSourceToggleMap))
    }

    return newState
  },

  [types.SET_TOGGLE_ALL_MAP_LAYERS]: (state, action) => {
    let gisDataSourceToggleMap = state.get('gisDataSourceToggleMap')
    action.gisDataSources.forEach(dataSource => {
      gisDataSourceToggleMap = gisDataSourceToggleMap.set(dataSource.dataSourceName, action.isVisible)
    })

    return state.set('gisDataSourceToggleMap', gisDataSourceToggleMap)
  },

  [types.SET_TOGGLE_MAP_LAYER]: (state, action) => {
    let gisDataSourceToggleMap = state.get('gisDataSourceToggleMap')
    gisDataSourceToggleMap = gisDataSourceToggleMap.set(action.layerName, action.isVisible)

    return state.set('gisDataSourceToggleMap', gisDataSourceToggleMap)
  },

  // Co-locate needs bounds. See sagas/map.js
  [types.SHOULD_TRACK_CURRENT_BOUNDS]: (state, action) => {
    let shouldTrack = action.shouldTrack
    return state.set('shouldTrackCurrentBounds', shouldTrack)
  },

  [types.SET_REGION]: (state, action) => {
    state = state.set('cachedRegion', action.region)
    return state
  },

  [types.SET_CURRENT_BOUNDS]: (state, action) => {
    let currentBoundsArray = action.currentBoundsArray

    if (!currentBoundsArray) {
      return state.set('currentBounds', null)
    } else {
      let sw = new LatLng({ lat: currentBoundsArray[0], lng: currentBoundsArray[1] })
      let ne = new LatLng({ lat: currentBoundsArray[2], lng: currentBoundsArray[3] })
      return state.set(
        'currentBounds',
        new Bounds({
          southWest: new LatLng(sw),
          northEast: new LatLng(ne),
        })
      )
    }
  },
  [types.LOCATION_PROVIDER_CHANGED]: (state, action) => {
    if (!action.provider.enabled) {
      state = state.set('currentLocation', null)
    }
    return state.set(
      'locationProvider',
      new LocationProvider({
        enabled: action.provider.enabled,
        network: action.provider.network,
        gps: action.provider.gps,
      })
    )
  },
  [types.SET_CURRENT_LOCATION]: (state, action) => {
    return state
      .set('previousLocation', state.currentLocation)
      .set('currentLocation', new LatLng({ lat: action.lat, lng: action.lng }))
  },
  [types.ZOOM_TO_LOCATION]: (state, action) => {
    return state.set('zoomToLocation', new ZoomLocation(action))
  },
  [types.ZOOM_TO_BOUNDS]: (state, action) => {
    return state.set(
      'zoomToBounds',
      new Bounds({
        southWest: new LatLng(action.southWest),
        northEast: new LatLng(action.northEast),
      })
    )
  },
  [types.SET_BASEMAP_TYPE]: (state, action) => {
    return state.set('baseMapType', action.mapType)
  },
  [types.TOGGLE_MAP_COMPONENT]: (state, action) => {
    if (state.component === 'mapbox-gl') {
      return state.set('component', 'leaflet')
    } else {
      return state.set('component', 'mapbox-gl')
    }
  },
  [types.SIGN_IN_SUCCESS]: state => state.set('targetLocation', null).set('currentLocationSimulator', false),
  [types.DID_ZOOM_TO_LOCATION]: (state, action) => state.set('zoomToLocation', null),
  [types.DID_ZOOM_TO_BOUNDS]: (state, action) => state.set('zoomToBounds', null),
  [types.DID_CHANGE_BOUNDS]: (state, action) => {
    return state.set(
      'currentBounds',
      new Bounds({
        southWest: new LatLng(action.southWest),
        northEast: new LatLng(action.northEast),
      })
    )
  },

  // Listen for viewport changes from the web app. We'll use the location set here in the geocode proximity.
  [types.SET_VIEWPORT]: (state, action) => {
    let lat = action.viewport.latitude
    let lng = action.viewport.longitude

    return state
      .set('previousLocation', state.currentLocation)
      .set('currentLocation', new LatLng({ lat: lat, lng: lng }))
  },

  // Target location actions
  [types.SET_TARGET_LOCATION]: (state, action) => {
    return state
      .set('targetLocation', new LatLng({ lat: action.lat, lng: action.lng }))
      .set('zoomToLocation', new ZoomLocation({ lat: action.lat, lng: action.lng, zoom: 17 }))
  },
  // Clear the target location when all text is removed from the search bar
  [types.SEARCH_TEXT_UPDATED]: (state, action) => {
    if (!action.searchText) {
      return state.set('targetLocation', null)
    }
    return state
  },
  [types.GET_MAPBOX_STYLES_SUCCESS]: (state, action) => {
    return state.withMutations(_state => {
      for (const [styleType, styleJson] of Object.entries(action.stylesObject)) {
        _state.update('mapboxStyles', styles => styles.set(styleType, new MapboxStyle(Immutable.fromJS(styleJson))))
      }
    })
  },
  [types.TOGGLE_CURRENT_LOCATION_SIMULATOR]: state => {
    return state.set('currentLocationSimulator', !state.currentLocationSimulator)
  },
  [types.INCREASE_FONT_SIZE]: state => state.update('fontSizeAdjustments', size => size + 1),
  [types.DECREASE_FONT_SIZE]: state => state.update('fontSizeAdjustments', size => size - 1),
  [types.MAP_STYLE_LOADED]: state => state.set('mapStyleLoaded', true),
  [types.APP_STATE_CHANGED]: (state, action) => {
    if (action.appState === 'inactive') {
      return state.set('showDynamicLayers', false)
    }
    return state
  },
  [types.SHOW_DYNAMIC_LAYERS]: state => state.set('showDynamicLayers', true),
  [types.REGISTRATION_FLOW_SUCCESS]: state => state.set('showDynamicLayers', false),

  [types.TOGGLE_TRACK_USER_LOCATION]: state => {
    return state.set('trackUserLocation', !state.trackUserLocation)
  },
}

export default createReducerFromHandlers(initialState, handlers)
