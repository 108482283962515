/**
 * Created by lewladd on 2/17/17.
 */
import * as Immutable from 'immutable'
import { REHYDRATE } from 'redux-persist/constants'
import * as types from '../constants/ActionTypes'
import { createReducerFromHandlers } from './utils'

export const SyncState = Immutable.Record({
  isSyncing: false,
  featureCache: new Immutable.Map(),
})

export const initialState = new SyncState()

function removeFromCache(state, guid) {
  let featureCache = state.get('featureCache')
  featureCache = featureCache.delete(guid)
  state = state.set('featureCache', featureCache)
  return state
}

const handlers = {
  [REHYDRATE]: (state, action) => {
    const previousState = action.payload.offlineSyncronizer

    return previousState ? Immutable.fromJS(previousState) : state
  },

  [types.CLEAR_IS_SYNCING]: (state, action) => {
    state = state.set('isSyncing', false)
    return state
  },

  [types.SET_IS_SYNCING]: (state, action) => {
    state = state.set('isSyncing', true)
    return state
  },

  [types.LOST_NETWORK_CONNECTION]: (state, action) => {
    state = state.set('isSyncing', false)
    return state
  },

  // [types.SAVE_FEATURE_EDIT_REQUEST]: (state, action) => {
  //   // cache on SAVE_FEATURE_EDIT_REQUEST instead of cache_submission
  //   const { featureSetGuid, dbOperation, modifiedDate, featureGuid } = action
  //   const cachedFeatureObject = {
  //     modifiedDate,
  //     dbOperation,
  //     featureSetGuid,
  //   }
  //   // Update the cache
  //   return state.setIn(['featureCache', featureGuid], Immutable.fromJS(cachedFeatureObject))
  // },

  // [types.CACHE_SUBMISSION]: (state, action) => {
  //   // Update/insert the feature in the cache with values held in the action.
  //   let guid = state.get('currentFeatureObjectId')
  //   let featureCache = state.get('featureCache')
  //
  //   let ts = new Date().getTime()
  //   let layerName = action.layerName
  //   // Default:
  //   let cachedFeatureObject = {
  //     timestamp: ts,
  //     dbOperation: 'add',
  //     layerName: layerName,
  //   }
  //
  //   let features = action.features
  //   let hasGuid = features[layerName] && features[layerName][guid]
  //   if (hasGuid) {
  //     cachedFeatureObject = featureCache.get(guid) || cachedFeatureObject
  //     cachedFeatureObject.dbOperation = 'update'
  //   }
  //
  //
  //   // Update the cache
  //   featureCache = featureCache.set(guid, Immutable.fromJS(cachedFeatureObject))
  //   state = state.set('featureCache', featureCache)
  //
  //   return state
  // },

  [types.FIREBASE_UPDATE_COMPLETE]: (state, action) => {
    // Remove the key/value pair associated with action.guid from the featureCache:
    return removeFromCache(state, action.guid)
  },

  '@@redux-form/ARRAY_REMOVE': (state, action) => {
    // If an element is removed from the array, mark the feature that holds the array's dbOperation as update'ed.
    let featureCache = state.get('featureCache')
    let guid = state.get('currentFeatureObjectId')

    let feature = state.get('currentFeatureObject')
    let layerName = state.get('activeLayer')
    let obj = {
      timestamp: new Date().getTime(),
      feature: feature,
      layerName: layerName,
      dbOperation: 'update',
    }

    // Add or update the featureCache with the info needed to
    // later update the feature associated with guid from the DB.
    featureCache = featureCache.set(guid, Immutable.Map(obj))
    state = state.set('featureCache', featureCache)
    return state
  },

  [types.DELETE_FEATURE]: (state, action) => {
    let featureCache = state.get('featureCache')

    let obj = {
      timestamp: action.timestamp,
      feature: action.feature, // For deletes, we must hold onto the feature
      layerName: action.layerName,
      dbOperation: 'delete',
    }

    // Add or update the featureCache with the info needed to
    // later delete the feature associated with guid from the DB.
    featureCache = featureCache.set(action.guid, Immutable.Map(obj))
    state = state.set('featureCache', featureCache)
    return state
  },
}

export default createReducerFromHandlers(initialState, handlers)
