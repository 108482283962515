import * as dotenv from './dotenv'
import { SET_VIEWPORT } from '../constants'

export const actionsToNotLog = [SET_VIEWPORT]

const convertToBoolean = val => {
  if (['1', 'true', 1, true].includes(val)) {
    return true
  }
  return false
}

export const useReduxLogger = convertToBoolean(dotenv.REACT_APP_USE_REDUX_LOGGER)
export const useReactotron = convertToBoolean(dotenv.REACT_APP_USE_REACTOTRON)
