/* @flow */

export const IGNORED_GIS_ATTRIBUTES = [
  'guid',
  'origin_id',
  'modified_date',
  'modified_user_guid',
  'modified_user',
  'source',
  'version',
]

export const DEFAULT_ZOOM_DEPTH = 11
export const DEFAULT_AVL_COLOR = 'FFFF8800'
export const TOOL_HIGHLIGHT_COLOR = 'rgb(255, 204, 0)'
export const UI_BACKGROUND_LIGHT_COLOR = '#424242'
export const UI_BACKGROUND_COLOR = '#303030'
export const UI_BACKGROUND_DARK_COLOR = '#212121'
export const UI_TOOLBAR_COLOR = '#212121'
export const UI_TOOLBAR_DARK_COLOR = '#000000'
export const HOLO_BLUE_COLOR = '#33b5e5'
export const ANDROID_TEAL_COLOR = '#009688'
export const IOS_BLUE_COLOR = 'rgb(90, 200, 250)'
export const FEATURE_STROKE_COLOR = '#FF7700'
export const FEATURE_FILL_COLOR = '#FFCC00'
export const FEATURE_FILL_ALPHA = 0.48
export const FEATURE_STROKE_WIDTH = 2

export const DEMO_AGENCY = 'or_demo_fd'
export const DEMO_EMAIL = 'iv.demo.fd@gmail.com'
export const DEMO_PASSWORD = "GD$Kzj>y:JhxGt'h5w{C"

// Used for connectivity checking
export const PING_URL = 'http://www.google.com/generate_204'
export const CONNECTIVITY_CHECK_INTERVAL = 5000
export const CONNECTIVITY_CHECK_TIMEOUT = 4000

export const TOAST_DISPLAY_INTERVAL_SHORT = 3000

export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoiYWxzZWFnZW8iLCJhIjoiY2p4Y2g2eWdtMDNlbTNvbmxib2dkd2l3ZiJ9.P56nt_iuDr7sD9t_qLqMlQ'

export const MAPBOX_STYLE_URL = 'https://api.incidentview.com/v1/map/style?useMapboxCustomSources=true'
export const createMapStyleUrl = ({ mapType, agencyName }) => {
  return `${MAPBOX_STYLE_URL}/${mapType}?v=1&agencyName=${agencyName}`
}

export * from './ActionTypes'
export * from './DownloadOptions'
export * from './ModalNames'
export * from './GeometryTypes'
