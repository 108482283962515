/* @flow */
import { makeActionCreator } from './utils'
import * as types from '../constants/ActionTypes'

export const saveFeatureEditRequest = ({ feature, featureSetGuid, featureGuid, dbOperation, modifiedDate }) => ({
  type: types.SAVE_FEATURE_EDIT_REQUEST,
  feature,
  featureSetGuid,
  featureGuid,
  dbOperation,
  modifiedDate,
})
export const saveFeatureEditSuccess = ({ featureGuid }) => ({
  type: types.SAVE_FEATURE_EDIT_SUCCESS,
  featureGuid,
})
export const saveFeatureEditFailure = makeActionCreator(types.SAVE_FEATURE_EDIT_FAILURE, 'error')

export const submitFeatureAttributeEdits = makeActionCreator(types.SUBMIT_FEATURE_ATTRIBUTE_EDITS, 'values')
export const deleteFeature = makeActionCreator(types.DELETE_FEATURE, 'guid', 'layerName', 'feature', 'timestamp')
export const firebaseUpdateComplete = makeActionCreator(types.FIREBASE_UPDATE_COMPLETE, 'guid')
export const coordinateSubmit = makeActionCreator(types.COORDINATE_SUBMIT, 'lat', 'lng', 'geom', 'layerKey')

export const featureEditsUpdated = (features: {}) => ({
  type: types.FEATURE_EDITS_UPDATED,
  features,
})
export const featureEditsRemoved = (featureGuids: Array<string>) => ({
  type: types.FEATURE_EDITS_REMOVED,
  featureGuids,
})
