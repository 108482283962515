/* @flow */
import { createSelector } from 'reselect'
import _ from 'lodash'
import { getAgencyName } from './auth'
import * as geometryTypes from '../constants/GeometryTypes'
import * as turf from '@turf/turf'

export const getShouldHighlightCurrentFeature = (state: Object): boolean => state.editor.shouldHighlightCurrentFeature

export const getShouldToggleLineEditing = (state: Object): boolean => state.editor.toggleLineLengthEdit

export const getAddingNewFeature = (state: Object): boolean => state.editor.addingNewFeature

export const getActiveDataSourceName = (state: Object): ?string => state.editor.activeDataSource

export const getUndoStatus = (state: Object): boolean => state.editor.undoStatus

export const getShouldCenterMap = (state: Object): boolean => state.editor.centerMap

export const getRemoveStatus = (state: Object): boolean => state.editor.removeFromPolygon

export const getCreateMultiPartStatus = (state: Object): boolean => state.editor.createMultiPartFeature

export const getAgencyBoundary = (state: Object): Map<string, Object> => state.editor.agencyBoundary

export const getCurrentFeature = createSelector(
  state => state.editor.currentFeatureObject,
  currentFeature => {
    return currentFeature ? { ...currentFeature } : null
  }
)
export const getFeatureEditHistory = createSelector(
  state => state.editor.featureEditHistory,
  featureEditHistory => {
    return featureEditHistory ? [...featureEditHistory] : null
  }
)

export const getEditGeometryMessage = createSelector(
  [getAddingNewFeature, getCurrentFeature, getActiveDataSourceName, getFeatureEditHistory],
  (addingNewFeature, currentFeature, activeDataSource, editHistory) => {
    if (editHistory && editHistory.length && editHistory.length < 2) {
      if (currentFeature && currentFeature.geometry && currentFeature.geometry.type) {
        if (currentFeature.geometry.type === geometryTypes.POINT || currentFeature.geometry.type === 'MultiPoint') {
          return `Click to move the ${activeDataSource} to that location`
        } else if (
          currentFeature.geometry.type === geometryTypes.LINE ||
          currentFeature.geometry.type === geometryTypes.LINESTRING ||
          currentFeature.geometry.type === 'MultiLineString'
        ) {
          return `Click and drag to move vertices. Select the pencil button to add to the ${activeDataSource}`
        } else if (
          currentFeature.geometry.type === geometryTypes.POLYGON ||
          currentFeature.geometry.type === geometryTypes.MULTIPOLYGON
        ) {
          return `Click and drag to move vertices`
        }
      }
    } else if (addingNewFeature) {
      return `Click on the map to start adding a ${activeDataSource}`
    }
    return null
  }
)

export const getCurrentFeatureId = (state: Object): Map => state.editor.currentFeatureObjectId

export const getCurrentFeatureGeometry = createSelector(
  state => state.editor.currentFeatureObject,
  currentFeatureObject => {
    return currentFeatureObject && currentFeatureObject.geometry ? { ...currentFeatureObject.geometry } : null
  }
)

export const getFeatureSets = state => state.editor.featureSets

export const getCurrentFeaturePropertyOrder: (state: Object) => boolean = createSelector(
  [getFeatureSets, getCurrentFeature],
  (featureSets, currentFeature) => {
    const { featureSetGuid } = currentFeature.properties
    if (!(featureSets && featureSetGuid) || !featureSets[featureSetGuid]) {
      return Object.keys(currentFeature.properties).filter(p => !['featureSetGuid, guid'].includes(p))
    }
    const { feature_schema } = featureSets[featureSetGuid]
    return _.get(feature_schema, ['uiSchema', 'ui:order'], Object.keys(feature_schema.properties))
  }
)

export const getFeatureSetGuids = createSelector(
  [getFeatureSets],
  featureSets => Object.keys(featureSets)
)

export const getEditableFeatureSetGuids = createSelector(
  [getFeatureSets],
  featureSets => {
    return Object.entries(featureSets)
      .filter(
        ([featureSetGuid, featureSet]) =>
          featureSet.editable && featureSet.rel_agency_feature_sets[0].can_write_features
      )
      .map(([featureSetGuid, featureSet]) => featureSetGuid)
  }
)

export const getCurrentFeatureIsEditable = createSelector(
  [getCurrentFeature, getFeatureSets, getAddingNewFeature, getAgencyBoundary],
  (feature, featureSets, addingNewFeature, agencyBoundary) => {
    let isInside = true

    turf.coordEach(feature, function(currentCoord, coordIndex, featureIndex, multiFeatureIndex, geometryIndex) {
      if (agencyBoundary) {
        let isInsideTemp = turf.inside(currentCoord, agencyBoundary)
        if (!isInsideTemp) {
          isInside = false
        }
      }
    })

    if (!isInside) {
      return false
    }

    if (addingNewFeature) {
      return true
    }
    if (feature.properties && feature.properties.featureSetGuid) {
      const featureSet = featureSets[feature.properties.featureSetGuid]
      if (!featureSet) return false

      return featureSet.editable && featureSet.rel_agency_feature_sets[0].can_write_features
    }
    return false
  }
)

export const getCurrentFeatureIsOwnedByAgency = createSelector(
  [getAgencyName, getCurrentFeature, getFeatureSets],
  (agencyName, feature, featureSets) => {
    if (feature.properties && feature.properties.featureSetGuid) {
      const featureSet = featureSets[feature.properties.featureSetGuid]
      if (featureSet && featureSet.agencyName === agencyName) {
        return true
      } else {
        return false
      }
    }
    return false
  }
)

export const getCurrentFeaturesAgencyName = createSelector(
  [getCurrentFeature, getFeatureSets],
  (feature, featureSets) => {
    if (feature.properties && feature.properties.featureSetGuid && featureSets[feature.properties.featureSetGuid]) {
      const featureSet = featureSets[feature.properties.featureSetGuid]
      return featureSet.agencyName
    }
    return null
  }
)

export const getCurrentFeatureGeometryType = (state: Object): ?string => {
  return _.get(state.editor.currentFeatureObject, 'geometry.type', null)
}

export const getTempFeatureGeometryObject = createSelector(
  state => state.editor.tempFeatureGeometryObject,
  tempFeatureGeometryObject => {
    return tempFeatureGeometryObject ? { ...tempFeatureGeometryObject } : null
  }
)

export const getEditMode = state => state.editor.mode

export const getEditWindowOpen = (state: Object): boolean => {
  return state.editor.mode === 'EDIT_ATTRIBUTES'
}

export const getDetailWindowOpen = (state: Object): Map => {
  return state.editor.mode === 'VIEW_DETAILS'
}

export const getAnyWindowOpen = (state: Object): boolean => {
  return getEditWindowOpen(state) || getDetailWindowOpen(state)
}

export const getGeometryEditingWindowOpen = (state: Object): boolean => state.editor.mode === 'EDIT_GEOMETRY'

export const getCurrentFeatureModifiedDate = (state: Object): string => state.editor.currentFeatureModifiedDate
export const getCurrentFeatureModifiedUser = (state: Object): string => state.editor.currentFeatureModifiedUser
export const getCurrentFeatureVersionNumber = (state: Object): number => state.editor.currentFeatureVersionNumber
export const getRequestingFeatureInfo = (state: Object): boolean => state.editor.requestingFeatureInfo
export const getFetchFeatureStatus = (state: Object): boolean => state.editor.fetchFailure
export const getFetchFeatureFailureMessage = (state: Object): string => state.editor.fetchFailureMessage

// Check if the feature geometry is valid
export const getIsValidGeometry: (state: Object) => boolean = createSelector(
  getCurrentFeatureGeometry,
  getCurrentFeatureGeometryType,
  (currentFeatureGeometry, currentFeatureGeometryType) => {
    let isValidGeometry = false
    if (currentFeatureGeometry) {
      if (currentFeatureGeometryType === geometryTypes.POINT) {
        const hasPoint = currentFeatureGeometry.coordinates.length
        if (hasPoint) {
          isValidGeometry = true
        }
      } else if (currentFeatureGeometryType === geometryTypes.LINESTRING) {
        const lineSize = currentFeatureGeometry.coordinates[0].length
        if (lineSize > 1) {
          isValidGeometry = true
        }
      } else if (currentFeatureGeometryType === geometryTypes.POLYGON) {
        const polygonSize = currentFeatureGeometry.coordinates[0].length
        if (polygonSize > 3) {
          isValidGeometry = true
        }
      } else if (currentFeatureGeometryType === geometryTypes.MULTIPOLYGON) {
        const polygonSize = currentFeatureGeometry.coordinates[0][0].length
        if (polygonSize > 3) {
          isValidGeometry = true
        }
      }
    }
    return isValidGeometry
  }
)

// export const getCurrentFeatureGeometryCenter: (state: Object) => Object = createSelector(
//   getCurrentFeatureGeometry,
//   getCurrentFeatureGeometryType,
//   (currentFeatureGeometry, currentFeatureGeometryType) => {
//     let centerPoint = null
//     if (currentFeatureGeometry) {
//       if (currentFeatureGeometryType === geometryTypes.POINT) {
//         centerPoint = currentFeatureGeometry.coordinates
//       } else if (currentFeatureGeometryType === geometryTypes.LINESTRING) {
//         centerPoint = currentFeatureGeometry.coordinates[0]
//       } else if (currentFeatureGeometryType === geometryTypes.POLYGON) {
//         centerPoint = currentFeatureGeometry.coordinates[0]
//       } else if (currentFeatureGeometryType === geometryTypes.MULTIPOLYGON) {
//         centerPoint = currentFeatureGeometry.coordinates[0][0]
//       }
//     }
//     return centerPoint
//   }
// )
