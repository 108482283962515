/* @flow */
import React, { useLayoutEffect, useState } from 'react'
import { setBasemapType } from '../../common/actions/MapActions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { DropdownButton, MenuItem, Glyphicon, Button } from 'react-bootstrap'
import {
  openAccountSignInModal,
  openAboutModal,
  openExportDataModal,
  openLicenseAgreementModal,
  openLayerConfig,
  openModalComponent,
  popModal,
} from '../../common/actions/ModalActions'
import SearchBar from '../containers/SearchBar'
import { setToastMessage } from '../../common/actions/ToastActions'
import { createAlert } from '../../common/actions/AlertActions'
import { signOutRequest } from '../../common/actions/AuthActions'
import { baseMapTypeList } from '../../common/constants/MapTypes'
import * as selectors from '../../common/selectors'
import { NAVBAR_BACKGROUND_COLOR, NAVBAR_SHADOW_COLOR, GLYPH_COLOR } from '../styles'
import LayerConfig from './LayerConfig'
import InfrastructureIconsMenu from './InfrastructureIconsMenu'
import { Text, StyleSheet, View } from 'react-native-web'

export const TOP_NAVBAR_HEIGHT = '55px'

const buttonStyle = {
  background: 'none',
  color: GLYPH_COLOR,
  fontSize: 22,
  height: '100%',
  border: 0,
}
const dropdownAlign = {
  transform: 'translate(0, 19%)',
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: TOP_NAVBAR_HEIGHT,
    backgroundColor: NAVBAR_BACKGROUND_COLOR,
    borderBottomColor: NAVBAR_SHADOW_COLOR,
    borderBottomWidth: 1,
    zIndex: 3,
  },
  addFeatureButton: {
    marginRight: 20,
    marginLeft: 5,
  },
  buttonDisabled: {
    backgroundColor: '#dfdfdf',
  },
  textDisabled: {
    color: '#a9a9a9',
  },
  button: {
    alignSelf: 'stretch',
    flexDirection: 'row',
  },
  buttonView: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    marginTop: 6,
    marginRight: 16,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 22,
    paddingRight: 22,
  },
  text: {
    color: GLYPH_COLOR,
    fontSize: 22,
    justifyContent: 'center',
    paddingTop: 2,
  },
  glyph: {
    justifyContent: 'center',
    fontSize: 22,
    marginLeft: 8,
    marginRight: 8,
  },
  buttonGlyph: {
    alignSelf: 'stretch',
    flexDirection: 'row',
  },
})

const mapStateToProps = state => {
  return {
    userEmail: state.auth.user.email,
    activeBasemapType: selectors.getMapType(state),
    activeLayerIsEditable: selectors.getActiveLayerIsEditable(state),
    demoMode: selectors.getIsDemoMode(state),
    addingNewFeature: selectors.getAddingNewFeature(state),
    isConnected: selectors.getIsConnected(state),
    detailWindowOpen: selectors.getDetailWindowOpen(state),
    geometryWindowOpen: selectors.getGeometryEditingWindowOpen(state),
    editWindowOpen: selectors.getEditWindowOpen(state),
    isTestAccountForRealAgency: selectors.getIsTestAccountForRealAgency(state),
    agencyName: selectors.getAgencyName(state),
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      openAccountSignInModal,
      openAboutModal,
      openLicenseAgreementModal,
      openLayerConfig,
      openModalComponent,
      openExportDataModal,
      popModal,
      createAlert,
      signOutRequest,
      setBasemapType,
      setToastMessage,
    },
    dispatch
  )
}

type Props = {
  createAlert: Function,
  activeBasemapType: string,
  userEmail: string,
  activeLayerIsEditable: boolean,
  demoMode: boolean,
  addingNewFeature: boolean,
  detailWindowOpen: boolean,
  geometryWindowOpen: boolean,
  editWindowOpen: boolean,
  agencyName: string,
  isTestAccountForRealAgency: boolean,
  signOutRequest: Function,
  openAccountSignInModal: Function,
  openAboutModal: Function,
  openExportDataModal: Function,
  openModalComponent: Function,
  openLicenseAgreementModal: Function,
  openLayerConfig: Function,

  popModal: Function,
  refreshMap: Function,
  setBasemapType: Function,
  signInDisabled: boolean,
  setToastMessage: Function,
}

const TopNavbar = (props: Props) => {
  const [windowWidth, setWindowWidth] = useState(0)
  const { setBasemapType, activeBasemapType } = props

  useLayoutEffect(() => {
    // because need to know when window width changes, not just on load.
    const updateSize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return (
    <View style={[styles.container, { alignItems: 'center' }]}>
      <InfrastructureIconsMenu
        isTestAccountForRealAgency={props.isTestAccountForRealAgency}
        agencyName={props.agencyName}
      />
      <View style={{ flex: 1 }} />
      {windowWidth > 580 && <SearchBar />}
      {windowWidth > 990 && (
        <View style={{ marginLeft: 8, marginRight: 8, marginTop: 6, marginBottom: 6 }}>
          <Button
            style={{ background: 'none', color: GLYPH_COLOR, border: 0, fontSize: 15 }}
            onClick={props.openAccountSignInModal}
          >
            {props.userEmail}
          </Button>
        </View>
      )}
      <View style={styles.container}>
        <DropdownButton
          id="BasemapDropdown"
          title={
            <>
              <Glyphicon
                glyph="globe"
                style={{
                  fontSize: 22,
                  marginLeft: 8,
                  marginRight: 8,
                  marginTop: 6,
                  color: GLYPH_COLOR,
                }}
              />
              {windowWidth > 1150 && <Text style={styles.text}> Basemap</Text>}
            </>
          }
          style={buttonStyle}
        >
          {baseMapTypeList.map((baseMapType, index) => (
            <MenuItem
              key={index}
              eventKey={index}
              active={baseMapType.mapType === activeBasemapType}
              onClick={() => setBasemapType(baseMapType.mapType)}
            >
              <div style={dropdownAlign}>{baseMapType.displayName}</div>
            </MenuItem>
          ))}
        </DropdownButton>
      </View>
      <View style={styles.container}>
        <DropdownButton
          id="MenuDropdown"
          pullRight
          title={
            <>
              <Glyphicon
                glyph="menu-hamburger"
                style={{
                  fontSize: 22,
                  marginLeft: 8,
                  marginRight: 8,
                  marginTop: 6,
                  color: GLYPH_COLOR,
                }}
              />
              {windowWidth > 1050 && <Text style={styles.text}> Menu</Text>}
            </>
          }
          style={buttonStyle}
        >
          <MenuItem
            eventKey="1"
            onClick={
              props.signInDisabled
                ? () => props.setToastMessage('An internet connection is required to sign in', 2000)
                : props.openAccountSignInModal
            }
            className={props.signInDisabled ? 'disabled' : null}
          >
            <div style={dropdownAlign}> {props.demoMode ? 'Sign In' : 'Account'} </div>
          </MenuItem>
          <MenuItem eventKey="2" onClick={() => props.openLicenseAgreementModal(true)}>
            <div style={dropdownAlign}> License Agreement </div>
          </MenuItem>
          <MenuItem
            eventKey="3"
            onClick={() => {
              let f = () => <LayerConfig popModal={props.popModal} refreshMap={props.refreshMap} />
              props.openLayerConfig(f)
            }}
          >
            <div style={dropdownAlign}> Layers </div>
          </MenuItem>
          <MenuItem eventKey="4" onClick={props.openExportDataModal}>
            <div style={dropdownAlign}>Export Data</div>
          </MenuItem>
          <MenuItem eventKey="5" onClick={props.openAboutModal}>
            <div style={dropdownAlign}> About </div>
          </MenuItem>
          <MenuItem eventKey="6" onClick={props.signOutRequest}>
            <div style={dropdownAlign}> Sign Out </div>
          </MenuItem>
        </DropdownButton>
      </View>
    </View>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopNavbar)
