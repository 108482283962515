import React from 'react'
import { View, ActivityIndicator } from 'react-native-web'

export default props => (
  <View style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
    <ActivityIndicator
      size={props.size || 'small'}
      animating
      style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
    />
  </View>
)
