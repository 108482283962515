import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import uuid from 'uuid'
import * as actions from '../actions'
import * as featureActions from '../actions/FeatureActions'
import * as editorActions from '../actions/EditorActions'
import * as mapActions from '../actions/MapActions'
import * as selectors from '../selectors'
import * as types from '../constants/ActionTypes'
import * as alertTypes from '../constants/AlertTypes'
import firebase, { functions } from '../firebase'
import { makePattern } from './utils'
import * as turf from '@turf/turf'
import { client, GET_FEATURE } from '../apollo'

const outsideBoundaryAlert = actions.createAlert(
  'cannotAddFeatureOutsideAgencyBoundary',
  ['OK'],
  'Outside of Boundary',
  'Edits cannot be made outside the Editing Boundary.'
)
const doesNotHaveBoundaryAlert = actions.createAlert(
  'agencyDoesNotHaveBoundaryLoaded',
  ['OK'],
  'Boundary does not exist',
  'Warning: You are making edits even though an editing boundary is not loaded. Please contact support.'
)

export function* handleSubmitFeatureAttributeEdits({ values }) {
  const currentFeature = yield select(selectors.getCurrentFeature)
  let currentFeatureId = yield select(selectors.getCurrentFeatureId)
  let dbOperation = 'update'
  const featureSetGuid = yield select(selectors.getActiveFeatureSetGuid)

  const newFeatureProperties = { ...values }

  if (currentFeature) {
    if (!currentFeatureId) {
      currentFeatureId = uuid.v1()
      dbOperation = 'insert'
    }

    const featureSchema = yield select(selectors.getCurrentFeatureSchema)
    const attachmentPropertyNames = featureSchema.attachmentPropertyNames || []

    for (const name of attachmentPropertyNames) {
      if (newFeatureProperties[name] && Array.isArray(newFeatureProperties[name])) {
        newFeatureProperties[name] = yield Promise.all(
          newFeatureProperties[name].map(async attachment => {
            if (!attachment.blob) {
              // File must have already been uploaded to storage in a previous edit
              return attachment
            }

            // Since form gave us a File, this is a new attachment
            const attachmentGuid = uuid.v1()
            const metadata = {
              customMetadata: {
                fileName: attachment.fileName,
              },
            }

            await firebase
              .storage()
              .ref(`featureAttachments/${attachmentGuid}`)
              .put(attachment.blob, metadata)

            // Now that blob is saved to Firebase Storage, set the value to the following which is expected
            // by the 'editFeature' cloud function; this is also used by the feature detail and edit windows
            return {
              fileName: attachment.blob.name,
              fileType: attachment.blob.type,
              attachmentGuid,
            }
          })
        )
      }
    }

    if (Object.keys(newFeatureProperties).length > 0) {
      currentFeature.properties = newFeatureProperties
    } else {
      // We delete this so we don't try to write an empty object to firebase (which crashes)
      delete currentFeature.properties
    }

    yield put(
      featureActions.saveFeatureEditRequest({
        feature: currentFeature,
        featureSetGuid,
        featureGuid: currentFeatureId,
        dbOperation,
        modifiedDate: new Date(Date.now()).toISOString(),
      })
    )
  }
}

export function* handleCoordianteSubmit(data) {
  const lat = data.lat
  const lng = data.lng
  const geom = data.geom
  const layerKey = data.layerKey
  yield put(editorActions.addEditPointLocation(lat, lng, geom, layerKey))
  yield put(mapActions.zoomToLocationAction(lat, lng, 18))
}

export function* handleAddEditPointLocation(data) {
  const point = turf.point([data.lng, data.lat])
  const boundary = yield select(selectors.getAgencyBoundary)
  if (boundary && !turf.inside(point, boundary)) {
    yield put(outsideBoundaryAlert)
    yield put(editorActions.clearFeatureGeometry())
  } else if (!boundary) {
    yield put(doesNotHaveBoundaryAlert)
  }
}

export function* handleMapDrawUpdate(action) {
  let feature
  if (Array.isArray(action.feature)) {
    feature = action.feature[0]
  } else {
    feature = action.feature
  }
  const geomType = feature.geometry.type
  const boundary = yield select(selectors.getAgencyBoundary)
  if (boundary) {
    if (geomType === 'LineString') {
      const lastPoint = feature.geometry.coordinates[feature.geometry.coordinates.length - 1]
      const point = turf.point(lastPoint)
      if (!turf.inside(point, boundary)) {
        yield put(outsideBoundaryAlert)
        yield put(editorActions.undoFeatureGeometryVertex())
      }
    } else if (geomType === 'Polygon') {
      if (
        feature.geometry.coordinates &&
        feature.geometry.coordinates[0] &&
        !feature.geometry.coordinates[0].includes(null)
      ) {
        const flattenedBoundary = turf.flatten(boundary)
        const withinBoundary = flattenedBoundary.features.some(bnd => {
          return turf.booleanContains(bnd, feature)
        })
        if (!withinBoundary) {
          const featureEditHistory = yield select(selectors.getFeatureEditHistory)
          if (featureEditHistory.length > 1) {
            yield put(outsideBoundaryAlert)
            yield put(editorActions.undoFeatureGeometryVertex())
          } else {
            yield put(
              actions.createAlert(
                'cannotAddPolygonOutsideAgencyBoundary', // This alert name is referenced in map component
                ['OK'],
                'Outside of Boundary',
                'Edits cannot be made outside the Editing Boundary.'
              )
            )
            yield put(editorActions.clearFeatureGeometry())
          }
        }
      } else {
        return
      }
    } else {
      console.warn(`a feature with ${geomType} geometry type has gotten into mapDrawUpdate()`)
    }
  } else {
    yield put(doesNotHaveBoundaryAlert)
  }
}

export function* handleMapDrawUpdateMulti(feature) {
  const boundary = yield select(selectors.getAgencyBoundary)
  const featureCollection = turf.featureCollection(feature.feature)
  const combinedFeature = turf.combine(featureCollection)
  const flattenedBoundary = turf.flatten(boundary)
  let withinBoundary
  if (boundary) {
    for (const singlePolygon of combinedFeature.features[0].geometry.coordinates) {
      const comparePoly = turf.polygon(singlePolygon)
      withinBoundary = flattenedBoundary.features.some(bnd => {
        return turf.booleanContains(bnd, comparePoly)
      })
      if (!withinBoundary) {
        yield put(outsideBoundaryAlert)
        yield put(editorActions.undoFeatureGeometryVertex())
      }
    }
  } else {
    yield put(doesNotHaveBoundaryAlert)
  }
}

export function* handleSaveFeatureEditRequest({ feature, featureSetGuid, featureGuid, dbOperation, modifiedDate }) {
  const connected = yield select(selectors.getIsConnected)
  if (!connected) {
    yield put(
      actions.createAlert(
        'cannotEditOfflineAlert',
        ['OK'],
        'You are offline',
        'IncidentView Editor cannot reach the server. ' +
          'Please check your internet connection, then retry saving your edit.'
      )
    )
    return
  }

  const isDemoMode = yield select(selectors.getIsDemoMode)
  if (!isDemoMode) {
    // We don't need to save the id or guid to firebase because it's already saved at the path
    if (feature) {
      delete feature.id
      if (feature.properties) {
        delete feature.properties.guid
        delete feature.properties.featureSetGuid
      }
    }

    try {
      yield call(functions.editFeature, {
        feature,
        featureSetGuid,
        featureGuid,
        dbOperation,
        modifiedDate,
      })
      yield put(actions.saveFeatureEditSuccess({ featureGuid }))
    } catch (error) {
      yield put(actions.createAlert('failedEditingAlert', ['OK'], 'Feature edit failed', error.message))
      yield put(actions.saveFeatureEditFailure(error))
    } finally {
      yield put(actions.featureUnselected())
    }
  }
}

export function* handleDeleteFeatureAlert({ selectedOptionIndex, alertName }) {
  // If the user chooses OK then we will exit the application
  if (selectedOptionIndex === 0) {
    const currentFeatureId = yield select(selectors.getCurrentFeatureId)
    const featureSetGuid = yield select(selectors.getActiveFeatureSetGuid)

    yield put(
      featureActions.saveFeatureEditRequest({
        featureSetGuid,
        featureGuid: currentFeatureId,
        dbOperation: 'delete',
        modifiedDate: new Date(Date.now()).toISOString(),
      })
    )
  }
}

export function* handleIsAgiGeomEditAlert({ selectedOptionIndex, alertName }) {
  if (selectedOptionIndex === 0) {
    const currentFeature = yield select(selectors.getCurrentFeature)
    if (currentFeature) {
      yield put(editorActions.openGeometryEditingWindow())
    } else {
      yield put(editorActions.startAddNewFeature())
    }
  }
}

export function* handleIsAgiFeatureEditAlert({ selectedOptionIndex, alertName }) {
  if (selectedOptionIndex === 0) {
    yield put(editorActions.openEditWindow())
  }
}

export function* handleSubmitFeatureGeometryEdits() {
  const addingNewFeature = yield select(selectors.getAddingNewFeature)
  // If we are editing an existing feature geometry then save on submit
  // otherwise they will be moving to the edit form and can submit from there
  const currentFeature = yield select(selectors.getCurrentFeature)
  // remove id created from mapboxGlDraw
  delete currentFeature.id
  let currentFeatureId = yield select(selectors.getCurrentFeatureId)
  if (!currentFeatureId) {
    currentFeatureId = uuid.v1()
  }
  if (!addingNewFeature) {
    const featureSetGuid = yield select(selectors.getActiveFeatureSetGuid)
    yield put(
      featureActions.saveFeatureEditRequest({
        feature: currentFeature,
        featureSetGuid,
        featureGuid: currentFeatureId,
        dbOperation: 'update',
        modifiedDate: new Date(Date.now()).toISOString(),
      })
    )
  } else {
    const dataSourceName = yield select(selectors.getActiveDataSourceName)
    yield put(editorActions.featureSelected({ feature: currentFeature, dataSourceName }))
    yield put(editorActions.openEditWindow())
  }
}

function* fetchFeatureInfo() {
  const featureGuid = yield select(selectors.getCurrentFeatureId)
  const featureSetGuid = yield select(selectors.getActiveFeatureSetGuid)
  if (featureGuid && featureSetGuid) {
    try {
      const res = yield client.query({
        query: GET_FEATURE,
        variables: { featureGuid },
        fetchPolicy: 'network-only',
      })
      yield put(editorActions.fetchFeatureInfoSuccess(res.data.getFeature))
    } catch (error) {
      console.error(`Failed fetching feature info for ${featureGuid}`, error)
      let errorMessage
      if (error.message.includes(`${featureGuid} does not exist`)) {
        errorMessage = 'This feature does not exist in the database. Please contact support to export vector tiles.'
      } else {
        errorMessage = "There was a problem retrieving this feature's data from the server."
      }
      yield put(editorActions.fetchFeatureInfoFailure(errorMessage))
    }
  }
}

export default function* featuresSaga() {
  yield all([
    takeEvery(types.FEATURE_SELECTED, fetchFeatureInfo),
    takeEvery(types.SUBMIT_FEATURE_ATTRIBUTE_EDITS, handleSubmitFeatureAttributeEdits),
    takeEvery(types.MAP_DRAW_UPDATE, handleMapDrawUpdate),
    takeEvery(types.MAP_DRAW_UPDATE_MULTI, handleMapDrawUpdateMulti),
    takeEvery(types.ADD_EDIT_POINT_LOCATION, handleAddEditPointLocation),
    takeEvery(types.SAVE_FEATURE_EDIT_REQUEST, handleSaveFeatureEditRequest),
    takeEvery(types.COORDINATE_SUBMIT, handleCoordianteSubmit),
    takeEvery(
      makePattern(types.DISMISS_ALERT, { alertName: alertTypes.DELETE_FEATURE_ALERT }),
      handleDeleteFeatureAlert
    ),
    takeEvery(
      makePattern(types.DISMISS_ALERT, { alertName: alertTypes.IS_AGI_GEOM_EDIT_ALERT }),
      handleIsAgiGeomEditAlert
    ),
    takeEvery(
      makePattern(types.DISMISS_ALERT, { alertName: alertTypes.IS_AGI_FEATURE_EDIT_ALERT }),
      handleIsAgiFeatureEditAlert
    ),
    takeEvery(types.SUBMIT_FEATURE_GEOMETRY_EDITS, handleSubmitFeatureGeometryEdits),
  ])
}
