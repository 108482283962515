// Place any styles here that are shared between components
import { HOLO_BLUE_COLOR } from '../../common/constants/index'

export const TOP_NAVBAR_HEIGHT = '3.4rem'

// From flatly bootswatch theme
export const NAVBAR_BACKGROUND_COLOR = '#ecf0f1'
export const NAVBAR_SHADOW_COLOR = '#a4abb3'
export const NAV_BUTTON_COLOR_HOVER = '#bfc6ce'
export const TOOLBAR_BACKGROUND_COLOR = '#dce4ec'
export const EDIT_BUTTON_COLOR = '#dce4ec'
export const GLYPH_COLOR = '#000000'
export const BUTTON_COLOR_HOVER = '#bfc6ce'

export const PLATFORM_COLOR = '#3498db'
const textColor = '#03A9F4'
const background = 'rgba(0,0,0,0.85)'
const childItemsBackground = 'rgba(0,0,0,0.5)' // just add a little more opacity

// export const textInputStyle = {
//   backgroundColor: '#444',
//   color: '#eee',
//   borderColor: '#aaa',
//   fontSize: '0.85rem',
//   paddingTop: '0.75rem',
//   paddingBottom: '0.75rem',
//   paddingLeft: '0.6rem',
//   paddingRight: '0.6rem',
// }

export const textInputStyle = {
  color: '#444',
  borderColor: '#aaa',
  fontSize: '0.85rem',
  paddingTop: '0.75rem',
  paddingBottom: '0.75rem',
  paddingLeft: '0.6rem',
  paddingRight: '0.6rem',
}

export const listGroupStyle = {
  listGroup: {
    maxHeight: '210px',
    overflow: 'scroll',
  },
  listGroupItem: {
    background: childItemsBackground,
    color: 'white',
    fontSize: '1.1rem',
  },
  listGroupItemActive: {
    color: 'white',
    fontSize: '1.1rem',
  },
  listGroupItemHeading: {
    color: 'white',
    fontSize: '1.1rem',
  },
  listGroupHeader: {
    backgroundColor: HOLO_BLUE_COLOR,
  },
}

export const toolWindowStyle = {
  topbar: {
    width: '100%',
    height: '3rem',
    background: TOOLBAR_BACKGROUND_COLOR,
    zIndex: 10,
    color: textColor,
    paddingTop: '10px',
    paddingLeft: '10px',
  },
  h4: {
    marginTop: 0,
    color: textColor,
    fontSize: '1.5rem',
    display: 'inline',
  },
  closeButton: {
    position: 'absolute',
    right: '6px',
    top: '6px',
    color: textColor,
    background: 'rgb(50,50,50)',
  },
  mainDiv: {
    color: 'white',
    fontSize: '0.8rem',
    position: 'absolute',
    right: 0,
    // TODO: for some reason TOP_NAVBAR_HEIGHT is undefined
    top: '3.4rem', // `calc(${TOP_NAVBAR_HEIGHT})`,
    background: background,
  },
  textColor: {
    color: textColor,
  },
  ...listGroupStyle,
  sectionSubHeaderDiv: {
    height: '2.25rem',
    padding: '0.5rem',
    backgroundColor: '#333',
  },
  sectionSubHeaderH4: {
    color: 'white',
    margin: 0,
    fontSize: '1.25rem',
  },
}
