/* @flow */
import React from 'react'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { View, Text, TextInput } from 'react-native-web'
import { activateSearchBar, searchTextUpdated, setCurrentSearchBarResult } from '../../common/actions/SearchBarActions'
import * as selectors from '../../common/selectors'
import { ListGroup, ListGroupItem, Glyphicon } from 'react-bootstrap'
import Spinner from 'react-spinner'
import 'react-spinner/react-spinner.css'
import { listGroupStyle } from '../styles'

const searchBarStyle = {
  top: '0.4rem',
  height: '2.4rem',
  fontSize: '1.2rem',
  display: 'flex',
  flexGrow: 2,
  zIndex: 28,
}

const borderColor = '#9A9A9A'

const inputStyle = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,0.5)',
  borderColor: borderColor,
  color: 'white',
  height: '100%',
  borderTopLeftRadius: '0.4rem',
  borderBottomLeftRadius: '0.4rem',
  padding: '0.4rem',
  outline: 'none',
}

const searchButtonStyle = {
  width: '3.5rem',
  height: '100%',
  background: 'rgba(0,0,0,0)',
  color: 'rgb(0, 150, 136)', // same color as bootstrap switch default.
  borderTopRightRadius: '0.4rem',
  borderBottomRightRadius: '0.4rem',
  borderLeft: 0,
  borderColor: borderColor,
}

const clearButtonStyle = {
  width: '2.5rem',
  height: '2.3rem',
  backgroundColor: 'inherit',
  border: 0,
  marginLeft: '-2.5rem',
  marginTop: '0.3rem',
}

const spinnerStyle = {
  width: '2.5rem',
  height: '2.3rem',
  marginLeft: '-2.5rem',
  marginTop: '0.3rem',
}

const mapStateToProps = state => {
  return {
    searchBarState: state.searchBar,
    currentSearchResult: selectors.getCurrentSearchBarResult(state),
    results: selectors.getSearchBarResults(state),
    requestingResults: selectors.getSearchBarRequestingResults(state),
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      activateSearchBar,
      searchTextUpdated,
      setCurrentSearchBarResult,
    },
    dispatch
  )
}

type Props = {
  activateSearchBar: Function,
  searchBarState: Object,
  results: Object,
  searchTextUpdated: Function,
  setCurrentSearchBarResult: Function,
  currentSearchResult: ?number,
  requestingResults: ?boolean,
}

type State = {
  loading: boolean,
}

const TextControl = props => {
  let text = props.value
  if (!text) {
    text = 'Search...'
  }
  const { active, textStyle, ...otherProps } = props

  if (true || active) {
    return <TextInput {...otherProps} style={[textStyle, { paddingRight: 35 }]} />
  }

  // else:
  let viewStyle = { ...props.textStyle }
  return (
    <View style={viewStyle}>
      <Text {...props}>{text}</Text>
    </View>
  )
}

class SearchBar extends React.Component<Props, State> {
  _activeItem: null | ListGroupItem
  constructor(props: Props) {
    super(props)
    this.state = { loading: false }
  }

  componentDidUpdate() {
    const activeItem = ReactDOM.findDOMNode(this._activeItem)
    if (activeItem) {
      activeItem.scrollIntoViewIfNeeded()
    }
  }

  render() {
    const {
      activateSearchBar,
      searchBarState,
      searchTextUpdated,
      currentSearchResult,
      setCurrentSearchBarResult,
      requestingResults,
    } = this.props
    const { searchText } = searchBarState

    // only show x button if we're not currently showing spinner
    const showXButton = searchText && !requestingResults
    return (
      <div id="ivSearchBar" style={searchBarStyle}>
        <div style={{ flexGrow: 10 }}>
          <TextControl
            onClick={() => activateSearchBar()}
            onChangeText={val => searchTextUpdated(val)}
            textStyle={inputStyle}
            value={searchText}
            active={searchBarState.active ? searchBarState.active : undefined}
          />

          {searchBarState.active && (
            <ListGroup ref="listGroup" style={{ backgroundColor: 'rgba(0,0,0,0.85)' }}>
              {this.props.results.map((result, index) => {
                const isActive = index === currentSearchResult
                return (
                  <ListGroupItem
                    key={index}
                    active={isActive}
                    onClick={() => setCurrentSearchBarResult(index)}
                    style={isActive ? listGroupStyle.listGroupItemActive : listGroupStyle.listGroupItem}
                    ref={ref => {
                      if (isActive) {
                        this._activeItem = ref
                      }
                    }}
                  >
                    {result.displayText}
                  </ListGroupItem>
                )
              })}
            </ListGroup>
          )}
        </div>

        {requestingResults && (
          <div style={spinnerStyle}>
            <Spinner />
          </div>
        )}
        {showXButton && (
          <button style={clearButtonStyle} onClick={() => searchTextUpdated('')}>
            <Glyphicon glyph="remove" />
          </button>
        )}
        <button style={searchButtonStyle}>
          <Glyphicon style={{ display: 'block', textAlign: 'center' }} glyph="search" />
        </button>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar)
