/* @flow */
import * as Immutable from 'immutable'
import { REHYDRATE } from 'redux-persist/constants'
import * as types from '../constants/ActionTypes'
import { createReducerFromHandlers } from './utils'

export const FeatureState = Immutable.List()
export const initialState = FeatureState

const handlers = {
  [REHYDRATE]: (state, action) => {
    const previousState = action.payload.featureGuids
    return previousState ? Immutable.fromJS(previousState) : state
  },

  // [types.SAVE_FEATURE_EDIT_REQUEST]: (state, action) => {
  //   return state.includes(action.featureGuid) ? state : state.push(action.featureGuid)
  // },

  [types.FEATURE_EDITS_UPDATED]: (state, action) => {
    if (action.features) {
      let newGuids = []
      for (const guid of Object.keys(action.features)) {
        if (!state.includes(guid)) {
          newGuids.push(guid)
        }
      }
      return state.concat(Immutable.List(newGuids))
    }
    return state
  },

  [types.FEATURE_EDITS_REMOVED]: (state, action) => {
    return state.filter(guid => !action.featureGuids.includes(guid))
  },

  [types.SIGN_IN_SUCCESS]: () => initialState,
}

export default createReducerFromHandlers(initialState, handlers)
