export const LICENSE_TEXT = `END-USER LICENSE AGREEMENT FOR IncidentView™ SOFTWARE


IMPORTANT--READ CAREFULLY: This End-User License Agreement ("Agreement") is a legal contract between you (either an individual or a single business entity) and Alsea Geospatial, Inc. (“AGI”) for the Vendor software product identified above, including, as applicable, any associated media, printed materials, and "online" or electronic documentation (the "Software Application").

BY CLICKING THE [TOUCH TO ACCEPT] ICON BELOW, OR BY INSTALLING, COPYING, OR OTHERWISE USING THE SOFTWARE APPLICATION, YOU AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT, INCLUDING THE WARRANTY DISCLAIMERS, LIMITATIONS OF LIABILITY AND TERMINATION PROVISIONS BELOW. IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, DO NOT INSTALL OR USE THE SOFTWARE APPLICATION, AND EXIT NOW.

LICENSE TERMS

Following acceptance of this Agreement, you may install, execute, and use one (1) registered copy of the Software Application for your own personal, individual use, under the following terms.  If you are a business or government agency, AGI grants you a non-exclusive, non-transferable, limited right to use the Software Application only for your governmental agency or private business under the following terms.
Use on a PC, Tablet, or Smartphone. You may install the single registered copy on one hard disk or other applicable storage device for one computer, tablet, or smartphone, and you may access and use the Software Application at that location so long as only one copy of the Software Application is in operation. If you are a government agency or a private business rather than an individual, you may authorize the personnel associated with your agency or business to use the Software Application, but only one person at a time, on one computer or other device at a time. The primary user of the computer or other device on which the Software Application is installed may make a second copy for his or her exclusive use on a backup or portable computer or other device, so long as each registered copy of the Software Application is used on only one computer or other device at a time.
Use on a Network. You may store, install and access the registered version of the Software Application through an internal network, provided that you first obtain and pay for a license for each workstation or device that will access the Software Application through the network. For instance, if 5 different workstations will access the Software Application on the network, each workstation must have its own paid-up license, regardless of whether the workstations use the Software Application at different times or concurrently.

OTHER LIMITATIONS

You may not rent, lease, re-license, nor lend the Software Application. You may not reverse engineer, decompile, or disassemble the Software Application, except and only to the extent that such activity is expressly permitted by applicable law notwithstanding this limitation.  You agree to not remove any copyright or other notice of proprietary rights in the Software Application.
AGI may provide you with support services related to the Software Application ("Support Services"). Use of Support Services is governed by AGI policies and programs described in the user manual, in "online" documentation, and/or in other AGI-provided materials. Any supplemental software code provided to you as part of the Support Services shall be considered part of the Software Application and subject to the terms and conditions of this Agreement. With respect to technical information you provide to AGI as part of the registration of your license to the Software Application or in connection with the Support Services, AGI may use such information for its business purposes, including for product support and development. However, AGI will not utilize such technical information in a form that personally identifies you.
When you cease using the Software Application or upon termination of the license, you agree to return all copies of the Software Application (including all component parts, the media and printed materials, any upgrades, and your registration) and certify the destruction of all digital or electronic copies thereof.
Without prejudice to any other rights, AGI may terminate this Agreement if you fail to comply with the terms and conditions of this Agreement.  In such event, you must destroy all copies of the Software Application and all of its component parts, and AGI may suspend or deactivate your use of the Software Application with or without notice.

GOVERNING LAW

This Agreement shall be governed by the laws of the State of Oregon.

PROPRIETARY RIGHTS

Copyright. All title and copyrights in and to the Software Application (including, without limitation, any images, photographs, animations, video, audio, music, text, and "applets" incorporated into the Software Application), the accompanying media and printed materials, and any copies of the Software Product are owned by AGI or its suppliers. The Software Application is protected by copyright laws and international treaty provisions. Therefore, you must treat the Software Application like any other copyrighted material, subject to the provisions of this Agreement.
U.S. Government Restricted Rights. The Software Application and documentation are provided with RESTRICTED RIGHTS. Use, duplication, or disclosure by the Government is subject to restrictions as set forth in subparagraph (c)(1)(ii) of the Rights in Technical Data and Computer Software clause at DFARS 252.227-7013 or subparagraphs (c)(1) and (2) of the Commercial Computer Software -- Restricted Rights at 48 C.F.R. 52.227-19, as applicable. Manufacturer for such purpose is Alsea Geospatial, Inc., PO Box 2380, Corvallis, OR 97339.  You agree not to export the Software Application or any results obtained from its use in violation of any export restriction laws or regulations.
Submissions. Should you decide to transmit to AGI’s Web site by any means or by any media any materials or other information (including, without limitation, ideas, concepts or techniques for new or improved services and products), whether as information, feedback, data, questions, comments, suggestions or the like, you agree such submissions are unrestricted and shall be deemed non-confidential and you automatically grant AGI and its assigns a nonexclusive, royalty-free, worldwide, perpetual, irrevocable license, with the right to sublicense, to use, copy, transmit, distribute, create derivative works of, display and perform the same.

DISCLAIMER OF WARRANTY

THIS SOFTWARE APPLICATION IS PROVIDED "AS IS." AGI AND ITS SUPPLIERS DO NOT AND CANNOT WARRANT THE PERFORMANCE OR RESULTS YOU MAY OBTAIN BY USING THE SOFTWARE APPLICATION OR SUCH FILES. AGI AND ITS SUPPLIERS MAKE NO WARRANTIES, EXPRESS OR IMPLIED, AS TO TITLE OR INFRINGEMENT OF THIRD-PARTY RIGHTS, MERCHANTABILITY, OR FITNESS FOR ANY PARTICULAR PURPOSE.
Good data processing procedure dictates that any program be thoroughly tested with non-critical data before relying on it. The user must assume the entire risk of using the Software Application. ANY LIABILITY OF AGI FOR A DEFECTIVE COPY OF THE SOFTWARE APPLICATION WILL BE LIMITED EXCLUSIVELY TO REPLACEMENT OF YOUR COPY OF THE SOFTWARE APPLICATION WITH ANOTHER COPY OR REFUND OF THE INITIAL LICENSE FEE AGI RECEIVED FROM YOU FOR THE DEFECTIVE COPY OF THE PRODUCT. IN NO EVENT WILL AGI OR ITS SUPPLIERS BE LIABLE TO YOU FOR ANY CONSEQUENTIAL, INCIDENTAL OR SPECIAL DAMAGES, INCLUDING ANY LOST PROFITS OR LOST SAVINGS, EVEN IF AGI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR FOR ANY CLAIM BY ANY THIRD PARTY.
Some states or jurisdictions do not allow the exclusion or limitation of incidental, consequential or special damages, or the exclusion of implied warranties or limitations on how long a given warranty may last, so the above limitations may not apply to you.

DATA

Data Disclaimer. The source data for the Software Application will be provided by you and other third party sources (the “Data”). Such Data may contain some nonconformities, defects, errors, or omissions.  You assume all responsibility for Data accuracy and completeness.  AGI assumes no responsibility or liability for Data accuracy and completeness, nor does it assume responsibility for correcting any nonconformities, defects, errors, or omissions in the course of AGI adapting the Data for use with the Software Application.  AGI MAKES NO WARRANTY WITH RESPECT TO THE DATA.  Without limiting the generality of the preceding sentence, AGI does not warrant that the Data will meet your needs or expectations, the use of the Data will be uninterrupted, or that all nonconformities can or will be corrected.  AGI is not inviting reliance on this Data, and you should always verify actual Data, including, but not limited to, map, spatial, raster, and tabular information.
Indemnification. You, at your own expense, shall defend, indemnify, and hold harmless AGI, its agents, affiliates, successors, and assigns with respect to any claim or action brought against AGI, its agents, affiliates, successors, and assigns arising out of or in connection with the source Data for the Software Application, including, but not limited to, the adaption or use of such Data, whether or not such claim or action is attributable or based upon in whole or in part any condition, characteristic, flaw, or other attribute of such Data; and any negligent or illegal act or omission of you or any of your agents, contractors, employees, or other users or accessees.  AGI shall promptly notify you of any such claim and shall provide reasonable assistance in connection with the defense and/or settlement thereof.
Situations Beyond AGI’s Control. You acknowledge that you will be using a third-party connection and communication (e.g. broadband connection) for the Software Application for personal computers and mobile devices (e.g. smartphones, tablets, or other similar devices), and you assume all responsibility and liability for the breakdown or failure of such third-party systems.  AGI assumes no responsibility or liability for such third-party system or for any degradation of telecommunications service, broadband service, or other communication service of such third-party.`
