import { all, fork } from 'redux-saga/effects'
import alerts from './alerts' // eslint-disable-line import/no-unresolved
import authSaga from './auth'
import connectivitySaga from './connectivity'
import featuresSaga from './features'
import firebaseSaga from './firebase'
import keepScreenActiveSaga from './keepScreenActive' // eslint-disable-line import/no-unresolved
import lifecycleSaga from './lifecycle'
// import localDataSaga from './localData'
import mapSaga from './map'
// import offlineSyncronizer from './offlineSyncronizer'
import toastSaga from './toast'
import searchBarSaga from './searchBar'

export default function* rootSaga() {
  yield all([
    fork(alerts),
    fork(authSaga),
    fork(connectivitySaga),
    fork(featuresSaga),
    fork(firebaseSaga),
    fork(keepScreenActiveSaga),
    fork(lifecycleSaga),
    // fork(localDataSaga),
    fork(mapSaga),
    // fork(offlineSyncronizer),
    fork(searchBarSaga),
    fork(toastSaga),
  ])
}
