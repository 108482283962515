/* @flow */
import React from 'react'
import Modal from './FullscreenModal'
import { Button, Glyphicon } from 'react-bootstrap'
import PDF from 'react-pdf-js'

const bodyStyle = {
  width: '100%',
  height: '90%',
}

const imageStyle = {
  width: '100%',
  height: '90%',
}

type Props = {
  closeFileViewer: Function,
  dataUrl: ?string,
  isImage: boolean,
  interpolatedStyles: Object,
}

class FileViewer extends React.Component<Props, *> {
  state = {}

  onDocumentComplete = pages => {
    this.setState({ page: 1, pages })
  }

  onPageComplete = page => {
    this.setState({ page })
  }

  handlePrevious = () => {
    this.setState({ page: this.state.page - 1 })
  }

  handleNext = () => {
    this.setState({ page: this.state.page + 1 })
  }

  renderPagination = (page, pages) => {
    let previousButton = (
      <Button style={{ float: 'left' }} onClick={this.handlePrevious}>
        <Glyphicon glyph="arrow-left" /> Previous
      </Button>
    )
    if (page === 1) {
      previousButton = (
        <Button style={{ float: 'left' }}>
          <Glyphicon glyph="arrow-left" /> Previous
        </Button>
      )
    }
    let nextButton = (
      <Button style={{ float: 'right' }} onClick={this.handleNext}>
        Next <Glyphicon glyph="arrow-right" />
      </Button>
    )
    if (page === pages) {
      nextButton = (
        <Button style={{ float: 'right' }}>
          Next <Glyphicon glyph="arrow-right" />
        </Button>
      )
    }
    return (
      <nav>
        <ul className="pager">
          {previousButton}
          {nextButton}
        </ul>
      </nav>
    )
  }

  render = () => {
    let pagination = null
    if (this.state.pages) {
      pagination = this.renderPagination(this.state.page, this.state.pages)
    }
    let viewer
    if (this.props.isImage) {
      viewer = <img alt="" src={this.props.dataUrl} style={imageStyle} />
    } else {
      viewer = (
        <div style={bodyStyle}>
          <PDF
            file={this.props.dataUrl}
            onDocumentComplete={this.onDocumentComplete}
            onPageComplete={this.onPageComplete}
            page={this.state.page}
          />
          {pagination}
        </div>
      )
    }
    return (
      <Modal
        interpolatedStyles={this.props.interpolatedStyles}
        closeModal={() => this.props.closeFileViewer()}
        hasTitleImage={false}
        title="File Viewer"
      >
        {viewer}
      </Modal>
    )
  }
}

export default FileViewer
