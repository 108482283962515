/* @flow */
import React from 'react'
import { StyleSheet, Text, View } from 'react-native-web'

const styles = StyleSheet.create({
  toastWrapper: {
    alignItems: 'center',
    position: 'absolute',
    left: 68,
    right: 68,
    bottom: 50,
  },
  toast: {
    backgroundColor: 'black',
    padding: 8,
    borderRadius: 8,
    shadowColor: 'black',
    shadowOpacity: 0.5,
    shadowOffset: { width: 1, height: 2 },
    shadowRadius: 2,
  },
  toastText: {
    color: 'white',
    textAlign: 'center',
  },
})

type Props = {
  message: ?string,
}

class Toast extends React.PureComponent<Props> {
  render = () => {
    if (!this.props.message) {
      return null
    }

    return (
      <View style={styles.toastWrapper}>
        <View style={styles.toast}>
          <Text style={styles.toastText}>{this.props.message}</Text>
        </View>
      </View>
    )
  }
}

export default Toast
