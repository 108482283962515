// @flow
import { ApolloClient, createHttpLink, split, InMemoryCache, from } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { useReduxLogger } from '../config'
import apolloLogger from 'apollo-link-logger'

const useLogger = process.env.NODE_ENV !== 'production' && useReduxLogger

export const createClient = token => {
  const GQLURL = 'graphql.incidentview.com/v1/graphql'
  const headers = token
    ? {
        authorization: `Bearer ${token}`,
        'x-hasura-role': 'editor',
      }
    : {}
  const httpLink = createHttpLink({
    uri: `https://${GQLURL}`,
    headers,
  })

  // Create a WebSocket link:
  const wsLink = new WebSocketLink({
    uri: `wss://${GQLURL}`,
    options: {
      reconnect: true,
      connectionParams: {
        headers,
      },
    },
  })

  const wrappedHttpLink = useLogger ? from([apolloLogger, httpLink]) : httpLink

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    wrappedHttpLink
  )
  client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  })
  return client
}

export let client

export * from './queries'
// export * from './mutations'
