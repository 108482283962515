import * as Immutable from 'immutable'
import { REHYDRATE } from 'redux-persist/constants'
import * as types from '../constants/ActionTypes'
import User, { createUserRecordFromFirebaseObj } from '../records/User'
import { createReducerFromHandlers } from './utils'

export const AuthState = Immutable.Record({
  // eslint-disable-line new-cap
  authData: null,
  user: new User({}),
  agency: null,
  agencyConfig: null,
  commonConfig: null,
  licenseAccepted: false,
  loadingAuthRequest: false,
  loadingApp: true,
  token: null,
  hasRunAuthenticated: false,
})

const initialState = new AuthState()

const handlers = {
  [types.APP_START]: (state, action) => (action.isConnected ? state : state.set('loadingApp', false)),
  [types.ID_TOKEN_CHANGED]: (state, action) =>
    state.set('token', action.token).set('authData', Immutable.fromJS(action.authData)),
  [types.AUTHENTICATED]: (state, action) => {
    return state.set('authData', Immutable.fromJS(action.authData)).set('hasRunAuthenticated', true)
  },
  // keep loadingApp true only if AUTHENTICATED hasn't been run yet
  // (should only happen the first time the app is run after a fresh install)
  [types.UNAUTHENTICATED]: state => {
    if (state.get('hasRunAuthenticated')) {
      return initialState.withMutations(_state => {
        // TODO: we want loadingApp to be false so that we avoid a situation where the user signs out simultaneously
        // TODO: when losing the internet, causing the map to never remount. However, we set it to true for now since
        // TODO: otherwise the app crashes on sign out as of Mapbox iOS SDK v3.6.0-beta.2
        _state.set('token', null)
        _state.set('loadingApp', true)
        _state.set('hasRunAuthenticated', true)
      })
    }
    return initialState
  },
  // preserve the hasRunAuthenticated value in the state
  [types.REGISTRATION_FLOW_FAILURE]: state => state.set('loadingApp', false),
  [types.REGISTRATION_FLOW_SUCCESS]: (state, action) => {
    return state
      .set('agency', Immutable.fromJS(action.agency))
      .set('agencyConfig', Immutable.fromJS(action.agencyConfig))
      .set('commonConfig', Immutable.fromJS(action.commonConfig))
      .set('user', createUserRecordFromFirebaseObj(action.user))
      .set('loadingApp', false)
  },
  [types.LOADING_APP]: (state, action) => state.set('loadingApp', action.loadingApp),
  [types.SIGN_IN_REQUEST]: state => state.set('loadingAuthRequest', true),
  [types.SIGN_IN_SUCCESS]: state => state.set('loadingAuthRequest', false),
  [types.SIGN_IN_FAILURE]: state => state.set('loadingAuthRequest', false).set('loadingApp', false),
  [types.GET_USERS_AGENCY_CONFIG_SUCCESS]: (state, action) => {
    return state.set('agencyConfig', Immutable.fromJS(action.agencyConfig))
  },
  [types.SET_LICENSE_STATE]: (state, action) => state.set('licenseAccepted', action.licenseState),
  [types.LICENSE_ACCEPTED]: state => state.set('licenseAccepted', true),

  [REHYDRATE]: (state, action) => {
    const previousState = action.payload.auth
    let newState = state
    if (previousState && previousState.licenseAccepted) {
      newState = newState.set('licenseAccepted', previousState.licenseAccepted)
    }
    if (previousState && previousState.agency) {
      newState = newState.set('agency', Immutable.fromJS(previousState.agency))
    }
    if (previousState && previousState.agencyConfig) {
      newState = newState.set('agencyConfig', Immutable.fromJS(previousState.agencyConfig))
    }
    if (previousState && previousState.user) {
      newState = newState.set('user', previousState.user)
    }
    if (previousState && previousState.authData) {
      newState = newState.set('authData', previousState.authData)
    }
    return newState
  },
}

export default createReducerFromHandlers(initialState, handlers)

// selectors moved to ../selectors dir
