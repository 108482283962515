/* @flow */
import * as Immutable from 'immutable'

const MapboxStyleRecord = Immutable.Record({
  id: null,
  version: 8,
  name: null,
  sources: Immutable.Map(),
  sprite: null,
  glyphs: null,
  layers: Immutable.List(),
})

export default MapboxStyleRecord
