/* @flow */
import React, { type Element } from 'react'
import { Glyphicon } from 'react-bootstrap'
import { Text, View, TouchableHighlight } from 'react-native-web'
import Spinner from 'react-spinner'
import 'react-spinner/react-spinner.css'
import { GLYPH_COLOR } from '../styles'

const text = {
  fontSize: 22,
  paddingTop: 1,
  justifyContent: 'center',
}

const modalWrapperStyle = {
  position: 'fixed',
  top: '0px',
  width: '100%',
  height: '100%',
  zIndex: 40,
  overflowY: 'scroll',
  pointerEvents: 'all',
}

const buttonView = {
  alignSelf: 'stretch',
  flexDirection: 'row',
  marginTop: 3,
  marginRight: 16,
  paddingTop: 5,
  paddingBottom: 8,
  paddingLeft: 22,
  paddingRight: 22,
  zIndex: 50,
}

const modalStyle = {
  position: 'relative',
  top: '30px',
  backgroundColor: 'white',
  fontSize: '1.1rem',
  borderRadius: '8px',
  marginLeft: 'auto',
  marginRight: 'auto',
  boxShadow: '0 0 5px black',
  width: '90%',
  height: '90%',
  marginBottom: '2rem',
}

const titleStyle = {
  position: 'relative',
  fontSize: '1.4rem',
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 23,
  paddingRight: 22,
  backgroundColor: 'rgb(220, 228, 236)',
}

const bodyStyle = {
  paddingTop: '1rem',
  padding: '1.5rem',
  fontSize: '0.8rem',
  width: '100%',
  height: '100%',
}

const closeButtonStyle = {
  position: 'relative',
  zIndex: 45,
}

const spinnerWrapper = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  backgroundColor: 'white',
  borderRadius: '8px',
  borderColor: 'white',
}

type Props = {
  children: Element<any>,
  closeModal: Function,
  title: string,
  hasCloseButton?: boolean,
  hasTitleImage: ?boolean,
  imagePath?: string,
  imageSize?: string,
  interpolatedStyles: Object,
  showSpinner?: boolean,
}

// TODO We should check this again on the next release, there is a flow bug that doesn't like the use of children
// $FlowFixMe
const FullscreenModal = ({
  children,
  closeModal,
  title,
  hasCloseButton = true,
  hasTitleImage,
  imagePath,
  imageSize,
  interpolatedStyles,
  showSpinner,
}: Props) => (
  <div style={{ ...modalWrapperStyle, opacity: interpolatedStyles.opacity }}>
    <div style={{ ...modalStyle, marginTop: interpolatedStyles.marginTop + 'rem' }}>
      {hasCloseButton ? (
        <TouchableHighlight className="edit-button" style={closeButtonStyle} onPress={closeModal}>
          <View style={buttonView}>
            <Glyphicon
              glyph="remove"
              style={{
                justifyContent: 'center',
                fontSize: 22,
                marginLeft: 4,
                marginRight: 4,
                marginTop: 4,
                color: GLYPH_COLOR,
              }}
            />
            <Text style={text}> Close</Text>
          </View>
        </TouchableHighlight>
      ) : null}
      <div style={titleStyle}>
        {hasTitleImage ? <img alt="" width={imageSize} height={imageSize} src={imagePath} /> : null}
        {title}
      </div>
      <div style={bodyStyle}>{children}</div>
      {showSpinner ? (
        <div style={spinnerWrapper}>
          <Spinner />
        </div>
      ) : null}
    </div>
  </div>
)

export default FullscreenModal
