/* @flow */
import { createSelector } from 'reselect'

export const getHardwareUid = (state: Object): string => state.device.hardwareUid
export const getDeviceName = (state: Object): string => state.device.displayName
export const getDeviceSystemVersion = (state: Object): string => state.device.systemVersion
export const getAvlColor = (state: Object): string => state.device.avlColor
export const getCadAvlSharingId = (state: Object): string => state.device.cadAvlSharingId
export const getAppState = (state: Object): string => state.device.appState
export const getGoogleMapsInstalled = (state: Object): boolean => state.device.googleMapsInstalled
export const getKeepScreenActive = (state: Object): boolean => state.device.keepScreenActive
export const getIsWindows: (state: Object) => boolean = createSelector(
  getDeviceSystemVersion,
  systemVersion => systemVersion && systemVersion.toLowerCase().includes('win')
)
