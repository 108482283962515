/* @flow */
import { createSelector } from 'reselect'
import { List } from 'immutable'
import SearchResult from '../records/SearchResult'

const getLocalDataResults = (state: Object): List<SearchResult> => state.searchBar.localDataResults
const getSearchResults = (state: Object): List<SearchResult> => state.searchBar.searchResults
const getLatLngResult = (state: Object): SearchResult => state.searchBar.latLngResult
export const getSearchBarActive = (state: Object): boolean => state.searchBar.active
export const getCurrentSearchBarResult = (state: Object): string => state.searchBar.currentSearchResult
export const getSearchText = (state: Object): string => state.searchBar.searchText

const getRequestingGeocodedResults = (state: Object): boolean => state.searchBar.requestingGeocodedResults
const getRequestingAddressPointResults = (state: Object): boolean => state.searchBar.requestingAddressPointResults
export const getSearchBarRequestingResults = createSelector(
  [getRequestingGeocodedResults, getRequestingAddressPointResults],
  (requestingGeocodedResults: boolean, requestingAddressPointResults: boolean): boolean => {
    return requestingGeocodedResults || requestingAddressPointResults
  }
)

export const getSearchBarResults = createSelector(
  [getSearchResults, getLocalDataResults, getLatLngResult],
  (searchResults, localDataResults, latLngResult): List<SearchResult> => {
    if (latLngResult) {
      return new List([latLngResult])
    }
    return localDataResults && localDataResults.concat(searchResults)
  }
)

export const getSearchBarResultsArray: (state: Object) => Array<SearchResult> = createSelector(
  [getSearchBarResults],
  list => list.toArray()
)
