/* @flow */
import { makeActionCreator } from './utils'
import * as types from '../constants/ActionTypes'

export const zoomToLocationAction = makeActionCreator(types.ZOOM_TO_LOCATION, 'lat', 'lng', 'zoom')
export const didZoomToLocation = makeActionCreator(types.DID_ZOOM_TO_LOCATION)
export const zoomToBounds = makeActionCreator(types.ZOOM_TO_BOUNDS, 'southWest', 'northEast')
export const didZoomToBounds = makeActionCreator(types.DID_ZOOM_TO_BOUNDS)
export const userPannedMap = makeActionCreator(types.USER_PANNED_MAP)
export const didChangeBounds = makeActionCreator(types.DID_CHANGE_BOUNDS, 'southWest', 'northEast')
export const shouldTrackCurrentBounds = makeActionCreator(types.SHOULD_TRACK_CURRENT_BOUNDS, 'shouldTrack')
export const setCurrentLocation = makeActionCreator(types.SET_CURRENT_LOCATION, 'lat', 'lng')
export const locationProviderChanged = makeActionCreator(types.LOCATION_PROVIDER_CHANGED, 'provider')
export const toggleTrackUserLocation = makeActionCreator(types.TOGGLE_TRACK_USER_LOCATION)

export const setToggleMapLayer = makeActionCreator(types.SET_TOGGLE_MAP_LAYER, 'layerName', 'isVisible')
export const setToggleAllMapLayers = makeActionCreator(types.SET_TOGGLE_ALL_MAP_LAYERS, 'gisDataSources', 'isVisible')
export const refreshMap = makeActionCreator(types.REFRESH_MAP, 'visibleLayerNames')

export const setCurrentBounds = makeActionCreator(types.SET_CURRENT_BOUNDS, 'currentBoundsArray')
export const setRegion = makeActionCreator(types.SET_REGION, 'region')

export const setTargetLocation = makeActionCreator(types.SET_TARGET_LOCATION, 'lat', 'lng', 'displayText')
export const setBasemapType = makeActionCreator(types.SET_BASEMAP_TYPE, 'mapType')
export const getMapboxStylesSuccess = makeActionCreator(types.GET_MAPBOX_STYLES_SUCCESS, 'stylesObject')
export const setViewport = makeActionCreator(types.SET_VIEWPORT, 'viewport')
export const setViewportDimensions = makeActionCreator(types.SET_VIEWPORT_DIMENSIONS, 'dimensions')
export const toggleMapComponent = makeActionCreator(types.TOGGLE_MAP_COMPONENT)
export const zoomIn = makeActionCreator(types.ZOOM_IN)
export const zoomOut = makeActionCreator(types.ZOOM_OUT)
export const setBearing = makeActionCreator(types.SET_BEARING, 'bearing')
export const setPitch = makeActionCreator(types.SET_PITCH, 'pitch')
export const centerMap = makeActionCreator(types.CENTER_MAP)
export const userClickedMap = makeActionCreator(types.USER_CLICKED_MAP)
export const toggleCurrentLocationSimulator = makeActionCreator(types.TOGGLE_CURRENT_LOCATION_SIMULATOR)
export const increaseFontSize = makeActionCreator(types.INCREASE_FONT_SIZE)
export const decreaseFontSize = makeActionCreator(types.DECREASE_FONT_SIZE)

export const mapStyleLoaded = makeActionCreator(types.MAP_STYLE_LOADED)
export const showDynamicLayers = makeActionCreator(types.SHOW_DYNAMIC_LAYERS)
