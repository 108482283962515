import axios from 'axios'
import { delay } from 'redux-saga'
import { all, call, cancel, fork, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import firebase from '../firebase'
import { REHYDRATE } from 'redux-persist/constants'
import * as types from '../constants/ActionTypes'
import { createAlert } from '../actions/AlertActions'
import * as authActions from '../actions/AuthActions'
import * as modalActions from '../actions/ModalActions'
import { setToastMessage } from '../actions/ToastActions'
import { refUsers, refAgencies, refCommonConfig, refAgencyConfigs, refGisDataSources } from '../firebase'
import * as selectors from '../selectors'
import * as constant from '../constants'
import { client, GET_AGENCY_RESOURCES } from '../apollo'
import Sentry from '../utils/sentry' // eslint-disable-line import/no-unresolved

let _hasRunHandleAuthenticated = false
const MAX_SIGN_IN_RETRIES = 2
let signInRetries = 0

function* handleSignInRequest({ email, password }) {
  try {
    if (!email | !password) {
      throw new Error('You must enter an email address and a password in order to sign in.')
    }
    // successful authWithPassword will call AUTHENTICATED, hooked up in containers/main
    // set this up before requesting sign in
    if (_hasRunHandleAuthenticated) {
      yield fork(watchIdTokenChangedOnSignIn)
    }
    const authData = yield call(() => firebase.auth().signInWithEmailAndPassword(email, password))
    yield put(authActions.signInSuccess(authData))
    // if we are signing into the demo agency, the license agreement will be opened either by handleSignOut
    // or handleAppStart in lifecycle.js if we are starting the app for the first time
    if (email !== constant.DEMO_EMAIL) {
      yield put(authActions.setLicenseState(false))
      yield put(modalActions.openLicenseAgreementModal(false))
    }
  } catch (error) {
    if (error.code === 'auth/network-request-failed' && signInRetries < MAX_SIGN_IN_RETRIES) {
      // TODO: !!!!!! find a better way to handle the network timeout issue on some Android devices
      yield call(delay, 100)
      yield put(authActions.signInRequest(email, password))
      signInRetries++
    } else if (error.code === 'auth/network-request-failed' && email === constant.DEMO_EMAIL) {
      // If we launch the app for the first time while offline, we want to wait until we
      // get connected in order to sign into the demo agency
      yield take(types.GAIN_NETWORK_CONNECTION)
      signInRetries = 0
      yield put(authActions.signInRequest(email, password))
    } else {
      yield put(authActions.signInFailure(error))
      yield put(createAlert('invalidSignInAlert', ['OK'], 'Sign in failed', error.message))
      const errorsCodesToNotLog = ['auth/user-not-found', 'iv/missing-email-or-password']
      if (errorsCodesToNotLog.includes(error.code)) {
        return
      }
      Sentry.captureException(error)
    }
  }
}

function* watchIdTokenChangedOnSignIn() {
  const action = yield take(types.ID_TOKEN_CHANGED)
  yield call(handleAuthenticated, action)
}

function* watchLostNetworkConnection() {
  yield take(types.LOST_NETWORK_CONNECTION)
  yield put(authActions.loadingApp(false))
}

function* handleIdTokenChanged({ authData, token }) {
  // Set auth header to be used for all axios calls
  axios.defaults.headers.common.Authorization = `Bearer ${token}`

  // If no token, we're most likely offline with a stale token. When we reconnect, idTokenChanged will happen again.
  if (token && !_hasRunHandleAuthenticated) {
    yield put(authActions.authenticated(authData))
  }
}

const getFirebaseVal = ref => ref.once('value').then(snap => snap.val())
function* handleAuthenticated({ authData }) {
  signInRetries = 0

  const isNetworkConnected = yield select(selectors.getIsConnected)
  if (!isNetworkConnected) {
    yield take(types.GAIN_NETWORK_CONNECTION)
  }

  yield put(setToastMessage('Loading configuration...', 3000))
  const watchLostNetworkConnectionTask = yield fork(watchLostNetworkConnection)
  try {
    const { user } = yield race({
      user: call(getFirebaseVal, refUsers.child(authData.uid)),
      timeout: call(delay, 12000),
    })
    if (!user) {
      throw new Error('Auth timed out')
    }
    user.uid = authData.uid
    const { roles, email } = user
    const isDemoUser = email === constant.DEMO_EMAIL
    if (!isDemoUser && roles && !roles.editor) {
      throw new Error('notEditor')
    }

    // need user before calling these
    const [agency, agencyConfig, commonConfig, gisDataSources] = yield all([
      call(getFirebaseVal, refAgencies.child(user.agencyName)),
      call(getFirebaseVal, refAgencyConfigs.child(user.agencyName)),
      call(getFirebaseVal, refCommonConfig),
      call(getFirebaseVal, refGisDataSources.child(user.agencyName)),
    ])

    if (!agency) {
      const title = 'Agency entry is missing'
      const msg =
        'Your user account is missing an agency entry. IncidentView Editor cannot run without it. ' +
        'Sign in again once this is fixed.'
      yield put(createAlert('agencyConfigMissingAlert', ['OK'], title, msg))
      throw new Error(msg)
    }

    if (!agencyConfig) {
      const title = 'Agency config is missing'
      const msg =
        'Your agency account is missing an agency config. IncidentView Editor cannot run without it. ' +
        'Sign in again once this is fixed.'
      yield put(createAlert('agencyConfigMissingAlert', ['OK'], title, msg))
      throw new Error(msg)
    }

    if (!gisDataSources) {
      const title = 'GIS data sources are missing'
      const msg =
        "Your account is missing GIS data source information. You won't " +
        'be able to see your custom data until this is fixed.'
      yield put(createAlert('gisDataSourcesMissingAlert', ['OK'], title, msg))
    }

    const res = yield client.query({
      query: GET_AGENCY_RESOURCES,
      fetchPolicy: 'no-cache',
    })
    const agencyBoundary = res.data.agency_boundary[0]
    if (!agencyBoundary && !user.agencyName === constant.DEMO_AGENCY) {
      const title = 'Agency boundary is missing'
      const msg =
        'Your agency account is missing an editing boundary. IncidentView Editor cannot run without it. ' +
        'Sign in again once this is fixed.'
      yield put(createAlert('agencyBoundaryMissingAlert', ['OK'], title, msg))
      throw new Error(msg)
    }
    const readableFeatureSets = res.data.feature_set.reduce((acc, fs) => {
      acc[fs.guid] = fs
      return acc
    }, {})

    // We do not want gis data sources that do not have a style defined to be present in the editor.
    if (gisDataSources) {
      Object.keys(gisDataSources).forEach(key => {
        if (!gisDataSources[key].hasOwnProperty('mapboxStyleLayers')) {
          console.warn(key, 'does not have a style defined! Removing from editor...')
          delete gisDataSources[key]
        }
      })
    }

    // wait until APP_START action has been fired
    // use systemVersion field to determine whether or not it has been fired
    let stateSystemVersion = yield select(selectors.getDeviceSystemVersion)
    if (!stateSystemVersion) {
      yield take(types.APP_START)
      stateSystemVersion = yield select(selectors.getDeviceSystemVersion)
    }
    const stateHardwareUid = yield select(selectors.getHardwareUid)

    Sentry.setUser({ ...user, email: user.email, id: user.uid, hardwareUid: stateHardwareUid })
    yield put(
      authActions.registrationFlowSuccess({
        user,
        agency,
        commonConfig,
        agencyConfig,
        gisDataSources,
        featureSets: readableFeatureSets,
        agencyBoundary: agencyBoundary && agencyBoundary.geom,
      })
    )
    _hasRunHandleAuthenticated = true
    yield cancel(watchLostNetworkConnectionTask)
  } catch (error) {
    Sentry.captureException(error)
    if (error.message === 'notEditor') {
      _hasRunHandleAuthenticated = true
      yield put(
        createAlert(
          'userNotEditor',
          ['OK'],
          'This user does not have editor permissions',
          'Please contact your administrator.'
        )
      )
    }
    const wasSignedIn = yield select(selectors.getIsSignedIn)
    yield put(authActions.registrationFlowFailure(error))
    if (error.message.includes('Your requested role is not in allowed roles')) {
      yield put(
        createAlert(
          'roleNotInAllowedRoles',
          ['OK'],
          `This user's requested role is not in allowed roles`,
          'Your token may need to be regenerated. Please contact your administrator.'
        )
      )
    }
    if (!wasSignedIn) {
      firebase.auth().signOut() // will call UNAUTHENTICATED, hooked up in containers/main
      yield put(setToastMessage('Authentication configuration failed. Please sign in again.', 4000))
    } else {
      yield put(setToastMessage('Failed checking server for configuration changes. Will try on next launch.', 5000))
    }
    return
  }
}

function* handleSignOutRequest() {
  const isNetworkConnected = yield select(selectors.getIsConnected)
  if (!isNetworkConnected) {
    yield put(createAlert('signOutReject', ['OK'], 'Sign out failed', 'An internet connection is required to sign out'))
  } else {
    yield put(authActions.signOut())
  }
}

function* handleSignOut() {
  firebase.auth().signOut() // will call UNAUTHENTICATED, hooked up in containers/main
  yield put(authActions.setLicenseState(false))
}

function* handleUnauthenticated() {
  yield handleSignInRequest({ email: constant.DEMO_EMAIL, password: constant.DEMO_PASSWORD })
}

function* watchLicenseAccepted() {
  while (true) {
    // first, this should only be triggered when the license agreement is shown
    const prereq = action =>
      (action.type === types.SET_LICENSE_STATE && action.licenseState === false) ||
      (action.type === REHYDRATE && !action.payload) ||
      (action.payload && action.payload.auth && !action.payload.auth.licenseAccepted)
    yield take(prereq)
    // we need both the LICENSE_ACCEPTED and REGISTRATION_FLOW_SUCCESS in order for this action to be triggered
    yield all([take(types.LICENSE_ACCEPTED), take(types.REGISTRATION_FLOW_SUCCESS)])
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(types.ID_TOKEN_CHANGED, handleIdTokenChanged),
    takeLatest(action => action.type === types.AUTHENTICATED && !_hasRunHandleAuthenticated, handleAuthenticated),
    takeEvery(types.UNAUTHENTICATED, handleUnauthenticated),
    fork(watchLicenseAccepted),
    takeEvery(types.SIGN_IN_REQUEST, handleSignInRequest),
    takeEvery(types.SIGN_OUT_REQUEST, handleSignOutRequest),
    takeEvery(types.SIGN_OUT, handleSignOut),
  ])
}
