import { PING_URL } from '../constants'
import platform from '../utils/platform'

export async function checkConnection() {
  let params = { method: 'GET' }
  if (platform.web) {
    params.mode = 'no-cors'
  }
  const response = await fetch(new Request(PING_URL, params))
  return response.ok || response.type === 'opaque'
}
