/* @flow */
import React from 'react'
import { FlatList, Image, Platform, StyleSheet, Text, TouchableHighlight, View } from 'react-native-web'
import { featureSelected, openDetailWindow } from '../../common/actions'
import Modal from './SimpleModal'
import { connect } from 'react-redux'
import { NAV_BUTTON_COLOR_HOVER, PLATFORM_COLOR } from '../styles'
import { getGisDataSources } from '../../common/selectors/gisDataSources'
import * as Immutable from 'immutable'

const styles = StyleSheet.create({
  rowSelected: {
    backgroundColor: PLATFORM_COLOR,
  },
  separator: {
    height: 1,
    backgroundColor: '#666666',
  },
  menuImage: {
    width: 24,
    height: 24,
    marginTop: Platform.OS === 'ios' ? 5 : 6,
    marginRight: 5,
  },
  menuButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    color: 'black',
    flex: 1,
    fontSize: 20,
    paddingLeft: 24,
  },
})

const mapDispatchToProps = {
  openDetailWindow,
  featureSelected,
}

const mapStateToProps = state => ({
  gisDataSources: getGisDataSources(state),
})

type Props = {
  closeFeatureSelectModal: Function,
  featureSelected: Function,
  queriedFeatures: Array<Object>,
  openDetailWindow: Function,
  closeFeatureSelectModal: Function,
  gisDataSources: Immutable.Map<any, any>,
  interpolatedStyles: any,
}

class QueryFeatureSelectModal extends React.PureComponent<Props> {
  renderItem({ item, separators }) {
    const feature_ = item
    const handleFeatureClicked = () => {
      this.props.featureSelected({ feature: feature_, dataSourceName: feature_.sourceName })
      this.props.closeFeatureSelectModal()
    }
    const sourceName = feature_.sourceName

    const determineRowText = featureName => {
      let layerText
      const featureType = feature_.properties.type
      if (this.props.gisDataSources && featureName && this.props.gisDataSources.has(featureName)) {
        layerText = `${featureName.charAt(0).toUpperCase() + sourceName.slice(1)}`
        const displayField = this.props.gisDataSources.get(featureName).get('displayField')
        if (displayField) {
          const featureProperties = feature_.properties
          const displayValue = featureProperties[displayField]
          if (displayValue) {
            layerText = layerText.concat(`: ${displayValue}`)
            return layerText
          } else if (featureType) {
            layerText = layerText.concat(`: ${featureType}`)
            return layerText
          }
        } else if (featureType) {
          layerText = layerText.concat(`: ${featureType}`)
          return layerText
        }
      } else {
        // displaying layers that are in the old system (do not have sourceName)
        layerText = 'In old system'
        if (featureType) {
          layerText = layerText.concat(`: ${featureType}`)
          return layerText
        } else {
          const betterThanNothingText =
            Object.keys(feature_.properties)[0] === 'guid'
              ? Object.values(feature_.properties)[1]
              : Object.values(feature_.properties)[0]
          layerText = layerText.concat(`: ${betterThanNothingText}`)
          return layerText
        }
      }
      return layerText
    }
    const rowText = determineRowText(sourceName)
    return (
      <TouchableHighlight
        onPress={handleFeatureClicked}
        onShowUnderlay={separators.highlight}
        onHideUnderlay={separators.unhighlight}
        underlayColor={NAV_BUTTON_COLOR_HOVER}
      >
        <View style={styles.menuButtonContainer}>
          <Image
            source={{
              uri: `https://firebasestorage.googleapis.com/v0/b/incidentview.appspot.com/o/layerIcons%2F${sourceName}.svg?alt=media`,
            }}
            style={styles.menuImage}
            resizeMode="contain"
          />
          <Text style={styles.text} numberOfLines={1}>
            {rowText}
          </Text>
        </View>
      </TouchableHighlight>
    )
  }

  render() {
    return (
      <Modal
        interpolatedStyles={this.props.interpolatedStyles}
        title="Select Feature"
        closeModal={this.props.closeFeatureSelectModal}
      >
        <FlatList
          ItemSeparatorComponent={({ highlighted }) => (
            <View style={[styles.separator, highlighted && { marginLeft: 0 }]} />
          )}
          data={this.props.queriedFeatures}
          renderItem={item => this.renderItem(item)}
          keyExtractor={feature => feature.properties.guid}
        />
      </Modal>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueryFeatureSelectModal)
