/* @flow */
import * as Immutable from 'immutable'
import * as types from '../constants/ActionTypes'
import { createReducerFromHandlers } from './utils'
import { REHYDRATE } from 'redux-persist/constants'

export const DeviceState = Immutable.Record({
  // eslint-disable-line new-cap
  hardwareUid: null,
  displayName: null,
  systemVersion: null,
  reactVersion: null,
  avlColor: 'FFFF8800',
  cadAvlSharingId: null,
  appState: 'active',
  googleMapsInstalled: false,
  keepScreenActive: true,
})

export const initialState = new DeviceState()

const handlers = {
  [REHYDRATE]: (state, action) => {
    const previousState = action.payload.device
    let newState = state
    if (previousState && previousState.avlColor) {
      newState = newState.set('avlColor', previousState.avlColor)
    }
    if (previousState && previousState.displayName) {
      newState = newState.set('displayName', previousState.displayName)
    }
    if (previousState && previousState.keepScreenActive) {
      newState = newState.set('keepScreenActive', previousState.keepScreenActive)
    }
    return newState
  },

  [types.APP_START]: (state, { hardwareUid, systemVersion, reactVersion }) => {
    return state.withMutations(_state => {
      _state.set('hardwareUid', hardwareUid)
      _state.set('systemVersion', systemVersion)
      if (reactVersion) {
        _state.set('reactVersion', reactVersion)
      }
      // default displayName to hardwareUid if not set already
      if (!_state.get('displayName')) {
        _state.set('displayName', hardwareUid)
      }
    })
  },
  [types.SET_DEVICE_DISPLAY_NAME_FROM_USER]: (state, action) => state.set('displayName', action.deviceName),
  [types.SET_GOOGLE_MAPS_INSTALLED]: (state, action) => state.set('googleMapsInstalled', action.googleMapsInstalled),
  [types.APP_STATE_CHANGED]: (state, action) => state.set('appState', action.appState),
  [types.TOGGLE_KEEP_SCREEN_ACTIVE]: state => state.set('keepScreenActive', !state.get('keepScreenActive')),
  [types.UPDATE_DEVICE]: (state, action) => {
    if (!action.device) {
      return state
    }

    // merge in each attribute only if it exists as a key on DeviceState
    const stateKeys = Object.keys(state.toJS())
    return state.withMutations(_state => {
      Object.entries(action.device).forEach(([key, val]) => {
        if (stateKeys.includes(key)) {
          _state.set(key, val)
        }
      })
    })
  },
}

export default createReducerFromHandlers(initialState, handlers)

// selectors moved to ../selectors dir
