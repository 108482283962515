/* @flow */
import { makeActionCreator } from './utils'
import * as types from '../constants/ActionTypes'

type AppStartPayload = {
  hardwareUid: string,
  systemVersion: string,
  reactVersion: ?string,
  isConnected: boolean,
  user: any,
}
export const appStart = (payload: AppStartPayload) => ({
  type: types.APP_START,
  ...payload,
})

export const appExit = makeActionCreator(types.APP_EXIT)
export const licenseAccepted = makeActionCreator(types.LICENSE_ACCEPTED)
export const appStateChanged = makeActionCreator(types.APP_STATE_CHANGED, 'appState')
export const androidBackPressed = makeActionCreator(types.ANDROID_BACK_PRESSED)
