import * as Immutable from 'immutable'
import LatLng from './LatLng'

const SearchResultRecord = Immutable.Record({
  displayText: null,
  coordinates: new LatLng(),
  relevance: null, // score from Mapbox
  searchBarText: null,
  type: null,
})

export default SearchResultRecord

export const createSearchResultRecordFromObj = obj => {
  let coordinates
  if (obj.coordinates) {
    coordinates = obj.coordinates
  } else if (obj.lat && obj.lng) {
    coordinates = new LatLng({ lat: obj.lat, lng: obj.lng })
  } else {
    throw new Error('SearchResult must have lat lng coordinates defined')
  }
  return new SearchResultRecord({ ...obj, coordinates })
}
