/* @flow */
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as modalNames from '../../common/constants/ModalNames'
import * as modalActions from '../../common/actions/ModalActions'
import { appExit, licenseAccepted } from '../../common/actions/LifecycleActions'
import { signInRequest, signOutRequest } from '../../common/actions/AuthActions'
import { coordinateSubmit } from '../../common/actions/FeatureActions'
import AccountModal from '../components/AccountModal'
import SignInModal from '../components/SignInModal'
import AboutModal from '../components/AboutModal'
import DataModal from '../components/DataModal'
import LayerConfig from '../components/LayerConfig'
import QueryFeatureSelectModal from '../components/QueryFeatureSelectModal'
import LicenseAgreementModal from '../components/LicenseAgreementModal'
import InsertCoordinateModal from '../components/ExportDataModal'
import AddPointLocationModal from '../components/InsertCoordinateModal'
import UsersGuideModal from '../components/UsersGuideModal'
import SettingModal from '../components/SettingModal'
import FileViewer from '../components/FileViewer'
import * as Immutable from 'immutable'
import { TransitionMotion, spring } from 'react-motion'
import { getIsDemoMode } from '../../common/selectors/auth'

const mapStateToProps = state => {
  return {
    modalStack: state.modal,
    signedIn: !getIsDemoMode(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      { ...modalActions, signInRequest, coordinateSubmit, signOutRequest, licenseAccepted, appExit },
      dispatch
    ),
  }
}

const springConfig = {
  stiffness: 230,
  damping: 26,
}

const willEnter = () => ({
  marginTop: -20,
  opacity: 0,
})

const willLeave = () => ({
  marginTop: spring(-20, springConfig),
  opacity: spring(0, springConfig),
})

export const getStyle = {
  marginTop: spring(2, springConfig),
  opacity: spring(1, springConfig),
}

type Props = {
  modalStack: Immutable.List<Immutable.Record<{ modalComponent: any, modalName: string | null, modalProps: any }>>,
  closeAccountSignInModal: ?Function,
  closeAboutModal: ?Function,
  closeExportDataModal: ?Function,
  closeLayerConfig: ?Function,
  closeAddEditPointLocation: ?Function,
  closeSettingModal: ?Function,
  closeLicenseAgreementModal: ?Function,
  closeUsersGuideModal: ?Function,
  closeDataModal: ?Function,
  signedIn: ?boolean,
  signInRequest: ?Function,
  signOutRequest: ?Function,
}

const ModalBase = (props: Props) => {
  let noModals = false
  if (!props.modalStack.size) {
    noModals = true
  }

  const topModal = noModals ? { modalName: null } : props.modalStack.last()

  return (
    <TransitionMotion
      styles={[
        {
          key: props.modalStack.size ? topModal.modalName : 'no-modal',
          style: getStyle,
          data: { ...props, topModal },
        },
      ]}
      willEnter={willEnter}
      willLeave={willLeave}
    >
      {interpolated => (
        <div>
          {interpolated.map(({ key, style, data }: { key: string, style: any, data: any }) => {
            const modalProps = data.topModal.modalProps
            let SpecificModal = topModal.modalComponent
            if (SpecificModal) {
              return <SpecificModal key={key} />
            }

            // else:
            switch (data.topModal.modalName) {
              case modalNames.MODAL_ACCOUNT_OR_SIGN_IN:
                return data.signedIn ? (
                  <AccountModal
                    key={key}
                    interpolatedStyles={style}
                    close={data.closeAccountSignInModal}
                    submitSignOut={data.signOutRequest}
                  />
                ) : (
                  <SignInModal
                    key={key}
                    interpolatedStyles={style}
                    closeSignIn={data.closeAccountSignInModal}
                    submitSignIn={data.signInRequest}
                  />
                )
              case modalNames.MODAL_ABOUT:
                return <AboutModal key={key} interpolatedStyles={style} closeAbout={data.closeAboutModal} />
              case modalNames.MODAL_SETTING:
                return <SettingModal key={key} interpolatedStyles={style} closeSetting={data.closeSettingModal} />
              case modalNames.MODAL_LICENSE_AGREEMENT:
                return (
                  <LicenseAgreementModal
                    key={key}
                    interpolatedStyles={style}
                    closeLicenseAgreement={data.closeLicenseAgreementModal}
                    hasCloseButton={modalProps.initialSignIn}
                    declineLicense={data.appExit}
                    acceptLicense={data.licenseAccepted}
                  />
                )
              case modalNames.MODAL_EXPORT_DATA:
                return (
                  <InsertCoordinateModal
                    key={key}
                    interpolatedStyles={style}
                    closeExportData={data.closeExportDataModal}
                  />
                )
              case modalNames.LAYER_CONFIG:
                return <LayerConfig key={key} interpolatedStyles={style} closeLayerConfig={data.closeLayerConfig} />
              case modalNames.MODAL_ADD_POINT_LOCATION:
                return (
                  <AddPointLocationModal
                    key={key}
                    interpolatedStyles={style}
                    closeAddEditPointLocation={data.closeAddEditPointLocationModal}
                    submitCoordinates={data.coordinateSubmit}
                  />
                )
              case modalNames.MODAL_FILE_VIEWER:
                return (
                  <FileViewer
                    key={key}
                    interpolatedStyles={style}
                    isImage={modalProps.isImage}
                    dataUrl={modalProps.dataUrl}
                    closeFileViewer={data.closeFileViewerModal}
                  />
                )
              case modalNames.MODAL_USERS_GUIDE:
                return (
                  <UsersGuideModal key={key} interpolatedStyles={style} closeUsersGuide={data.closeUsersGuideModal} />
                )
              case modalNames.MODAL_DATA:
                return <DataModal key={key} interpolatedStyles={style} closeData={data.closeDataModal} />
              case modalNames.MODAL_FEATURE_SELECT:
                return (
                  <QueryFeatureSelectModal
                    key={key}
                    interpolatedStyles={style}
                    closeFeatureSelectModal={data.closeFeatureSelectModal}
                    queriedFeatures={modalProps.queriedFeatures}
                  />
                )
              default:
                return null
            }
          })}
        </div>
      )}
    </TransitionMotion>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalBase)
