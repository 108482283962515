/* @flow */
import React from 'react'
import { version, author } from '../../../package.json'
import { connect } from 'react-redux'
import Modal from './SimpleModal'

const mapStateToProps = state => {
  return {
    hardwareUid: state.device.hardwareUid, // remove
  }
}

type Props = {
  closeAbout: Function,
  interpolatedStyles: Object,
}
const mapboxAttributionControl = (
  <div className="mapbox-attribution-container">
    <a href="https://www.mapbox.com/about/maps/" target="_blank" rel="noopener noreferrer">
      © Mapbox |{' '}
    </a>
    <a href="http://www.openstreetmap.org/copyright" target="_blank" rel="noopener noreferrer">
      © OpenStreetMap |{' '}
    </a>
    <a href="https://www.mapbox.com/map-feedback/" target="_blank" rel="noopener noreferrer">
      <strong>Improve this map</strong>
    </a>
  </div>
) // TODO: Remove BETA on release
class AboutModal extends React.Component<Props> {
  render = () => {
    const { closeAbout, interpolatedStyles } = this.props
    let currentTime = new Date()
    return (
      <Modal interpolatedStyles={interpolatedStyles} closeModal={closeAbout} title="IncidentView Editor - BETA">
        <img alt="IV Editor logo" width="100%" src={require('../../assets/images/IV_Editor_logo_long.png')} />
        <div className="row">
          <div className="col-md-6">
            <p />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p>Version: </p>
          </div>
          <div className="col-md-6">
            <p className="text-right">{`${version}`}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <p>Map: </p>
          </div>
          <div className="col-md-8">
            <div className="text-right">{mapboxAttributionControl}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p>Contact:</p>
          </div>
          <div className="col-md-6">
            <p className="text-right">{`${author.address}`}</p>
          </div>
        </div>
        <p className="text-right">{`${author.phone}`}</p>
        <p className="text-right">{`${author.contactEmail}`}</p>
        <p>{`\u00A9 ${author.name} ${currentTime.getFullYear()}`}</p>
      </Modal>
    )
  }
}

export default connect(mapStateToProps)(AboutModal)
