import { combineReducers } from 'redux'
import alerts from './alerts'
import searchBar from './searchBar'
import auth from './auth'
import connectivity from './connectivity'
import device from './device'
import download from './download'
import featureGuids from './featureGuids'
import featureEdits from './featureEdits'
import firebase from './firebase'
import gisDataSources from './gisDataSources'
import map from './map'
import modal from './modal'
import offlineSyncronizer from './offlineSyncronizer'
import toast from './toast'
import mapViewport from './mapViewport'
import platform from '../utils/platform' // eslint-disable-line import/no-unresolved
import editForm from './editForm'
import editor from './editor'

// Redux formWidgets
import { reducer as formReducer } from 'redux-form/immutable'

export const reducers = {
  alerts,
  auth,
  connectivity,
  device,
  download,
  editForm,
  editor,
  featureGuids,
  featureEdits,
  firebase,
  gisDataSources,
  map,
  modal,
  offlineSyncronizer,
  toast,
  mapViewport,
  searchBar,
  form: formReducer,
}

const reducersForWeb = Object.entries(reducers).reduce((acc, [key, reducer]) => {
  acc[key] = reducer
  if (key !== 'mapViewport') {
    //acc[key] = ignoreActions(reducer, ['SET_VIEWPORT']) // Don't ignore now. We are listening for this in reducer/map now.
  } else {
    acc[key] = reducer
  }
  return acc
}, {})

// ignore mapViewport as it's specific for react-map-gl web component
const reducersForReactNative = { ...reducers }
delete reducersForReactNative.mapViewport

const rootReducer = combineReducers(platform.native ? reducersForReactNative : reducersForWeb)

export default rootReducer
