import { delay } from 'redux-saga'
import { all, call, fork, put, race, select, take, takeLatest } from 'redux-saga/effects'
// import { NetInfo } from 'react-native'
import * as connectivityActions from '../actions/ConnectivityActions'
import { setToastMessage } from '../actions/ToastActions'
import { checkConnection } from '../utils/connectivity'
import { getIsConnected } from '../selectors/connectivity'
import { CONNECTIVITY_CHECK_INTERVAL, CONNECTIVITY_CHECK_TIMEOUT, TOAST_DISPLAY_INTERVAL_SHORT } from '../constants'
import * as types from '../constants/ActionTypes'
import sendConnectedToMain from '../utils/sendConnectedToMain' // eslint-disable-line import/no-unresolved

export function* testConnection() {
  const previousIsConnected = yield select(getIsConnected)
  let isConnected = false
  // For now we are just using a fetch command as we expand we will want
  // to add the platform specific implementations for checking connectivity
  try {
    // Do a test fetch and give it four seconds to complete
    // otherwise we time out and mark connection lost
    const { raceIsConnected } = yield race({
      raceIsConnected: call(checkConnection),
      raceTimedOut: call(delay, CONNECTIVITY_CHECK_TIMEOUT),
    })
    // If the fetch times out then we are not connected
    if (raceIsConnected === undefined) {
      isConnected = false
      throw Error('Active connection test timed out')
    }
    isConnected = raceIsConnected
  } catch (error) {
    isConnected = false
  }
  if (previousIsConnected !== isConnected) {
    if (isConnected) {
      yield put(connectivityActions.gainNetworkConnection())
      // don't put a toast if the app has just started and previousIsConnected is null
      if (previousIsConnected !== null) {
        yield put(setToastMessage('Gained internet connection', TOAST_DISPLAY_INTERVAL_SHORT))
      }
    } else {
      yield put(connectivityActions.lostNetworkConnection())
      if (previousIsConnected !== null) {
        yield put(setToastMessage('Lost internet connection', TOAST_DISPLAY_INTERVAL_SHORT))
      }
    }
  }
}

// Actively check the connection status in a loop so we can determine when connection is lost
export function* checkConnectionLoop() {
  while (true) {
    yield call(testConnection)
    yield call(delay, CONNECTIVITY_CHECK_INTERVAL)
  }
}

/**
 * The electron main process needs to know of the initial time we get connected
 */
function* watchFirstGainNetworkConnection() {
  yield take(types.GAIN_NETWORK_CONNECTION)
  yield call(sendConnectedToMain)
}

export default function* networkmonitorSaga() {
  yield all([
    fork(checkConnectionLoop),
    takeLatest(types.SET_CONNECTION_TYPE, testConnection),
    fork(watchFirstGainNetworkConnection),
  ])
}
