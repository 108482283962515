/* @flow */
import * as Immutable from 'immutable'
import * as types from '../constants/ActionTypes'
import { MODAL_ACCOUNT_OR_SIGN_IN, MODAL_LICENSE_AGREEMENT } from '../constants/ModalNames'
import { createReducerFromHandlers } from './utils'
import { DEMO_EMAIL } from '../constants'

const ModalRecord = Immutable.Record({
  // eslint-disable-line new-cap
  modalComponent: null,
  modalName: null,
  modalProps: {},
})

export const ModalState = Immutable.List([])

const initialState = ModalState

const closeSignInModal = state => state.filter(m => m.modalName !== MODAL_ACCOUNT_OR_SIGN_IN)
const openLicenseAgreementModal = (state, initialSignIn) => {
  return state.push(new ModalRecord({ modalName: MODAL_LICENSE_AGREEMENT, modalProps: { initialSignIn } }))
}

const closeLicenseAgreementModal = state => state.filter(m => m.modalName !== MODAL_LICENSE_AGREEMENT)

const pushModalToStack = (state, action) => {
  return state.push(new ModalRecord({ modalName: action.modalName, modalProps: action.modalProps }))
}

const popModal = (state, action) => {
  return state.pop()
}

const pushModalComponent = (state, action) => {
  return state.push(new ModalRecord({ modalComponent: action.modalComponent, modalProps: action.modalProps }))
}

const removeModalFromStack = (state, action) => state.filter(m => m.modalName !== action.modalName)

const handlers = {
  [types.APP_START]: (state, action) => {
    if (!action.licenseAccepted && action.user.email !== DEMO_EMAIL) {
      return openLicenseAgreementModal(state, false)
    }
    return state
  },
  [types.SIGN_IN_SUCCESS]: closeSignInModal,
  [types.SIGN_OUT]: closeSignInModal,
  [types.LICENSE_ACCEPTED]: closeLicenseAgreementModal,
  [types.OPEN_MODAL]: pushModalToStack,
  [types.CLOSE_MODAL]: removeModalFromStack,

  [types.OPEN_MODAL_COMPONENT]: pushModalComponent,
  [types.POP_MODAL]: popModal,
}

export default createReducerFromHandlers(initialState, handlers)

// selectors moved to ../selectors dir
