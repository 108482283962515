/* @flow */
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { TransitionMotion, spring } from 'react-motion'
import { dismissAlert } from '../../common/actions/AlertActions'
import { getTopAlert } from '../../common/reducers/alerts'
import Alert from '../components/Alert'

const mapStateToProps = state => ({
  alert: getTopAlert(state),
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ dismissAlert }, dispatch),
})

const springConfig = {
  stiffness: 230,
}

const willEnter = () => ({
  opacity: 0,
})

const willLeave = () => ({
  opacity: spring(0, springConfig),
})

const getStyle = {
  opacity: spring(1, springConfig),
}

type Props = {
  alert: ?Object,
}

const AlertBase = (props: Props) => {
  const { alert } = props
  return (
    <TransitionMotion
      styles={[
        {
          key: alert ? alert.alertName : 'no-alert',
          style: getStyle,
          data: props,
        },
      ]}
      willEnter={willEnter}
      willLeave={willLeave}
    >
      {interpolated => (
        <div>
          {interpolated.map(({ key, style, data }) => {
            return data.alert ? (
              <Alert
                alertName={data.alert.alertName}
                title={data.alert.title}
                message={data.alert.message}
                options={data.alert.options}
                dismissAlert={data.dismissAlert}
                key={key}
                interpolatedStyles={style}
              />
            ) : null
          })}
        </div>
      )}
    </TransitionMotion>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertBase)
