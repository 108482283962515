import { makeActionCreator } from './utils'
import * as types from '../constants/ActionTypes'
import * as modalNames from '../constants/ModalNames'

export const openModalComponent = makeActionCreator(types.OPEN_MODAL_COMPONENT, 'modalComponent', 'modalProps')

export const openModal = makeActionCreator(types.OPEN_MODAL, 'modalName', 'modalProps')
export const closeModal = makeActionCreator(types.CLOSE_MODAL, 'modalName')
export const popModal = makeActionCreator(types.POP_MODAL)

export const openAboutModal = () => openModal(modalNames.MODAL_ABOUT)
export const closeAboutModal = () => closeModal(modalNames.MODAL_ABOUT)

export const openLayerConfig = () => openModal(modalNames.LAYER_CONFIG)
export const closeLayerConfig = () => closeModal(modalNames.LAYER_CONFIG)

export const openAccountSignInModal = () => openModal(modalNames.MODAL_ACCOUNT_OR_SIGN_IN)
export const closeAccountSignInModal = () => closeModal(modalNames.MODAL_ACCOUNT_OR_SIGN_IN)

export const openExportDataModal = () => openModal(modalNames.MODAL_EXPORT_DATA)
export const closeExportDataModal = () => closeModal(modalNames.MODAL_EXPORT_DATA)

export const openAddEditPointLocation = () => openModal(modalNames.MODAL_ADD_POINT_LOCATION)
export const closeAddEditPointLocationModal = () => closeModal(modalNames.MODAL_ADD_POINT_LOCATION)

export const openLicenseAgreementModal = initialSignIn =>
  openModal(modalNames.MODAL_LICENSE_AGREEMENT, { initialSignIn })
export const closeLicenseAgreementModal = () => closeModal(modalNames.MODAL_LICENSE_AGREEMENT)

export const openUsersLicenseAgreement = () => openModal(modalNames.MODAL_USERS_GUIDE)
export const closeUsersGuideModal = () => closeModal(modalNames.MODAL_USERS_GUIDE)

export const openDataModal = () => openModal(modalNames.MODAL_DATA)
export const closeDataModal = () => closeModal(modalNames.MODAL_DATA)

export const openSettingModal = () => openModal(modalNames.MODAL_SETTING)
export const closeSettingModal = () => closeModal(modalNames.MODAL_SETTING)

export const openFeatureSelectModal = queriedFeatures => openModal(modalNames.MODAL_FEATURE_SELECT, { queriedFeatures })
export const closeFeatureSelectModal = () => closeModal(modalNames.MODAL_FEATURE_SELECT)

export const openFileViewerModal = (dataUrl, isImage) => openModal(modalNames.MODAL_FILE_VIEWER, { dataUrl, isImage })
export const closeFileViewerModal = () => closeModal(modalNames.MODAL_FILE_VIEWER)
