/* @flow */
import produce from 'immer'
/**
 * This is a util FOR reducers, not a reducer for util actions.
 */
export function createReducerFromHandlers(initialState: Object, handlers: Object): Function {
  return (state = initialState, action) => {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}

export const createImmerReducerFromHandlers = (initialState = {}, handlers = {}) => (state = initialState, action) =>
  produce(state, draft => {
    if (action && action.type && handlers[action.type] instanceof Function) {
      return handlers[action.type](draft, action)
    }
  })
