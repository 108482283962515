/* @flow */
import React from 'react'
import { connect } from 'react-redux'
import * as selectors from '../../common/selectors'
import { ScrollView } from 'react-native-web'
import { bindActionCreators } from 'redux'
import { PageHeader, Col, Row, Glyphicon, Tooltip, OverlayTrigger, Alert } from 'react-bootstrap'
import EditPanel from '../components/EditPanel'
import { openFileViewerModal, closeFileViewerModal } from '../../common/actions/ModalActions'
import { TOOLBAR_BACKGROUND_COLOR } from '../styles'
import _ from 'lodash'
import Spinner from 'react-spinner'
import './FeatureDetailWindow.css'
export const DETAIL_WINDOW_WIDTH = 550

const styles = {
  bodyStyle: {
    width: DETAIL_WINDOW_WIDTH,
  },
  scrollViewStyle: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    paddingBottom: '2rem',
    overflowY: 'scroll',
    maxHeight: '90%',
  },
  textStyle: {
    marginBottom: '1rem',
    fontSize: '16px',
    borderBottom: '1px solid',
    borderColor: TOOLBAR_BACKGROUND_COLOR,
  },
  header: {
    textAlign: 'center',
  },
}

const mapStateToProps = state => {
  return {
    currentFeatureObject: selectors.getCurrentFeature(state),
    gisFeatureSchema: selectors.getCurrentFeatureSchema(state),
    propertyOrder: selectors.getCurrentFeaturePropertyOrder(state),
    activeDataSourceName: selectors.getActiveDataSourceName(state),
    detailWindowOpen: selectors.getDetailWindowOpen(state),
    modifiedDate: selectors.getCurrentFeatureModifiedDate(state),
    modifiedUser: selectors.getCurrentFeatureModifiedUser(state),
    versionNumber: selectors.getCurrentFeatureVersionNumber(state),
    requestingFeatureInfo: selectors.getRequestingFeatureInfo(state),
    fetchFailure: selectors.getFetchFeatureStatus(state),
    failureMessage: selectors.getFetchFeatureFailureMessage(state),
    isEditable: selectors.getCurrentFeatureIsEditable(state),
    ownedByAgency: selectors.getCurrentFeatureIsOwnedByAgency(state),
    currentFeatureAgencyName: selectors.getCurrentFeaturesAgencyName(state),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openFileViewerModal,
      closeFileViewerModal,
    },
    dispatch
  )

type Props = {
  currentFeatureObject: Function,
  gisFeatureSchema: ?Object,
  activeDataSourceName: ?string,
  detailWindowOpen: boolean,
  openFileViewerModal: Function,
  closeFileViewerModal: Function,
  propertyOrder: Array<string>,
  modifiedDate: string,
  modifiedUser: string,
  versionNumber: number,
  requestingFeatureInfo: boolean,
  fetchFailure: boolean,
  failureMessage: ?string,
  isEditable: boolean,
  ownedByAgency: boolean,
  currentFeatureAgencyName: ?string,
}

const FeatureDetailWindow = (props: Props) => {
  const {
    activeDataSourceName,
    currentFeatureObject,
    gisFeatureSchema,
    propertyOrder,
    modifiedDate,
    modifiedUser,
    versionNumber,
    requestingFeatureInfo,
    fetchFailure,
    failureMessage,
    isEditable,
    ownedByAgency,
    currentFeatureAgencyName,
  } = props
  const theDate = new Date(modifiedDate)
  const toolTipFeatureInfo = (
    <Tooltip id="toolTipFeatureInfo">
      {[
        modifiedDate && isEditable
          ? `Last modified date: \u00a0${theDate.getMonth() +
              1}-${theDate.getDate()}-${theDate.getFullYear()} ${theDate.toLocaleString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}`
          : null,
        modifiedDate && isEditable ? <br key={1} /> : null,
        modifiedUser && isEditable ? `Last modified user: \u00a0${modifiedUser}` : null,
        modifiedUser && isEditable ? <br key={2} /> : null,
        versionNumber && isEditable ? `Version number: \u00a0${versionNumber}` : null,
        isEditable && !ownedByAgency && currentFeatureAgencyName ? <br key={3} /> : null,
        isEditable && !ownedByAgency && currentFeatureAgencyName ? `Owner: \u00a0${currentFeatureAgencyName}` : null,
        !isEditable && !ownedByAgency && currentFeatureAgencyName ? `Owner: \u00a0${currentFeatureAgencyName}` : null,
      ]}
    </Tooltip>
  )

  if (!currentFeatureObject) return null
  function renderProperty(propertyName) {
    const propertyValue = currentFeatureObject.properties[propertyName]
    if (gisFeatureSchema) {
      const { attachmentPropertyNames } = gisFeatureSchema
      if (attachmentPropertyNames && attachmentPropertyNames.includes(propertyName) && Array.isArray(propertyValue)) {
        return propertyValue.map(attachment => (
          <div key={attachment.attachmentGuid}>
            <a href={attachment.fileUrl} target="_blank" rel="noopener noreferrer">
              {attachment.fileName}
            </a>
          </div>
        ))
      }
    }
    if (propertyValue === true || propertyValue === false) {
      return JSON.stringify(propertyValue)
    }
    return propertyValue
  }

  return (
    <div className="Modal-body" style={styles.bodyStyle}>
      <EditPanel />
      {fetchFailure ? (
        <Alert bsStyle="danger">
          {' '}
          <Glyphicon
            glyph="warning-sign"
            style={{
              justifyContent: 'center',
              fontSize: 22,
              margin: 8,
            }}
          />
          Error: {failureMessage}
        </Alert>
      ) : (
        (isEditable || (!isEditable && !ownedByAgency && currentFeatureAgencyName)) && (
          <OverlayTrigger overlay={toolTipFeatureInfo} placement="right">
            <Glyphicon
              glyph="info-sign"
              style={{
                justifyContent: 'center',
                fontSize: 22,
                margin: 8,
              }}
            />
          </OverlayTrigger>
        )
      )}
      <ScrollView style={styles.scrollViewStyle}>
        <PageHeader style={styles.header}>
          View Feature{activeDataSourceName ? ': ' + _.startCase(activeDataSourceName) : null}
        </PageHeader>
        {propertyOrder.map(propertyName => (
          <div key={propertyName}>
            <Row style={styles.textStyle}>
              <Col md={4} style={{ fontWeight: 'bold' }}>
                {_.get(gisFeatureSchema, `properties.${propertyName}.title`, _.startCase(propertyName))}:
              </Col>
              <Col md={8} style={{ color: fetchFailure ? 'red' : 'black' }}>
                {currentFeatureObject ? (
                  (requestingFeatureInfo && propertyName === 'attachments' && isEditable) ||
                  (requestingFeatureInfo && activeDataSourceName === 'preplan' && isEditable) ? (
                    <div>
                      {' '}
                      <Spinner />{' '}
                    </div>
                  ) : (
                    renderProperty(propertyName)
                  )
                ) : null}
              </Col>
            </Row>
          </div>
        ))}
        {currentFeatureObject.geometry && currentFeatureObject.geometry.type === 'Point' ? (
          <div key="coordinates">
            <Row style={styles.textStyle}>
              <Col md={4} style={{ fontWeight: 'bold' }}>
                Coordinates :
              </Col>
              <Col md={8} style={{ fontSize: '14px' }}>
                {currentFeatureObject.geometry.coordinates[0]},{' '}
                {`\u00A0\u00A0${currentFeatureObject.geometry.coordinates[1]}`}
              </Col>
            </Row>
          </div>
        ) : null}
      </ScrollView>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeatureDetailWindow)
