// import '../../common/config/configureReactotron'
import React, { Component } from 'react'
import IvEditorApp from './IvEditorApp'
import { Provider } from 'react-redux'
import configureStore from '../../common/store/configureStore'
import { increaseFontSize, decreaseFontSize } from '../../common/actions/MapActions'
import ApolloManager from '../../common/components/ApolloManager'
const store = configureStore()
document.onkeydown = e => {
  // Press Ctrl/Cmd+= (+) to increase font size
  if ((e.ctrlKey || e.metaKey) && (e.keyCode === 187 || e.keyCode === 107)) {
    const htmlTag = document.getElementsByTagName('html')[0]
    const currentSize = parseInt(htmlTag.style.fontSize) || 16
    htmlTag.style.fontSize = `${currentSize + 4}px`
    store.dispatch(increaseFontSize())
  }
  // Press Ctrl/Cmd+- to decrease font size
  if ((e.ctrlKey || e.metaKey) && (e.keyCode === 189 || e.keyCode === 109)) {
    const htmlTag = document.getElementsByTagName('html')[0]
    const currentSize = parseInt(htmlTag.style.fontSize) || 16
    htmlTag.style.fontSize = `${currentSize - 4}px`
    store.dispatch(decreaseFontSize())
  }
}

const elements = [
  <Provider key="provider" store={store}>
    <ApolloManager>
      <IvEditorApp />
    </ApolloManager>
  </Provider>,
]
// // only load DevTools when not in production. Hard-coded to never turn on (false), until redux DevTools supports a default closed option
// if (process.env.NODE_ENV !== 'production' && false) {
//   const { DevTools, DebugPanel, LogMonitor } = require('redux-devtools/lib/react')
//   elements.push(
//     <DebugPanel top right bottom key='debugPanel'>
//       <DevTools store={store} monitor={LogMonitor}/>
//     </DebugPanel>
//   )
// }
export default class Main extends Component {
  render() {
    return <div style={{ height: '100%' }}>{elements}</div>
  }
}
