import { makeActionCreator } from './utils'
import * as types from '../constants/ActionTypes'

export const startFileDownload = makeActionCreator(types.START_FILE_DOWNLOAD, 'downloadOptions')
export const selectDownloadOption = makeActionCreator(types.SELECT_DOWNLOAD_OPTION, 'optionName', 'selected')
export const checkForUpdates = makeActionCreator(types.CHECK_FOR_UPDATES, 'fileName')
export const setDownloadOptionPercent = makeActionCreator(
  types.SET_DOWNLOAD_OPTION_PERCENT,
  'downloadOption',
  'percent'
)
export const setFileDownloadPercent = makeActionCreator(types.FILE_DOWNLOAD_PERCENT, 'file', 'percent')
export const setFileDownloadComplete = makeActionCreator(types.FILE_DOWNLOAD_COMPLETE, 'file')
export const setFileDownloadFailed = makeActionCreator(types.FILE_DOWNLOAD_FAILED, 'url', 'agencyName', 'file', 'error')
export const setRemoveTempFile = makeActionCreator(types.REMOVE_TEMP_FILES, 'filePathTemp')
export const setFileSize = makeActionCreator(types.SET_FILE_SIZE, 'fileSizesMap')
export const toggleUpdateAutomatically = makeActionCreator(types.TOGGLE_UPDATE_AUTOMATICALLY, 'file')
