/* @flow */
import { createSelector } from 'reselect'
import * as Immutable from 'immutable'
import _ from 'lodash'
import {
  getCurrentFeature,
  getActiveDataSourceName,
  getFeatureSets,
  getGeometryEditingWindowOpen,
  getCurrentFeatureId,
} from './editor'
import { getGisDataSources } from './gisDataSources'

export const getActiveFeatureSetGuid = createSelector(
  [getActiveDataSourceName, getGisDataSources, getCurrentFeature],
  (dataSourceName, dataSources, currentFeature) => {
    if (currentFeature && currentFeature.properties.featureSetGuid) {
      return currentFeature.properties.featureSetGuid
    }
    if (!dataSourceName) {
      return null
    }
    return dataSources.get(dataSourceName).get('postGisFeatureSet')
  }
)

export const getActiveFeatureSet = createSelector(
  [getActiveFeatureSetGuid, getFeatureSets],
  (featureSetGuid, featureSets) => _.get(featureSets, featureSetGuid, null)
)

export const getCurrentFeatureSchema = createSelector(
  [getActiveFeatureSet],
  featureSet => featureSet && featureSet.feature_schema
)

export const getFeatureEdits = state => state.featureEdits.byId
export const getFeatureEditIdsByFeatureSetGuid = state => state.featureEdits.idsByFeatureSetGuid
export const getFeatureEditIdsByDataSourceName = createSelector(
  [getFeatureSets, getFeatureEditIdsByFeatureSetGuid],
  (featureSets, featureEditIdsByFeatureSetGuid) => {
    return Object.entries(featureSets).reduce((acc, [featureSetGuid, { data_source_name: dataSourceName }]) => {
      if (!acc[dataSourceName]) {
        acc[dataSourceName] = []
      }
      if (featureEditIdsByFeatureSetGuid[featureSetGuid]) {
        acc[dataSourceName] = acc[dataSourceName].concat(featureEditIdsByFeatureSetGuid[featureSetGuid])
      }
      return acc
    }, {})
  }
)

export const getFeatureSetGuidsByDataSourceName = createSelector(
  [getFeatureSets],
  featureSets => {
    return Object.entries(featureSets).reduce((acc, [featureSetGuid, { data_source_name: dataSourceName }]) => {
      if (!acc[dataSourceName]) {
        acc[dataSourceName] = []
      }
      acc[dataSourceName].push(featureSetGuid)
      return acc
    }, {})
  }
)

// featureGuids now lives in its own slice of state, rather than computed in a selector
const _getFormFeatureGuids = (state: Object): Immutable.List<string> => state.featureGuids
export const getFormFeatureGuids: (state: Object) => Immutable.List<string> = createSelector(
  _getFormFeatureGuids,
  getGeometryEditingWindowOpen,
  getCurrentFeatureId,
  (formFeatureGuids, geometryEditingWindowOpen, currentFeatureId) => {
    if (geometryEditingWindowOpen && currentFeatureId) {
      return formFeatureGuids.push(currentFeatureId)
    }
    return formFeatureGuids
  }
)
