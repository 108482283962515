/* @flow */
import * as Immutable from 'immutable'
import produce from 'immer'
import * as types from '../constants/ActionTypes'
import { createReducerFromHandlers } from './utils'
import { REHYDRATE } from 'redux-persist/constants'

export const GisDataSourcesState = Immutable.Map()
export const initialState = GisDataSourcesState

const filterOutInvalidDataSources = gisDataSources => {
  if (!gisDataSources) {
    return {}
  }
  return produce(gisDataSources, draft => {
    Object.entries(draft).forEach(([dataSourceName, dataSource]) => {
      const inVectorTileset = dataSource.mapboxTileset && dataSource.mapboxTilesetLayer
      // To display in the Editor, data source must either be in the vector tiles, and/or have a postGisFeatureSet id
      // or have a 'source' property that generally points to an outside tile server
      if (!inVectorTileset && !dataSource.postGisFeatureSet && !dataSource.source) {
        delete draft[dataSourceName]
      }
    })
  })
}

const handlers = {
  [REHYDRATE]: (state, action) => {
    const previousState = action.payload.gisDataSource
    return previousState ? Immutable.fromJS(previousState) : state
  },
  [types.SET_GIS_DATA_SOURCES]: (state, action) => {
    return Immutable.fromJS(filterOutInvalidDataSources(action.gisDataSources))
  },
  [types.REGISTRATION_FLOW_SUCCESS]: (state, action) => {
    return Immutable.fromJS(filterOutInvalidDataSources(action.gisDataSources))
  },
}

export default createReducerFromHandlers(initialState, handlers)
