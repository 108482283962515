import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

export default firebase

export const firebaseURL = 'https://incidentview.firebaseio.com'
const config = {
  projectId: 'incidentview',
  apiKey: 'AIzaSyDFkklN5RV_rE9UJ5yRBaUm9JJ4lNrCPKM',
  authDomain: 'incidentview.firebaseapp.com',
  databaseURL: firebaseURL,
  storageBucket: 'incidentview.appspot.com',
}
firebase.initializeApp(config)

const _functions = firebase.functions()

export const functions = {
  editFeature: _functions.httpsCallable('editFeature'),
}

export const firestore = firebase.firestore()

export const firebaseRoot = firebase.database().ref()
export const refAvlLocations = firebaseRoot.child('avlLocations')
export const refUsers = firebaseRoot.child('users')
export const refAgencies = firebaseRoot.child('agencies')
export const refAgencyConfigs = firebaseRoot.child('agencyConfigs')
export const refCommonConfig = firebaseRoot.child('commonConfig')
export const refDeviceGroups = firebaseRoot.child('deviceGroups')
export const refDevices = firebaseRoot.child('devices')
export const refGisDataSources = firebaseRoot.child('gisDataSources')
export const refQueues = firebaseRoot.child('queues')
export const refS3Urls = firebaseRoot.child('s3Urls')
export const serverTimestamp = firebase.database.ServerValue.TIMESTAMP
