import * as Immutable from 'immutable'
import { createSelector } from 'reselect'
import * as turf from '@turf/turf'
import fitBounds from '../utils/fitBounds'
import * as types from '../constants/ActionTypes'
import { createReducerFromHandlers } from './utils'

import { REHYDRATE } from 'redux-persist/constants'

// The viewport state is needed only for the React Map GL component
// Please keep this state separate from other reducers for performance reasons
// for example, don't make this state part of the 'map' reducer
const MapViewportState = new Immutable.Map({
  // MapGL props
  height: 600,
  width: 1000,
  // MapGL viewport attributes
  latitude: 44.566312,
  longitude: -123.261708,
  zoom: 12,
  startDragLngLat: null,
  isDragging: false,
  bearing: 0,
  pitch: 0,
  //cachedRegion:null
  // startBearing: null,
  // startPitch: null,
  // projectionMatrix: null,
})

const initialState = MapViewportState

const handlers = {
  [REHYDRATE]: (state, action) => {
    const previousState = action.payload.mapViewport

    if (previousState) {
      return Immutable.fromJS(previousState)
    }

    return state
  },

  [types.SET_VIEWPORT]: (state, action) => {
    state = state.merge(action.viewport)

    return state
  },

  [types.SET_VIEWPORT_DIMENSIONS]: (state, action) => {
    // state = state.merge(action.dimensions)
    return state
  },

  [types.FEATURE_SELECTED]: (state, action) => {
    const centroid = turf.centroid(action.feature)
    const [lng, lat] = centroid.geometry.coordinates
    return state.set('latitude', lat).set('longitude', lng)
  },

  [types.SET_TARGET_LOCATION]: (state, action) => {
    return state.merge({
      latitude: action.lat,
      longitude: action.lng,
      zoom: 17,
    })
  },
  [types.ZOOM_TO_LOCATION]: (state, action) => {
    const { lng, lat, zoom } = action
    state = state.merge({
      latitude: lat,
      longitude: lng,
      zoom: zoom,
    })

    // Remove the cachedRegion, which forces the map to use the lat/lng set here as the center.
    //state = state.set('cachedRegion', undefined)
    return state
  },

  [types.ZOOM_TO_BOUNDS]: (state, action) => {
    const { southWest, northEast } = action
    const mapGlExtent = [[southWest.lat, southWest.lng], [northEast.lat, northEast.lng]]
    return state.merge(fitBounds(state.get('width'), state.get('height'), mapGlExtent, { padding: 75 }))
  },

  [types.ZOOM_IN]: (state, action) => {
    let zoom = state.get('zoom')

    zoom = Math.min(zoom + 1, 20)

    return state.merge({
      zoom: zoom,
    })
  },

  [types.ZOOM_OUT]: (state, action) => {
    let zoom = state.get('zoom')

    zoom = Math.max(zoom - 1, 0)

    return state.merge({
      zoom: zoom,
    })
  },

  [types.SIGN_OUT]: state => {
    // TODO: these are hard-coded
    const lat = 44.5647
    const lng = -123.2608
    return state.merge({
      latitude: lat,
      longitude: lng,
      zoom: 12,
    })
  },
  [types.SET_BEARING]: (state, action) => {
    return state.merge({
      bearing: action.bearing,
      startBearing: action.bearing,
    })
  },
  [types.SET_PITCH]: (state, action) => {
    return state.merge({
      pitch: action.pitch,
      startPitch: action.pitch,
    })
  },
}

export default createReducerFromHandlers(initialState, handlers)

// selectors
export const getViewportZoom = state => state.mapViewport && state.mapViewport.get('zoom')
export const getZoomInDisabled = createSelector(
  getViewportZoom,
  zoom => zoom === 20
)
export const getZoomOutDisabled = createSelector(
  getViewportZoom,
  zoom => zoom === 0
)
