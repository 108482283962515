/* @flow */
import * as turf from '@turf/turf'
import { REHYDRATE } from 'redux-persist/constants'
import * as types from '../constants/ActionTypes'
import * as geometryTypes from '../constants/GeometryTypes'
import { createImmerReducerFromHandlers } from './utils'

// TODO: use GeoJSON types for TypeScript, if we ever move over to it; tried @mapbox/geojson-types Flow types and they're not working well
type Geometry = {
  coordinates: Array<any>,
  type: string,
}

type Feature = {
  id?: string,
  geometry: Geometry,
}

type EditorState = {
  mode: 'MAPVIEW' | 'VIEW_DETAILS' | 'EDIT_ATTRIBUTES' | 'EDIT_GEOMETRY',
  activeDataSource: ?string,
  currentFeatureObject: ?Feature,
  currentFeatureObjectId: ?string,
  currentFeatureVersionNumber: number,
  addingNewFeature: boolean,
  featureEditHistory: Array<Geometry>,
  tempFeatureGeometryObject: ?Geometry,
  shouldHighlightCurrentFeature: boolean,
  undoStatus: boolean,
  toggleLineLengthEdit: boolean,
  createMultiPartFeature: boolean,
  removeFromPolygon: boolean,
  fetchFailure: boolean,
  fetchFailureMessage: ?string,
  featureSets: {},
  agencyBoundary: {},
}

export const initialState: EditorState = {
  mode: 'MAPVIEW',

  requestingFeatureInfo: false,
  addingNewFeature: false,
  currentFeatureObject: null,
  currentFeatureObjectId: null,
  currentFeatureModifiedDate: null,
  currentFeatureModifiedUser: null,
  currentFeatureVersionNumber: null,
  shouldHighlightCurrentFeature: false,
  tempFeatureGeometryObject: null,
  featureEditHistory: [],
  trackUserLocation: true,
  activeDataSource: null,
  undoStatus: false,
  toggleLineLengthEdit: false,
  createMultiPartFeature: false,
  removeFromPolygon: false,
  featureSets: {},
  centerMap: true,
  agencyBoundary: null,
  fetchFailure: false,
  fetchFailureMessage: null,
}

const handlers = {
  [REHYDRATE]: (draft: EditorState, action) => {
    const previousState: EditorState = action.payload.editor
    if (previousState && previousState.activeDataSource) {
      draft.activeDataSource = previousState.activeDataSource
    }
    if (previousState && previousState.featureSets) {
      draft.featureSets = previousState.featureSets
    }
  },

  [types.FEATURE_SELECTED]: (draft: EditorState, action) => {
    draft.mode = 'VIEW_DETAILS'
    draft.requestingFeatureInfo = true
    // Unique id is stored in vector tiles' `guid` property since we couldn't rely on `id`s coming from vector tile.
    // We delete it from the properties here so that guid isn't saved in properties on Firebase or Postgres.
    const { guid, ...propertiesWithoutGuid } = action.feature.properties
    draft.currentFeatureObject = {
      id: action.feature.properties.guid,
      properties: propertiesWithoutGuid,
      geometry: action.feature.geometry,
      type: 'Feature',
    }
    draft.currentFeatureObjectId = draft.currentFeatureObject.id
    draft.shouldHighlightCurrentFeature = true
    draft.activeDataSource = action.dataSourceName
    draft.centerMap = true
  },
  [types.FETCH_FEATURE_INFO_SUCCESS]: (draft: EditorState, action) => {
    draft.currentFeatureObject = turf.feature(action.geometry, {
      ...action.properties,
      featureSetGuid: action.featureSetGuid,
    })
    draft.currentFeatureObject.id = action.featureGuid
    draft.currentFeatureModifiedDate = action.modifiedDate
    draft.currentFeatureModifiedUser = action.modifiedUserEmail
    draft.currentFeatureVersionNumber = action.version
    draft.requestingFeatureInfo = false
    draft.fetchFailure = false
  },
  [types.FETCH_FEATURE_INFO_FAILURE]: (draft: EditorState, action) => {
    draft.requestingFeatureInfo = false
    draft.fetchFailure = true
    draft.fetchFailureMessage = action.errorMessage
  },
  [types.FEATURE_UNSELECTED]: (draft: EditorState, action) => {
    draft.mode = 'MAPVIEW'
    draft.currentFeatureObject = null
    draft.currentFeatureObjectId = null
    draft.tempFeatureGeometryObject = null
    draft.currentFeatureModifiedDate = null
    draft.currentFeatureModifiedUser = null
    draft.currentFeatureVersionNumber = null
    draft.featureEditHistory = []
    draft.toggleLineLengthEdit = false
    draft.addingNewFeature = false
    draft.fetchFailure = false
    draft.centerMap = true
    draft.fetchFailureMessage = null
  },
  [types.OPEN_DETAIL_WINDOW]: (draft: EditorState) => {
    draft.mode = 'VIEW_DETAILS'
  },
  // TODO: replace usages of this action with FEATURE_UNSELECTED
  [types.CLOSE_DETAIL_WINDOW]: (draft: EditorState) => {
    draft.mode = 'MAPVIEW'
    draft.currentFeatureObject = null
    draft.currentFeatureObjectId = null
    draft.addingNewFeature = false
  },
  // TODO: rename to START_EDIT_ATTRIBUTES
  [types.OPEN_EDIT_WINDOW]: (draft: EditorState) => {
    draft.mode = 'EDIT_ATTRIBUTES'
  },
  // TODO: rename to START_EDIT_GEOMETRY
  [types.OPEN_GEOMETRY_EDITING_WINDOW]: (draft: EditorState, action) => {
    draft.mode = 'EDIT_GEOMETRY'
    const featureGeom = draft.currentFeatureObject && draft.currentFeatureObject.geometry
    // Save the current geometry so cancel can revert back
    if (featureGeom) {
      draft.featureEditHistory = [featureGeom]
      draft.tempFeatureGeometryObject = featureGeom
    }
  },
  [types.CANCEL_FEATURE_GEOMETRY_EDITS]: (draft: EditorState) => {
    draft.mode = 'MAPVIEW'
    draft.currentFeatureObject = null
    draft.currentFeatureObjectId = null
    draft.tempFeatureGeometryObject = null
    draft.featureEditHistory = []
    draft.toggleLineLengthEdit = false
    draft.addingNewFeature = false
  },
  [types.START_ADD_NEW_FEATURE]: (draft: EditorState, action) => {
    draft.mode = 'EDIT_GEOMETRY'
    draft.addingNewFeature = true
    draft.currentFeatureObject = null
    draft.currentFeatureObjectId = null
  },
  // TODO: rename to EDIT_ATTRIBUTES_SUBMIT_SUCCEEDED
  [types.SUBMIT_FEATURE_ATTRIBUTE_EDITS]: (draft: EditorState) => {
    draft.mode = 'MAPVIEW'
    draft.addingNewFeature = false
    draft.shouldHighlightCurrentFeature = false
  },
  // Multi-polygon actions
  [types.ADD_EDIT_POINT_LOCATION]: (draft: EditorState, action) => {
    let currentFeature = draft.currentFeatureObject
    const newPoint = [action.lng, action.lat]
    const geometryType = currentFeature ? currentFeature.geometry.type : action.geometryType
    if (!currentFeature) {
      currentFeature = {
        geometry: {
          type: geometryType,
          coordinates: [],
        },
        properties: {},
        type: 'Feature',
      }
      // Create a new empty geometry
      if (geometryType === geometryTypes.MULTIPOLYGON) {
        currentFeature.geometry.coordinates = [[[]]]
      } else if (geometryType === geometryTypes.POLYGON) {
        currentFeature.geometry.coordinates = [[]]
      } else if (geometryType === geometryTypes.LINESTRING || geometryType === geometryTypes.LINE) {
        currentFeature.geometry.coordinates = [[]]
      } else if (geometryType === geometryTypes.POINT) {
        currentFeature.geometry.coordinates = []
      }
    }

    // Update the point geometry
    if (geometryType === geometryTypes.POINT) {
      const newGeometry = {
        coordinates: newPoint,
        type: geometryType,
      }
      currentFeature.geometry = newGeometry
      const history = draft.featureEditHistory
      history.push(newPoint)
      draft.featureEditHistory = history
    } else if (geometryType === geometryTypes.LINESTRING || geometryType === geometryTypes.LINE) {
      const currentLine = currentFeature.geometry.coordinates[0]
      const lineArray = Array.from(currentLine)
      if (lineArray && lineArray.length > 0) {
        currentFeature.geometry.type = 'LineString'
        currentFeature.geometry.coordinates.push(newPoint)
      } else {
        const newGeometry = {
          coordinates: [newPoint],
          type: geometryType,
        }
        currentFeature.geometry = newGeometry
      }
    } else if (geometryType === geometryTypes.POLYGON) {
      const currentPolygon = currentFeature.geometry.coordinates[0]
      const polygonArray = Array.from(currentPolygon)
      // For the first point we add it twice to keep the polygon ring closed
      // For later points we splice second from the end to keep the duplicate
      // first point at the last spot
      let newPolygon = [newPoint, newPoint]
      if (polygonArray && polygonArray.length > 0) {
        newPolygon = currentFeature.geometry.coordinates[0]
        newPolygon.splice(-1, 0, newPoint)
      }
      currentFeature.geometry.coordinates[0] = newPolygon
    } else if (geometryType === geometryTypes.MULTIPOLYGON) {
      const currentPolygon = currentFeature.geometry.coordinates[0][0]
      const polygonArray = Array.from(currentPolygon)
      // For the first point we add it twice to keep the polygon ring closed
      // For later points we splice second from the end to keep the duplicate
      // first point at the last spot
      let newPolygon = [newPoint, newPoint]
      if (polygonArray && polygonArray.length > 0) {
        newPolygon = currentFeature.geometry.coordinates[0][0]
        newPolygon.splice(-1, 0, newPoint)
      }
      currentFeature.geometry.coordinates[0][0] = newPolygon
    }

    draft.currentFeatureObject = currentFeature
    draft.shouldHighlightCurrentFeature = true
  },
  [types.CLEAR_FEATURE_GEOMETRY]: (draft: EditorState) => {
    if (draft.currentFeatureObject) {
      const currentFeatureGeometryType = draft.currentFeatureObject.geometry.type
      if (currentFeatureGeometryType === geometryTypes.MULTIPOLYGON) {
        draft.currentFeatureObject.geometry.coordinates = [[[]]]
      } else if (currentFeatureGeometryType === geometryTypes.POLYGON) {
        draft.currentFeatureObject.geometry.coordinates = [[]]
        draft.featureEditHistory = []
      } else if (currentFeatureGeometryType === geometryTypes.LINESTRING) {
        draft.currentFeatureObject.geometry.coordinates = [[]]
      } else if (currentFeatureGeometryType === geometryTypes.POINT) {
        draft.currentFeatureObject.geometry.coordinates = []
      }
    }
  },
  [types.FIREBASE_UPDATE_COMPLETE]: (draft: EditorState) => {
    draft.currentFeatureObject = null
    draft.currentFeatureObjectId = null
  },
  [types.CLEAR_CURRENT_FEATURE_OBJECT]: (draft: EditorState) => {
    draft.currentFeatureObject = null
    draft.currentFeatureObjectId = null
  },
  [types.UNDO_FEATURE_GEOMETRY_VERTEX]: (draft: EditorState) => {
    // removing last item in array for undo.
    // If redo functionality is requested: put undoFeatureGeometry into an array in state, 'redoFeatureGeometry'
    const currentEditHistory = draft.featureEditHistory
    const currentFeatureObject = draft.currentFeatureObject
    if (!currentFeatureObject) {
      return
    }
    let undoFeatureGeometry
    if (currentEditHistory.length === 1) {
      undoFeatureGeometry = currentEditHistory[0]
    }
    if (currentEditHistory.length !== 1) {
      // regular undo process
      undoFeatureGeometry = currentEditHistory.pop()
      const undoUndoFeatureGeometry = currentEditHistory[currentEditHistory.length - 1]
      // sometimes double-clicking when creating poly can make duplicate edit history entries
      // if (JSON.stringify(undoFeatureGeometry.coordinates) === JSON.stringify(undoUndoFeatureGeometry.coordinates)) {
      //   undoFeatureGeometry = currentEditHistory.pop()
      // }
      if (
        JSON.stringify(undoFeatureGeometry) === JSON.stringify(currentFeatureObject.geometry) &&
        currentEditHistory.length !== 1
      ) {
        undoFeatureGeometry = undoUndoFeatureGeometry
      }
      // returning to original feature
      if (currentEditHistory.length === 1) {
        undoFeatureGeometry = currentEditHistory[0]
      }
      if (
        (currentFeatureObject.geometry.type === 'Line' || currentFeatureObject.geometry.type === 'LineString') &&
        currentEditHistory.length === 1
      ) {
        undoFeatureGeometry = currentEditHistory[0]
      }
    }
    if (undoFeatureGeometry) {
      currentFeatureObject.geometry = undoFeatureGeometry
    }
    draft.currentFeatureObject = currentFeatureObject
    draft.featureEditHistory = currentEditHistory
    draft.undoStatus = true
  },
  [types.DELETE_FEATURE]: (draft: EditorState, action) => {
    draft.mode = 'MAPVIEW'
    draft.currentFeatureObject = null
    draft.currentFeatureObjectId = null
  },
  [types.REFRESH_MAP]: (draft: EditorState, action) => {
    if (action.visibleLayerNames.indexOf(draft.activeDataSource) === -1) {
      draft.mode = 'MAPVIEW'
      draft.activeDataSource = null
    }
  },
  [types.SET_ACTIVE_DATA_SOURCE]: (draft: EditorState, action) => {
    if (action.key) {
      draft.activeDataSource = action.key
    }
  },

  [types.MAP_DRAW_UPDATE]: (draft: EditorState, action) => {
    let theCurrentFeatureObject
    let drawFeature = JSON.parse(JSON.stringify(action.feature))
    const currentEditHistory = draft.featureEditHistory
    if (drawFeature === undefined) {
      return
    } else {
      if (drawFeature.length === 1) {
        drawFeature = drawFeature[0]
      }
      if (draft.currentFeatureObject && draft.removeFromPolygon === false) {
        theCurrentFeatureObject = draft.currentFeatureObject
        // sometimes with double clicking mapbox gl draw fires draw.update more than once
        // preventing duplicate geometry coordinate entries
        const drawFeatureLength = drawFeature.geometry.coordinates.length - 1
        if (
          theCurrentFeatureObject &&
          theCurrentFeatureObject.geometry &&
          theCurrentFeatureObject.geometry.coordinates
        ) {
          const comparisonDrawFeatureGeom = JSON.stringify(drawFeature.geometry.coordinates)
          const comparisonCurrentFeatureGeom = JSON.stringify(theCurrentFeatureObject.geometry.coordinates)
          let match
          let currentFeatureObjectCoordinates = []
          for (const coordinates of theCurrentFeatureObject.geometry.coordinates) {
            currentFeatureObjectCoordinates.push(coordinates[0])
            if (JSON.stringify(currentFeatureObjectCoordinates) === comparisonDrawFeatureGeom) {
              match = true
            }
            currentFeatureObjectCoordinates = []
          }

          if (match || comparisonCurrentFeatureGeom === comparisonDrawFeatureGeom) {
            return
          }
        }
        if (
          JSON.stringify(drawFeature.geometry.coordinates[drawFeatureLength]) ===
          JSON.stringify(drawFeature.geometry.coordinates[drawFeatureLength - 1])
        ) {
          drawFeature.geometry.coordinates.pop()
        }
      }

      if (
        !theCurrentFeatureObject ||
        (theCurrentFeatureObject.geometry &&
          theCurrentFeatureObject.geometry.coordinates &&
          theCurrentFeatureObject.geometry.coordinates.length <= 1)
      ) {
        // putting mapbox-gl-draw data into currentFeatureObject
        draft.currentFeatureObject = drawFeature
        // create history of edits for undo button
        if (
          JSON.stringify(drawFeature.geometry) !== JSON.stringify(currentEditHistory[currentEditHistory.length - 1])
        ) {
          draft.featureEditHistory.push(drawFeature.geometry)
        }
        return
      } else if (theCurrentFeatureObject) {
        if (!theCurrentFeatureObject.id) {
          const id = drawFeature.id
          theCurrentFeatureObject.id = id
        }
        if (!(drawFeature.geometry.type === 'LineString' || drawFeature.geometry.type === 'Line')) {
          // For recently deleted polys:
          const areDeletingPoly = draft.removeFromPolygon
          if (areDeletingPoly === true) {
            theCurrentFeatureObject = drawFeature
            currentEditHistory.push(theCurrentFeatureObject.geometry)
          } else {
            // For newly created multi-polys:
            if (drawFeature && drawFeature.geometry.coordinates.length === 1) {
              theCurrentFeatureObject.geometry.coordinates.push(drawFeature.geometry.coordinates)
            } else {
              theCurrentFeatureObject = drawFeature
            }
          }
        } else if (
          theCurrentFeatureObject &&
          theCurrentFeatureObject.geometry &&
          (drawFeature.geometry.type === 'LineString' || drawFeature.geometry.type === 'Line')
        ) {
          if (JSON.stringify(theCurrentFeatureObject.geometry) !== JSON.stringify(drawFeature.geometry)) {
            theCurrentFeatureObject = drawFeature
          }
          if (
            JSON.stringify(currentEditHistory[currentEditHistory.length - 1]) !==
            JSON.stringify(theCurrentFeatureObject.geometry)
          ) {
            currentEditHistory.push(theCurrentFeatureObject.geometry)
          }
        }
      }
      draft.currentFeatureObject = theCurrentFeatureObject
      draft.featureEditHistory = currentEditHistory
      return
    }
  },
  [types.MAP_DRAW_UPDATE_MULTI]: (draft: EditorState, action) => {
    let oldFeature = JSON.parse(JSON.stringify(draft.currentFeatureObject))
    if (!oldFeature) return
    const newFeatures = action.feature
    let newCoordinates = []
    let id
    let isSingle
    let isMulti
    let isSingleAndMulti
    // can be given as single multi-part in one case
    for (const feature of newFeatures) {
      if (feature.geometry.coordinates.length === 1) {
        isSingle = true
      } else if (feature.geometry.coordinates.length > 1) {
        isMulti = true
      } else {
        // No coordinates, so return without modifying state
        return
      }
      if (isSingle && isMulti) {
        isSingleAndMulti = true
      }
    }
    if (isSingleAndMulti) {
      // newFeatures given as single multi-part features
      let tempGeom = []
      newFeatures.forEach(feature => {
        if (feature.geometry.coordinates.length === 1) {
          tempGeom.push(feature.geometry.coordinates)
        }
        if (feature.geometry.coordinates.length > 1) {
          Object.entries(feature).forEach(([key, value]) => {
            if (key === 'id') {
              id = value
            }
            if (key === 'geometry') {
              // $FlowFixMe
              for (const val of value.coordinates) {
                tempGeom.push(val)
              }
            }
          })
        }
      })
      oldFeature.id = id
      oldFeature.geometry.coordinates = tempGeom
    } else if (Array.isArray(newFeatures) && newFeatures[0].geometry.coordinates.length > 1) {
      // newFeatures given as multiPart
      oldFeature.id = newFeatures[0].id
      oldFeature.geometry = newFeatures[0].geometry
    } else {
      // newFeatures given as single polys
      if (newFeatures.length === 1 && newFeatures[0].geometry.type === 'Polygon') {
        oldFeature = newFeatures[0]
      } else {
        newFeatures.forEach(feature => {
          Object.entries(feature).forEach(([key, value]) => {
            if (key === 'id') {
              // we need an id of some sort for undo
              id = value
            }
            if (key === 'geometry') {
              // $FlowFixMe
              newCoordinates.push(value.coordinates)
            }
          })
        })
        oldFeature.id = id
        oldFeature.geometry.coordinates = newCoordinates
      }
    }
    const historyCheck = draft.featureEditHistory
    if (JSON.stringify(historyCheck[historyCheck.length - 1]) !== JSON.stringify(oldFeature.geometry)) {
      const newHistory = draft.featureEditHistory
      if (oldFeature.geometry.coordinates.length > 1) {
        oldFeature.geometry.type = 'MultiPolygon'
      }
      newHistory.push(oldFeature.geometry)
      draft.featureEditHistory = newHistory
    }
    draft.currentFeatureObject = oldFeature
  },
  [types.ADD_TO_POLYGON]: (draft: EditorState) => {
    draft.createMultiPartFeature = !draft.createMultiPartFeature
  },
  [types.REMOVE_FROM_POLYGON]: (draft: EditorState) => {
    draft.removeFromPolygon = !draft.removeFromPolygon
  },
  [types.TOGGLE_LINE_LENGTH_EDIT]: (draft: EditorState) => {
    draft.toggleLineLengthEdit = !draft.toggleLineLengthEdit
  },
  [types.SET_TOGGLE_ALL_MAP_LAYERS]: (draft: EditorState, action) => {
    if (action.isVisible === false) {
      draft.activeDataSource = null
    }
  },
  [types.SET_TOGGLE_MAP_LAYER]: (draft: EditorState, action) => {
    const currentActiveDataSource = draft.activeDataSource
    if (currentActiveDataSource === action.layerName && action.isVisible === false) {
      draft.activeDataSource = null
    }
  },
  [types.SET_UNDO_STATUS]: (draft: EditorState) => {
    draft.undoStatus = !draft.undoStatus
  },
  [types.REGISTRATION_FLOW_SUCCESS]: (draft: EditorState, action) => {
    draft.featureSets = action.featureSets || {}
    draft.featureSets = action.featureSets
    draft.agencyBoundary = action.agencyBoundary
  },
  [types.CENTER_MAP]: (draft: EditorState) => {
    draft.centerMap = !draft.centerMap
  },
}

export default createImmerReducerFromHandlers(initialState, handlers)
