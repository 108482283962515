/* @flow */
/* This component exists to create a new client with the proper headers
  in response to any change in auth state, while not creating a new client
  every time IvEditorApp rerenders */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import { getToken } from '../selectors'
import { createClient } from '../apollo'

const mapStateToProps = state => {
  return {
    token: getToken(state),
  }
}
type Props = {
  children: any,
  token: string,
}

const ApolloManager = (props: Props) => {
  const [activeClient, setActiveClient] = useState(null)
  const refreshClient = async (token?: string) => {
    const newClient = createClient(token)
    // await newClient.init() // Use this if/when using offix for offline edits
    setActiveClient(newClient)
  }
  useEffect(() => {
    refreshClient(props.token)
  }, [props.token])
  if (!activeClient) return null
  return <ApolloProvider client={activeClient}>{props.children}</ApolloProvider>
}

export default connect(mapStateToProps)(ApolloManager)
