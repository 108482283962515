/* @flow */
import { useEffect } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'

type Props = {
  query: any,
  onChange: Function,
  onDocumentRemoved: Function,
}

const FirestoreConnector = ({ query, onChange, onDocumentRemoved }: Props) => {
  const { error, value } = useCollection(query)

  useEffect(() => {
    if (error) {
      console.error('Error in FirestoreConnector', error)
    }

    if (value) {
      if (onDocumentRemoved) {
        const deletedDocIds = value
          .docChanges()
          .filter(change => change.type === 'removed')
          .map(change => change.doc.id)
        if (deletedDocIds.length) {
          onDocumentRemoved(deletedDocIds)
        }
      }
    }

    let data
    if (value) {
      data = {}
      value.forEach(doc => {
        data[doc.id] = doc.data()
      })
    }
    onChange(data)
  }, [value, error])

  return null
}

export default FirestoreConnector
