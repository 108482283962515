import { List, Record } from 'immutable'

const UserRecord = Record({
  key: null, // not in firebase data, but needed in app
  uid: null, // not in firebase data, but needed in app
  // attributes in firebase
  email: null,
  agencyName: 'or_demo_fd',
  roles: new List(),
})

export default UserRecord

export const createUserRecordFromFirebaseObj = obj => {
  return new UserRecord({
    ...obj,
    // uid comes in from firebase as key
    uid: obj.uid || obj.key,
    key: obj.key || obj.uid,
    roles: obj.roles ? new List(Object.keys(obj.roles)) : new List(),
  })
}
