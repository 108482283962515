/* @flow */
import * as Immutable from 'immutable'
import { createReducerFromHandlers } from './utils'
import * as types from '../constants/ActionTypes'

export const EditFormState = Immutable.Record({
  // eslint-disable-line new-cap
  formFields: Immutable.OrderedMap(),
  formLayout: Immutable.Map(),
  errorPosition: 0,
  selectedMedia: Immutable.List(),
  pendingDeleteList: Immutable.List(),
  shouldScroll: false,
  showEditSpinner: false,
  initialValues: {},
})

export const initialState = new EditFormState()

const handlers = {
  [types.ON_MEDIA_SELECTION_CHANGED]: (state, action) => {
    let index = parseInt(action.index)
    if (action.isSelected) {
      state = state.set('selectedMedia', state.selectedMedia.push(index))
    } else {
      let ndx = state.selectedMedia.findIndex(v => v === index)
      if (ndx !== -1) {
        state = state.set('selectedMedia', state.selectedMedia.delete(ndx))
      }
    }
    return state
  },
  [types.DELETE_SELECTED_IMAGES]: (state, action) => {
    return state
  },
  [types.GET_GIS_FEATURE_SCHEMAS_SUCCESS]: (state, action) => {
    let formFields = Immutable.OrderedMap(action.featureSchemas)
    return state.set('formFields', formFields)
  },
  [types.SCROLL_TO_ERROR]: (state, action) => {
    let key = Object.keys(action.error)[0]
    let errorPosition = state.get('formLayout').get(key)
    return state.set('errorPosition', errorPosition).set('shouldScroll', true)
  },
  [types.SCROLLED_TO_ERROR]: (state, action) => {
    return state.set('shouldScroll', false)
  },
  [types.SUBMIT_FEATURE_ATTRIBUTE_EDITS]: state => {
    return state.set('showEditSpinner', true)
  },
  [types.DELETE_FEATURE]: state => {
    return state.set('showEditSpinner', true)
  },
  [types.SUBMIT_FEATURE_GEOMETRY_EDITS]: state => {
    return state.set('showEditSpinner', true)
  },
  [types.SAVE_FEATURE_EDIT_REQUEST]: state => {
    return state.set('showEditSpinner', true)
  },
  [types.FEATURE_EDITS_UPDATED]: state => {
    return state.set('showEditSpinner', false)
  },
  [types.FIREBASE_UPDATE_COMPLETE]: state => {
    return state.set('showEditSpinner', false)
  },
  [types.FEATURE_UNSELECTED]: state => {
    return state.set('showEditSpinner', false)
  },
  [types.SAVE_FEATURE_EDIT_SUCCESS]: state => {
    return state.set('showEditSpinner', false)
  },
  [types.INPUT_LAID_OUT]: (state, action) => {
    let { name, index } = action
    let fieldHeight = action.layout.height
    // 0 or greater:
    let yPos = Math.max(0, fieldHeight * (index - 1))
    return state.update('formLayout', formLayout => formLayout.set(name, yPos))
  },
}

export default createReducerFromHandlers(initialState, handlers)
