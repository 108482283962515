import { makeActionCreator } from './utils'
import * as types from '../constants/ActionTypes'
import $ from 'jquery'

/**
 * Make the search bar active. Set up one-time click event to deactivate search
 * bar when any part of app is cliked
 */
export function activateSearchBar() {
  return (dispatch, getState) => {
    dispatch({ type: types.ACTIVATE_SEARCH_BAR })
    if (window && window.document) {
      // TODO: find way to not rely on jQuery
      $('html').one('click', () => {
        // if (!$(event.target).is('#ivSearchBar') && !$(event.target).parents('#ivSearchBar').is('#ivSearchBar')) {
        //   dispatch(deactivateSearchBar())
        // }
      })
    }
  }
}

export const deactivateSearchBar = makeActionCreator(types.DEACTIVATE_SEARCH_BAR)
export const geocodeSearchBarRequest = makeActionCreator(types.GEOCODE_SEARCH_BAR_REQUEST, 'searchText')
export const geocodeSearchBarSuccess = makeActionCreator(types.GEOCODE_SEARCH_BAR_SUCCESS, 'results')
export const geocodeSearchBarFailure = makeActionCreator(types.GEOCODE_SEARCH_BAR_FAILURE, 'error')
export const localDataSearchBarSuccess = makeActionCreator(types.LOCAL_DATA_SEARCH_BAR_SUCCESS, 'results')
export const searchTextUpdated = makeActionCreator(types.SEARCH_TEXT_UPDATED, 'searchText')
export const setCurrentSearchBarResult = makeActionCreator(types.SET_CURRENT_SEARCH_BAR_RESULT, 'id')
export const setLatLngResult = makeActionCreator(types.SET_LAT_LNG_RESULT, 'result')
