/**
 * Created by lewladd on 2/23/17.
 */

import { makeActionCreator } from './utils'
import * as types from '../constants/ActionTypes'

// export const cacheSubmission = makeActionCreator(types.CACHE_SUBMISSION, 'formValues', 'features', 'layerName')
export const setIsSyncing = makeActionCreator(types.SET_IS_SYNCING)
export const clearIsSyncing = makeActionCreator(types.CLEAR_IS_SYNCING)
export const copyAttachmentsLocally = makeActionCreator(types.COPY_ATTACHMENTS_LOCALLY, 'formValues')
