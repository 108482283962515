/* @flow */
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { Button, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import Modal from './SimpleModal'
import { getLoadingAuthRequest } from '../../common/selectors/auth'
import { textInputStyle } from '../styles'

const mapStateToProps = state => ({
  loadingAuthRequest: getLoadingAuthRequest(state),
})

type Props = {
  closeSignIn: Function,
  submitSignIn: Function,
  interpolatedStyles: Object,
  loadingAuthRequest?: boolean,
}

const SignInModal = (props: Props) => {
  const { closeSignIn, submitSignIn, interpolatedStyles, loadingAuthRequest } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  return (
    <Modal
      interpolatedStyles={interpolatedStyles}
      title="Sign In"
      closeModal={closeSignIn}
      showSpinner={loadingAuthRequest}
    >
      <form
        onSubmit={e => {
          e.preventDefault()
          submitSignIn(email, password)
        }}
      >
        <FormGroup controlId="formControlsEmail">
          <ControlLabel>Email Address</ControlLabel>
          <FormControl
            type="email"
            placeholder="Enter email"
            style={textInputStyle}
            value={email}
            onChange={e => setEmail(e.target.value)}
            autoFocus
          />
        </FormGroup>
        <FormGroup controlId="formControlsPassword">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            type="password"
            style={textInputStyle}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </FormGroup>
        <Button bsClass="btn btn-dark" bsStyle={null} type="submit">
          Submit
        </Button>
      </form>
    </Modal>
  )
}

export default connect(mapStateToProps)(SignInModal)
