import USNG2 from '../utils/usng2'
import LatLng from '../records/LatLng'

// converting from lat/long to other formats

export const latLongToUSNG = (lat, lng) => {
  return new USNG2().fromLonLat({ lon: lng, lat: lat }, 5)
}

function doubleToDM(coord) {
  let dm = []
  let sign = coord < 0 ? -1.0 : 1.0
  let ucoord = coord * sign

  let d = Math.floor(ucoord)
  let m = (ucoord - d) * 60.0

  dm[0] = d * sign
  dm[1] = m

  return dm
}

function doubleToDMS(coord) {
  let dms = []
  let sign = coord < 0 ? -1.0 : 1.0
  let ucoord = coord * sign

  let d = Math.floor(ucoord)
  let m = Math.floor((ucoord - d) * 60.0)
  let dm = d + m / 60.0
  let s = (ucoord - dm) * 3600.0

  dms[0] = d * sign
  dms[1] = m
  dms[2] = s

  return dms
}

export function latLongToDMS(lat, lon) {
  let rval = ''
  let dmslat = doubleToDMS(lat)
  let dmslon = doubleToDMS(lon)

  // Degrees + Minutes
  let slat = Math.round(dmslat[0]) + ' ' + dmslat[1].toFixed(0)
  let slon = Math.round(dmslon[0]) + ' ' + dmslon[1].toFixed(0)

  slat += ' ' + dmslat[2].toFixed(3)
  slon += ' ' + dmslon[2].toFixed(3)

  rval = slat + ', ' + slon
  return rval
}

export function latLongToNsarcLatLon(lat, lon) {
  let dmlat = doubleToDM(lat)
  let dmlon = doubleToDM(lon)

  let ns = 'N'
  if (dmlat[0] < 0) {
    ns = 'S'
    dmlat[0] *= -1.0
  }
  let ew = 'E'
  if (dmlon[0] < 0) {
    ew = 'W'
    dmlon[0] *= -1.0
  }

  const latDegrees = Math.round(dmlat[0])
  const lngDegrees = Math.round(dmlon[0])
  const latMins = dmlat[1].toFixed(3)
  const lngMins = dmlon[1].toFixed(3)
  const degreeSymbol = String.fromCharCode(176)
  const latString = `${latDegrees}${degreeSymbol} ${latMins} ${ns}`
  const lngString = `${lngDegrees}${degreeSymbol} ${lngMins} ${ew}`

  return `${latString}, ${lngString}`
}

// converting back to lat/long

const dmsToDouble = dms => {
  const parsedDms = dms.split(' ')
  const degrees = Number(parsedDms[0])
  const sign = degrees < 0 ? -1 : 1
  return sign * (sign * degrees + parsedDms[1] / 60 + parsedDms[2] / 3600)
}

const nsarcToDouble = dm => {
  const parsedNsarc = dm.replace('°', '').split(' ')
  const sign = parsedNsarc[2] === 'S' || parsedNsarc[2] === 'W' ? -1 : 1
  return sign * (Number(parsedNsarc[0]) + parsedNsarc[1] / 60)
}

const stringToLatLng = (string, test, toDouble) => {
  const parsedLatLng = string.match(test)
  return parsedLatLng && new LatLng({ lat: toDouble(parsedLatLng[1]), lng: toDouble(parsedLatLng[2]) })
}

const usngToLatLng = usng => {
  const lonLat = new USNG2().toLonLat(usng)
  return new LatLng({ lat: lonLat.lat, lng: lonLat.lon })
}

const latLongTest = /^(-?\d{1,3}\.?\d*), *(-?\d{1,3}\.?\d*)$/
const dmsTest = /^(-?\d{1,3} \d{1,2} \d{1,2}.?\d*), *(-?\d{1,3} \d{1,2} \d{1,2}.?\d*)$/
const nsarcTest = /^(\d{1,3}°? \d{1,2}.?\d* [NS]), *(\d{1,3}°? \d{1,2}.?\d* [EW])$/
const usngTest = /^\d{1,3}[A-Z] ?[A-Z]{2} ?\d{1,5} ?\d{1,5}$/

/**
 * parseCoordinates takes a string (e.g. entered into the search bar)
 * and tries to parse it as a coordinate location. If it parses successfully,
 * returns a LatLng object with the parsed values. Returns false if not.
 * @param  {String} string [search string to try parsing]
 * @return                 [LatLng object, null or false]
 */
export const parseCoordinates = string => {
  if (latLongTest.test(string)) return stringToLatLng(string, latLongTest, num => Number(num))
  if (dmsTest.test(string)) return stringToLatLng(string, dmsTest, dmsToDouble)
  if (nsarcTest.test(string)) return stringToLatLng(string, nsarcTest, nsarcToDouble)
  if (usngTest.test(string)) return usngToLatLng(string)
  return false
}
