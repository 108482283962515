/* @flow */
import React from 'react'
import { FormGroup, Checkbox, Collapse, ProgressBar } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Modal from './SimpleModal'
import * as downloadActions from '../../common/actions/DownloadActions'
import { getDownloadOptionsArrayReadableSize } from '../../common/selectors/download'

const optionWrapper = {
  marginBottom: '1.5rem',
  width: '100%',
}

const optionHeader = {
  width: '100%',
  backgroundColor: '#444',
  padding: '0.5rem',
  display: 'flex',
  justifyContent: 'space-between',
}

const optionRow = {
  width: '100%',
  borderBottom: '1px solid #444',
  padding: '0.5rem',
  display: 'flex',
  justifyContent: 'space-between',
}

const fileSizeStyle = {
  color: '#ccc',
}

const mapStateToProps = state => ({
  downloadOptions: getDownloadOptionsArrayReadableSize(state),
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(downloadActions, dispatch),
})

type Props = {
  interpolatedStyles: Object,
  closeData: Function,
  downloadOptions: Array<Object>,
  checkForUpdates: Function,
  toggleUpdateAutomatically: Function,
}

const DataModal = ({
  interpolatedStyles,
  closeData,
  downloadOptions,
  checkForUpdates,
  toggleUpdateAutomatically,
}: Props) => (
  <Modal interpolatedStyles={interpolatedStyles} title="Data" closeModal={closeData}>
    <FormGroup>
      {downloadOptions.map((opt, key) => (
        <div style={optionWrapper} key={key}>
          <div style={optionHeader}>
            <div>{opt.displayName}</div>
            <div style={fileSizeStyle}>{opt.fileSize || '(not loaded)'}</div>
          </div>
          <div style={optionRow}>
            <div>
              {opt.percent ? (
                'Downloading...'
              ) : (
                <button onClick={() => checkForUpdates(opt.optionName)}>
                  {opt.fileSize ? 'Check for Updates' : opt.downloadButtonText}
                </button>
              )}
            </div>
            <div>
              <Checkbox
                inline
                checked={opt.updateAutomatically}
                onChange={e => toggleUpdateAutomatically(opt.optionName)}
              >
                Update Automatically
              </Checkbox>
            </div>
          </div>
          <div>
            <Collapse in={opt.percent > 0 && opt.percent <= 100}>
              <div>
                <ProgressBar active now={opt.percent} label={`${opt.percent.toFixed(2)}%`} />
              </div>
            </Collapse>
          </div>
        </div>
      ))}
    </FormGroup>
  </Modal>
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataModal)
