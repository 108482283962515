/* @flow */
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Spinner from '../components/Spinner'
import * as Actions from '../../common/actions'
import * as selectors from '../../common/selectors'
import { firestore } from '../../common/firebase'
import FirestoreConnector from '../../common/components/FirestoreConnector'
import TopNavbar, { TOP_NAVBAR_HEIGHT } from '../components/TopNavbar'
import AlertBase from './AlertBase'
import ModalBase from './ModalBase'
import Toast from '../components/Toast'
import LoginPage from './LoginPage'
import IvReactMapboxGlMap from './IvReactMapboxGlMap'
import GeometryEditWindow from './GeometryEditWindow'
import FeatureEditWindow from './FeatureEditWindow'
import FeatureDetailWindow from './FeatureDetailWindow'

const leftToRightContainerStyle = {
  display: 'flex',
  height: `calc(100% - ${TOP_NAVBAR_HEIGHT})`,
}

const messageStyle = {
  width: '100%',
  height: 0,
  position: 'relative',
}

const divStyle = {
  width: '100%',
  height: '100%',
  position: 'relative',
  zIndex: '0',
  overflow: 'hidden',
}

const mapStateToProps = state => ({
  editWindowOpen: selectors.getEditWindowOpen(state),
  detailWindowOpen: selectors.getDetailWindowOpen(state),
  currentLocation: selectors.getCurrentLocation(state),
  toast: selectors.getToastMessage(state),
  agencyName: selectors.getAgencyName(state),
  demoMode: selectors.getIsDemoMode(state),
  geometryEditingWindowOpen: selectors.getGeometryEditingWindowOpen(state),
  showDynamicLayers: selectors.getShowDynamicLayers(state),
  featureSetGuids: selectors.getFeatureSetGuids(state),
  loadingApp: selectors.getLoadingApp(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

type Props = {
  editWindowOpen: boolean,
  detailWindowOpen: boolean,
  geometryEditingWindowOpen: boolean,
  actions: Object, // all actions (might want to change this later)
  toast: ?string,
  agencyName: string,
  demoMode: boolean,
  showDynamicLayers: boolean,
  featureSetGuids: Array<string>,
  loadingApp: boolean,
}

const IvEditorApp = (props: Props) => {
  const {
    actions,
    demoMode,
    agencyName,
    detailWindowOpen,
    geometryEditingWindowOpen,
    editWindowOpen,
    showDynamicLayers,
    toast,
    featureSetGuids,
  } = props

  if (props.loadingApp) {
    return <Spinner />
  }

  return (
    <div style={divStyle}>
      {/* second check is for the brief moment after signing in while the agencyName is still or_demo_fd */}
      {!demoMode &&
        agencyName !== 'or_demo_fd' &&
        featureSetGuids.map(guid => (
          <FirestoreConnector
            key={`featureSet-${guid}`}
            query={firestore.collection('featureEdits').where('featureSetGuid', '==', guid)}
            onChange={actions.featureEditsUpdated}
            onDocumentRemoved={actions.featureEditsRemoved}
          />
        ))}

      {demoMode || agencyName === 'or_demo_fd' ? (
        <LoginPage />
      ) : (
        <>
          <TopNavbar />
          {geometryEditingWindowOpen ? <GeometryEditWindow /> : null}
          <div style={leftToRightContainerStyle}>
            {editWindowOpen ? <FeatureEditWindow /> : null}
            {detailWindowOpen ? <FeatureDetailWindow /> : null}
            <div style={{ flexGrow: 2, alignItems: 'stretch', display: 'flex' }}>
              {showDynamicLayers ? <IvReactMapboxGlMap /> : <Spinner size="large" />}
            </div>
          </div>
        </>
      )}
      <div style={{ messageStyle }}>
        <ModalBase />
        <AlertBase />
      </div>
      <Toast message={toast} />
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IvEditorApp)
