/* @flow */
import React from 'react'
import ReactDOM from 'react-dom'
import Form from 'react-jsonschema-form'
import { connect } from 'react-redux'
import * as selectors from '../../common/selectors'
import { View, ScrollView } from 'react-native-web'
import { bindActionCreators } from 'redux'
import { IVFilePicker } from '../components/IVFilePicker'
import { DETAIL_WINDOW_WIDTH } from '../containers/FeatureDetailWindow'
import EditPanel from '../components/EditPanel'
import {
  openModalComponent,
  popModal,
  openFileViewerModal,
  closeFileViewerModal,
} from '../../common/actions/ModalActions'
import { submitFeatureAttributeEdits } from '../../common/actions/FeatureActions'
import { PageHeader, Alert } from 'react-bootstrap'

const styles = {
  alignRight: {
    paddingVertical: 3,
    justifyContent: 'flex-end',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
    paddingBottom: 4,
  },
  menuImage: {
    width: 40,
    height: 40,
    marginLeft: 4,
  },
  switch: {
    width: 40,
    height: 40,
  },
  bodyStyle: {
    width: DETAIL_WINDOW_WIDTH,
  },
  scrollViewStyle: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    paddingBottom: '2rem',
  },
  titleText: {
    paddingVertical: 3,
    flex: 1,
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: 24,
  },
  topBar: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowTop: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const removeEmpty = obj => {
  Object.entries(obj).forEach(([key, val]) => {
    if (Array.isArray(val)) {
      if (val.length === 0) {
        delete obj[key]
      }
    } else if (val && typeof val === 'object') {
      removeEmpty(val)
    } else if (val == null) {
      delete obj[key]
    }
  })
  return obj
}

const checkFeatureSchema = featureSchema => {
  // corvallis occupany data's uiSchema does not work with react-jsonschema-form v1.6.0: error: No widget "readonly" for type "string"
  // 'readyOnly' option is now a key for the field in properties. 'ui:widget' determines the form field input type.
  // this function check can be removed once corvallis occupancy schema is updated
  // (I am guessing the old schema is in use by the 'old' inspection/editor app for corvallis_fd)
  const notAllowedUiWidgets = ['readonly', 'disabled']
  let newSchema = JSON.parse(JSON.stringify(featureSchema))
  for (const [key, value] of Object.entries(featureSchema)) {
    if (key === 'uiSchema') {
      for (const [uiKey, uiValue] of Object.entries(value)) {
        const widgetType = uiValue['ui:widget']
        if (notAllowedUiWidgets.includes(widgetType)) {
          if (widgetType === 'readonly') {
            // add 'readonly' to properties
            newSchema['properties'][uiKey]['readOnly'] = true
            // remove 'readonly' from ui:widget
            delete newSchema[key][uiKey]['ui:widget']
          } else if (widgetType === 'disabled') {
            // add 'disabled' to properties
            newSchema['properties'][uiKey]['disabled'] = true
            // remove 'disabled' from ui:widget
            delete newSchema[key][uiKey]['ui:widget']
          }
        }
      }
    }
  }
  return newSchema
}

const convertBoolean = (formData, schema) => {
  let booleanFields = []
  for (const [key, value] of Object.entries(schema.properties)) {
    if (value.type === 'boolean') {
      booleanFields.push(key)
    }
  }
  for (const [key, value] of Object.entries(formData)) {
    if (booleanFields.includes(key)) {
      if (typeof value !== typeof true || typeof value !== typeof false) {
        if (value === 1) {
          formData[key] = true
        } else {
          formData[key] = false
        }
      }
    }
  }
  return formData
}

function validate(formData, errors) {
  return errors
}

const mapStateToProps = state => {
  return {
    currentFeatureObject: selectors.getCurrentFeature(state),
    featureSchema: selectors.getCurrentFeatureSchema(state),
    activeDataSourceName: selectors.getActiveDataSourceName(state),
    detailWindowOpen: selectors.getDetailWindowOpen(state),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openFileViewerModal,
      closeFileViewerModal,
      popModal,
      openModalComponent,
      submitFeatureAttributeEdits,
    },
    dispatch
  )

type Props = {
  currentFeatureObject: Function,
  featureSchema: ?Object,
  activeDataSourceName: ?string,
  detailWindowOpen: boolean,
  popModal: Function,
  openModalComponent: Function,
  submitFeatureAttributeEdits: Function,
  openFileViewerModal: Function,
  closeFileViewerModal: Function,
}

class FeatureEditWindow extends React.Component<Props> {
  submitButton: null | HTMLButtonElement
  onSubmit = ({ formData }) => {
    // Remove empty attributes, because firebase doesn't like null or undefined
    formData = removeEmpty(formData)
    this.props.submitFeatureAttributeEdits(formData)
  }

  submitForm() {
    this.submitButton && this.submitButton.click()
  }

  scrollToTop() {
    const formDom = ReactDOM.findDOMNode(this.form).firstChild
    formDom.scrollIntoView({ behavior: 'smooth' })
  }

  render = () => {
    const { currentFeatureObject, featureSchema } = this.props
    let formData
    let uiSchema
    let formFeatureSchema
    if (featureSchema) {
      formFeatureSchema = featureSchema.uiSchema ? checkFeatureSchema(featureSchema) : featureSchema
      uiSchema = formFeatureSchema.uiSchema || {}

      // Use special component for attachment properties
      const { attachmentPropertyNames } = featureSchema
      if (attachmentPropertyNames && attachmentPropertyNames.length) {
        for (const propertyName of attachmentPropertyNames) {
          uiSchema[propertyName] = { ...uiSchema[propertyName], 'ui:field': IVFilePicker }
        }
      }

      if (currentFeatureObject) {
        formData = currentFeatureObject.properties
        formData = convertBoolean(formData, featureSchema)
      }
    }
    return (
      <View style={styles.bodyStyle}>
        <EditPanel submitForm={this.submitForm.bind(this)} />
        <ScrollView style={styles.scrollViewStyle}>
          <PageHeader style={{ textAlign: 'center' }}>Edit Feature</PageHeader>
          {uiSchema.hasOwnProperty('attachments') ? (
            <Alert>
              Warning: New attachments saved using this editor will only be available to download on IncidentView core
              version 1.3.0 or higher. Preplan attachments saved using the old editor will continue to work on old
              IncidentView clients.
            </Alert>
          ) : null}
          <View>
            <Form
              ref={form => {
                this.form = form
              }}
              formContext={{
                popModal: this.props.popModal,
                openModalComponent: this.props.openModalComponent,
                openFileViewerModal: this.props.openFileViewerModal,
                closeFileViewerModal: this.props.closeFileViewerModal,
                removeList: this.removeList,
              }}
              uiSchema={uiSchema}
              formData={formData}
              schema={formFeatureSchema}
              validate={validate}
              // onChange={c => console.log('Change in FeatureEditWindow:', c)}
              onSubmit={this.onSubmit}
              onError={() => this.scrollToTop()}
            >
              {this.props.detailWindowOpen ? <div /> : ''}
              <button
                ref={btn => {
                  this.submitButton = btn
                }}
                className="hidden"
              />
            </Form>
          </View>
        </ScrollView>
      </View>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeatureEditWindow)
