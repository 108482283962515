/* @flow */
import { makeActionCreator } from './utils'
import * as types from '../constants/ActionTypes'

export const authenticated = authData => ({
  type: types.AUTHENTICATED,
  authData: {
    email: authData.email,
    uid: authData.uid,
  },
})
export const idTokenChanged = (authData, token) => ({
  type: types.ID_TOKEN_CHANGED,
  authData: {
    email: authData.email,
    uid: authData.uid,
  },
  token,
})
export const unauthenticated = makeActionCreator(types.UNAUTHENTICATED)
export const signInRequest = makeActionCreator(types.SIGN_IN_REQUEST, 'email', 'password')
export const signInSuccess = makeActionCreator(types.SIGN_IN_SUCCESS, 'authData')
export const signInFailure = makeActionCreator(types.SIGN_IN_FAILURE, 'error')
export const signOutRequest = makeActionCreator(types.SIGN_OUT_REQUEST)
export const signOut = makeActionCreator(types.SIGN_OUT)
export const getUsersAgencyConfigSuccess = makeActionCreator(
  types.GET_USERS_AGENCY_CONFIG_SUCCESS,
  'agencyConfig',
  'agencyName'
)

type Results = {
  user: Object,
  agency: Object,
  commonConfig: Object,
  agencyConfig: Object,
  gisDataSources: Object,
}
export const registrationFlowSuccess = (results: Results) => ({
  type: types.REGISTRATION_FLOW_SUCCESS,
  ...results,
})

export const registrationFlowFailure = makeActionCreator(types.REGISTRATION_FLOW_FAILURE, 'error')
export const setLicenseState = makeActionCreator(types.SET_LICENSE_STATE, 'licenseState')
export const loadingApp = makeActionCreator(types.LOADING_APP, 'loadingApp')
