/* @flow */
import React from 'react'
import Modal from './SimpleModal'
import { connect } from 'react-redux'
import { Glyphicon, Button, ButtonToolbar } from 'react-bootstrap'
import { Text, View } from 'react-native-web'
import { EXPORT_DATA_TEXT } from '../../common/constants/ExportDataText'
import { refAgencyConfigs } from '../../common/firebase'

const exportText = {
  fontSize: 22,
  justifyContent: 'center',
}

const exportButton = {
  alignSelf: 'stretch',
  flexDirection: 'row',
  marginTop: 6,
  marginRight: 16,
  paddingTop: 6,
  paddingBottom: 6,
  paddingLeft: 11,
  paddingRight: 11,
}

const mapStateToProps = state => {
  return {
    agencyName: state.auth.user.agencyName,
    userEmail: state.auth.user.email,
  }
}

type Props = {
  interpolatedStyles: Object,
  closeExportData: Function,
  agencyName: string,
  userEmail: string,
}

const writeExportRequestToFirebase = async (name, email) => {
  try {
    const data = {
      timeStamp: new Date().toISOString(),
      userEmail: email,
    }
    await refAgencyConfigs.child(`${name}/dataExportRequest`).set(data)
  } catch (error) {
    window.alert(`ERROR: ${error.message}`)
    throw error
  }
  window.alert('Data Export Request sent!')
}

// will need to change size if text is not large enough
class ExportDataModal extends React.Component<Props> {
  render = () => {
    return (
      <Modal
        title="Export Data Request"
        closeModal={this.props.closeExportData}
        interpolatedStyles={this.props.interpolatedStyles}
      >
        <div style={{ overflowY: 'scroll', fontSize: 18 }}>
          {EXPORT_DATA_TEXT.split('\n').map((paragraph, i) => (
            <p key={i}>{paragraph}</p>
          ))}
          <div>
            <View>
              <ButtonToolbar>
                <Button
                  type="submit"
                  style={exportButton}
                  onClick={() => writeExportRequestToFirebase(this.props.agencyName, this.props.userEmail)}
                >
                  <Glyphicon
                    glyph="export"
                    style={{
                      justifyContent: 'center',
                      fontSize: 22,
                      marginLeft: 4,
                      marginRight: 4,
                      marginTop: 2,
                    }}
                  />
                  <Text style={exportText}>&nbsp;&nbsp;CLICK TO SEND REQUEST</Text>
                </Button>
              </ButtonToolbar>
            </View>
          </div>
        </div>
      </Modal>
    )
  }
}

export default connect(mapStateToProps)(ExportDataModal)
