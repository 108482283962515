/* @flow */
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import Spinner from '../components/Spinner'
import { Carousel, Navbar, Button } from 'react-bootstrap'
import { setToastMessage } from '../../common/actions/ToastActions'
// import * as Actions from '../../common/actions'
import * as selectors from '../../common/selectors'
import { NAVBAR_BACKGROUND_COLOR } from '../styles'
import { openAccountSignInModal } from '../../common/actions/ModalActions'
// import Toast from '../components/Toast'

const divStyle = {
  width: '100%',
  height: '100%',
  position: 'relative',
  zIndex: '0',
  overflowY: 'scroll',
  overflowX: 'hidden',
}

const carouselImage = {
  height: '200px',
}

const carouselCaption = {
  color: 'black',
}

const mapStateToProps = state => ({
  toast: selectors.getToastMessage(state),
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ openAccountSignInModal, setToastMessage }, dispatch),
  }
}

type Props = {
  toast: ?string,
  signInDisabled: boolean,
  setToastMessage: Function,
  openAccountSignInModal: Function, // might want custom one..... to not have to log out or_demo before loging in.
}
// TODO: while setting up offline editing, finish setting up demo mode editing
// Then offer 'demo' button on login page as another option to logging in.

const LoginPage = (props: Props) => {
  const { signInDisabled, openAccountSignInModal, setToastMessage } = props

  const handleLogin = () => {
    if (signInDisabled) {
      setToastMessage('An internet connection is required to sign in', 2000)
    } else {
      openAccountSignInModal()
    }
  }
  // TODO: remove BETA on release
  return (
    <div style={divStyle}>
      <Navbar
        style={{
          backgroundColor: NAVBAR_BACKGROUND_COLOR,
          marginBottom: 0,
        }}
      >
        <img alt="IV Editor logo" src={require('../../assets/images/IV_Editor_logo_long.png')} /> BETA
        <Button style={{ float: 'right', margin: '10px' }} onClick={() => handleLogin()}>
          login
        </Button>{' '}
      </Navbar>
      <div
        style={{
          backgroundImage: 'url(https://editor.incidentview.com/static/images/6.jpg',
          marginTop: 0,
        }}
      >
        <div className="row">
          <div className="col-md-6" style={{ width: '50%', textAlign: 'center', padding: '35px' }}>
            <h1 style={{ color: 'white', fontWeight: 'bold', fontSize: '40px', marginBottom: '30px' }}>
              Use IncidentView Editor to update your fire infrastructure
            </h1>
            <p style={{ color: 'white', fontSize: '20px' }}>
              No more waiting for your GIS department to make updates. Update with a web browser and push changes to all
              your IncidentView devices.
            </p>
            <p style={{ color: 'white', fontSize: '20px' }}>
              {' '}
              Check out{' '}
              <a style={{ color: '#5bc0de' }} href="http://incidentview.com">
                IncidentView.com
              </a>{' '}
              to learn more.{' '}
            </p>
          </div>
          <div className="col-md-6">
            <img
              style={{ maxHeight: '400px', padding: '20px' }}
              src={require('../../assets/images/web_app_imac_tablet.png')}
              className="img-responsive"
              alt="computer"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <Carousel style={{ backgroundColor: '#eeeecc' }}>
          <Carousel.Item>
            <img alt="Attach Preplan" style={carouselImage} src={require('../../assets/images/Hyperlink.png')} />
            <Carousel.Caption style={carouselCaption}>
              <h3>Attach Preplan Documents</h3>
              <p>Drag and drop to attach existing preplan pdfs and image files to map features.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img alt="Fix Update" style={carouselImage} src={require('../../assets/images/Infrustructure.png')} />
            <Carousel.Caption style={carouselCaption}>
              <h3>Fix and Update</h3>
              <p>
                IncidentView Editor makes fire infrastructure updates easy. Use a web browser to add, move, delete and
                update infrastructure attributes for hydrants, control panels, gates, bridges, key boxes, and more.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img alt="Data Updates" style={carouselImage} src={require('../../assets/images/Sync_Icon.png')} />
            <Carousel.Caption style={carouselCaption}>
              <h3>Data Updates</h3>
              <p>Your approved updates are made available to all your devices running IncidentView.</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      <h5 style={{ backgroundColor: '#eeeecc', margin: '0' }}>© Alsea Geospatial, Inc. 2019</h5>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage)
