import * as turf from '@turf/turf'
import { MAPBOX_ACCESS_TOKEN } from '../constants'
import { GEOCODE } from '../constants/SearchResultTypes'

const createQueryString = data => {
  return Object.keys(data)
    .map(function(key) {
      return [key, data[key]].map(encodeURIComponent).join('=')
    })
    .join('&')
}

export const stripQuery = query => query.replace(/ ?#\S+/, '')

// replace some common abbreviations for which Mapbox expects a certain format
const abbreviationMap = {
  AL: 'ALY',
  AV: 'AVE',
  BV: 'BLVD',
  CR: 'CIR',
  LP: 'LOOP',
  PKY: 'PKWY',
}
const abbreviationRegExp = new RegExp(`( )(${Object.keys(abbreviationMap).join('|')})([ ,]|$)`, 'gi')
const replaceAbbreviationsInQuery = query =>
  query.replace(abbreviationRegExp, (matched, p1, p2, p3) => {
    return `${p1}${abbreviationMap[p2.toUpperCase()]}${p3}`
  })

export const preprocessQuery = query => replaceAbbreviationsInQuery(stripQuery(query))

export const geocodeUsingMapbox = (searchQuery, currentLocation) => {
  const encodedSearchQuery = encodeURIComponent(preprocessQuery(searchQuery))
  const dataset = 'mapbox.places'
  let queryParams = {
    access_token: MAPBOX_ACCESS_TOKEN,
    country: 'us',
    types: 'address,poi',
  }
  console.log('geocode params', { currentLocation: currentLocation })

  if (currentLocation) {
    // Limit to 3 decimal places, as recommended in the Mapbox docs
    queryParams.proximity = `${currentLocation.lng.toFixed(3)},${currentLocation.lat.toFixed(3)}`
  }
  const queryString = createQueryString(queryParams)

  const url = `https://api.mapbox.com/geocoding/v5/${dataset}/${encodedSearchQuery}.json?${queryString}`
  return fetch(url).then(data => data.json())
}

export const getResultsArray = (responseJson, currentLocation) => {
  const features = responseJson.features
  const firstResultRelevance = features[0] ? features[0].relevance : 1

  let searchResults = []
  for (const feature of features) {
    const { center, place_name, relevance } = feature
    if (relevance < 0.75 * firstResultRelevance) {
      continue
    }
    if (currentLocation) {
      // if we have current location data for the user, filter out any results >200mi away
      const currentLocationPoint = turf.point([currentLocation.lng, currentLocation.lat])
      const searchResultPoint = turf.point([center[0], center[1]])
      if (turf.distance(currentLocationPoint, searchResultPoint, { units: 'miles' }) > 200) {
        continue
      }
    }
    searchResults.push({
      displayText: place_name,
      lng: center[0],
      lat: center[1],
      type: GEOCODE,
      relevance,
    })
  }

  return searchResults
}
