import * as Immutable from 'immutable'
import * as types from '../constants/ActionTypes'
import { createReducerFromHandlers } from './utils'
import * as connectionTypes from '../constants/ConnectionTypes'
import { REHYDRATE } from 'redux-persist/constants'

export const ConnectivityState = Immutable.Record({
  // eslint-disable-line new-cap
  isNetworkConnected: false,
  networkConnectionType: connectionTypes.CONNECTION_TYPE_UNKNOWN,
  wifiOnly: true,
  hasGpsSignal: false,
})

export const initialState = new ConnectivityState()

const reactNativeConnectionTypeToIvConnectionType = {
  none: connectionTypes.CONNECTION_TYPE_NONE,
  wifi: connectionTypes.CONNECTION_TYPE_WIFI,
  cell: connectionTypes.CONNECTION_TYPE_CELL,
  unknown: connectionTypes.CONNECTION_TYPE_UNKNOWN,
  NONE: connectionTypes.CONNECTION_TYPE_NONE,
  BLUETOOTH: connectionTypes.CONNECTION_TYPE_WIFI,
  DUMMY: connectionTypes.CONNECTION_TYPE_WIFI,
  ETHERNET: connectionTypes.CONNECTION_TYPE_WIFI,
  MOBILE: connectionTypes.CONNECTION_TYPE_CELL,
  MOBILE_DUN: connectionTypes.CONNECTION_TYPE_CELL,
  MOBILE_HIPRI: connectionTypes.CONNECTION_TYPE_CELL,
  MOBILE_MMS: connectionTypes.CONNECTION_TYPE_CELL,
  MOBILE_SUPL: connectionTypes.CONNECTION_TYPE_CELL,
  VPN: connectionTypes.CONNECTION_TYPE_WIFI,
  WIFI: connectionTypes.CONNECTION_TYPE_WIFI,
  WIMAX: connectionTypes.CONNECTION_TYPE_CELL,
  UNKNOWN: connectionTypes.CONNECTION_TYPE_UNKNOWN,
  'N/A': connectionTypes.CONNECTION_TYPE_WIFI,
}

const getIvConnectionType = type => {
  return reactNativeConnectionTypeToIvConnectionType[type] || connectionTypes.CONNECTION_TYPE_UNKNOWN
}

const handlers = {
  [types.GAIN_NETWORK_CONNECTION]: state => {
    return state.set('isNetworkConnected', true)
  },
  [types.LOST_NETWORK_CONNECTION]: state => {
    return state.set('isNetworkConnected', false)
  },
  [types.SET_CONNECTION_TYPE]: (state, { connectionType }) => {
    return state.set('networkConnectionType', getIvConnectionType(connectionType))
  },
  [types.SET_WIFI_ONLY]: (state, action) => state.set('wifiOnly', !state.wifiOnly),
  [types.LOST_GPS_SIGNAL]: state => state.set('hasGpsSignal', false),
  [types.SET_CURRENT_LOCATION]: state => state.set('hasGpsSignal', true),

  [REHYDRATE]: (state, action) => {
    const previousState = action.payload.connectivity
    let newState = state
    if (previousState && previousState.wifiOnly !== undefined) {
      newState = newState.set('wifiOnly', previousState.wifiOnly)
    }
    return newState
  },
}

export default createReducerFromHandlers(initialState, handlers)

// selectors moved to ../selectors dir
