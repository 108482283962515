/* @flow */
import { makeActionCreator } from './utils'
import * as types from '../constants/ActionTypes'
export const inputLaidOut = makeActionCreator(types.INPUT_LAID_OUT, 'layout', 'name', 'index')
export const scrollToError = makeActionCreator(types.SCROLL_TO_ERROR, 'error')
export const scrolledToError = makeActionCreator(types.SCROLLED_TO_ERROR)
export const onMediaSelectionChanged = makeActionCreator(
  types.ON_MEDIA_SELECTION_CHANGED,
  'media',
  'index',
  'isSelected'
)
export const deleteSelectedImages = makeActionCreator(types.DELETE_SELECTED_IMAGES)
