/* @flow */
import { makeActionCreator } from './utils'
import * as types from '../constants/ActionTypes'

export const activateEditTool = makeActionCreator(types.ACTIVATE_EDIT_TOOL)
export const startAddNewFeature = makeActionCreator(types.START_ADD_NEW_FEATURE)
export const openEditWindow = makeActionCreator(types.OPEN_EDIT_WINDOW)

export const featureUnselected = makeActionCreator(types.FEATURE_UNSELECTED)

export const openDetailWindow = makeActionCreator(types.OPEN_DETAIL_WINDOW)
export const closeDetailWindow = makeActionCreator(types.CLOSE_DETAIL_WINDOW)
export const openGeometryEditingWindow = makeActionCreator(types.OPEN_GEOMETRY_EDITING_WINDOW)

export const submitFeatureGeometryEdits = makeActionCreator(types.SUBMIT_FEATURE_GEOMETRY_EDITS)
export const clearFeatureGeometry = makeActionCreator(types.CLEAR_FEATURE_GEOMETRY)
export const undoFeatureGeometryVertex = makeActionCreator(types.UNDO_FEATURE_GEOMETRY_VERTEX)
export const cancelFeatureGeometryEdits = makeActionCreator(types.CANCEL_FEATURE_GEOMETRY_EDITS)
export const addEditPointLocation = makeActionCreator(
  types.ADD_EDIT_POINT_LOCATION,
  'lat',
  'lng',
  'geometryType',
  'layer'
)
export const mapDrawUpdate = makeActionCreator(types.MAP_DRAW_UPDATE, 'feature')
export const mapDrawUpdateMulti = makeActionCreator(types.MAP_DRAW_UPDATE_MULTI, 'feature')
export const setUndoStatus = makeActionCreator(types.SET_UNDO_STATUS)
export const toggleLineLengthEdit = makeActionCreator(types.TOGGLE_LINE_LENGTH_EDIT)
export const addToPolygon = makeActionCreator(types.ADD_TO_POLYGON)
export const removeFromPolygon = makeActionCreator(types.REMOVE_FROM_POLYGON)
export const clearCurrentFeatureObject = makeActionCreator(types.CLEAR_CURRENT_FEATURE_OBJECT)
export const fetchFeatureInfoFailure = makeActionCreator(types.FETCH_FEATURE_INFO_FAILURE, 'errorMessage')

export const featureSelected = ({ feature, dataSourceName }) => ({
  type: types.FEATURE_SELECTED,
  feature,
  dataSourceName,
})
// This is for fetching additional feature info that isn't included in vector tiles
export const fetchFeatureInfoSuccess = ({
  geometry,
  properties,
  featureGuid,
  featureSetGuid,
  modifiedDate,
  modifiedUserEmail,
  originId,
  version,
  persistedDate,
}) => ({
  type: types.FETCH_FEATURE_INFO_SUCCESS,
  geometry,
  properties,
  featureGuid,
  featureSetGuid,
  modifiedDate,
  modifiedUserEmail,
  originId,
  version,
  persistedDate,
})
