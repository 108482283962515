/* @flow */
import { REHYDRATE } from 'redux-persist/constants'
import * as types from '../constants/ActionTypes'
import { createImmerReducerFromHandlers } from './utils'

type FeatureEditsState = {
  byId: {},
  idsByFeatureSetGuid: {},
}

export const initialState: FeatureEditsState = {
  byId: {},
  idsByFeatureSetGuid: {},
}

const handlers = {
  [REHYDRATE]: (draft: FeatureEditsState, action) => {
    const previousState = action.payload.featureEdits
    if (previousState && previousState.idsByFeatureSetGuid) {
      draft.idsByFeatureSetGuid = previousState.idsByFeatureSetGuid
    }
    if (previousState && previousState.byId) {
      draft.byId = previousState.byId
    }
  },
  [types.FEATURE_EDITS_UPDATED]: (draft: FeatureEditsState, action) => {
    if (!action.features) {
      return
    }
    Object.entries(action.features).forEach(([featureGuid, docData]) => {
      const { featureSetGuid } = docData
      if (!draft.idsByFeatureSetGuid[featureSetGuid]) {
        draft.idsByFeatureSetGuid[featureSetGuid] = []
      }
      if (!draft.idsByFeatureSetGuid[featureSetGuid].includes(featureGuid)) {
        draft.idsByFeatureSetGuid[featureSetGuid].push(featureGuid)
      }

      draft.byId[featureGuid] = { ...docData, feature: docData.deleteDate ? null : JSON.parse(docData.feature) }
    })
  },
  [types.FEATURE_EDITS_REMOVED]: (draft: FeatureEditsState, action) => {
    for (const featureGuid of action.featureGuids) {
      const featureEdit = draft.byId[featureGuid]
      const { featureSetGuid } = featureEdit
      draft.idsByFeatureSetGuid[featureSetGuid] = draft.idsByFeatureSetGuid[featureSetGuid].filter(
        v => v !== featureGuid
      )
      if (!draft.idsByFeatureSetGuid[featureSetGuid].length) {
        delete draft.idsByFeatureSetGuid[featureSetGuid]
      }
      delete draft.byId[featureGuid]
    }
  },
  [types.SIGN_IN_SUCCESS]: () => initialState,
}

export default createImmerReducerFromHandlers(initialState, handlers)
