/* @flow */
// the AccountModal, or SignInModal, depending on whether user is signed in
export const MODAL_ACCOUNT_OR_SIGN_IN = 'MODAL_ACCOUNT_OR_SIGN_IN'
export const MODAL_ABOUT = 'MODAL_ABOUT'
export const LAYER_CONFIG = 'LAYER_CONFIG'
export const MODAL_LICENSE_AGREEMENT = 'MODAL_LICENSE_AGREEMENT'
export const MODAL_EXPORT_DATA = 'MODAL_EXPORT_DATA'
export const MODAL_ADD_POINT_LOCATION = 'MODAL_ADD_POINT_LOCATION'
export const MODAL_USERS_GUIDE = 'MODAL_USERS_GUIDE'
export const MODAL_SETTING = 'MODAL_SETTING'
export const MODAL_DATA = 'MODAL_DATA'
export const MODAL_PREPLAN = 'MODAL_PREPLAN'
export const MODAL_FEATURE_SELECT = 'MODAL_FEATURE_SELECT'
export const MODAL_FILE_VIEWER = 'MODAL_FILE_VIEWER'
