/* @flow */
import { createSelector } from 'reselect'
import LatLng from '../records/LatLng'
import MapboxStyle from '../records/MapboxStyle'
import { List, Record, Map } from 'immutable'
import { getAgencyName, getConfig } from './auth'
import { firebaseURL } from '../firebase'
import { createMapStyleUrl, DEFAULT_ZOOM_DEPTH } from '../constants'

export const getCachedRegion = (state: Object): LatLng => state.map.cachedRegion

export const getCurrentLocation = (state: Object): LatLng => state.map.currentLocation

export const getPreviousLocation = (state: Object): LatLng => state.map.previousLocation
export const getIvLayers = (state: Object): List<Object> => state.map.ivLayers
export const getCurrentBounds = (state: Object): Record => state.map.currentBounds
export const getMapComponent = (state: Object): string => state.map.component
export const getMapboxStyles = (state: Object): MapboxStyle => state.map.mapboxStyles

export const getGisDataSourceToggleMap = createSelector(
  state => state.map.gisDataSourceToggleMap,
  currentGisDataSourceToggleMap => {
    return currentGisDataSourceToggleMap
  }
)

export const getTargetLocation = (state: Object): LatLng => state.map.targetLocation
export const getMapType = (state: Object): string => state.map && state.map.baseMapType
export const getFontSizeAdjustments = (state: Object): number => state.map.fontSizeAdjustments
export const getMapStyleLoaded = (state: Object): boolean => state.map.mapStyleLoaded
export const getShowDynamicLayers = (state: Object): boolean => state.map.showDynamicLayers

export const getLocationEnabled = (state: Object): boolean =>
  state.map.locationProvider && state.map.locationProvider.enabled
export const getGpsEnabled = (state: Object): boolean => state.map.locationProvider && state.map.locationProvider.gps
export const getShouldTrackUserLocation = (state: Object): LatLng => state.map.trackUserLocation

export const getBaseMapType = (state: Object): Map => state.map.baseMapType

export const getZoomToLocation = (state: Object): Map => state.map.zoomToLocation

export const getZoomToBounds = (state: Object): Map => state.map.zoomToBounds

const getMapboxStylesAvailable = (state: Object): Array<Object> =>
  (getMapboxStyles(state) &&
    getMapboxStyles(state)
      .keySeq()
      .toArray()) ||
  []

export const getVisibleLayerNames: (state: Object) => Array<string> = createSelector(
  getGisDataSourceToggleMap,
  toggleMap => {
    let visibleLayerMap = toggleMap.filter((v, k) => {
      // if visible:
      return v
    })

    let rVal = visibleLayerMap.keySeq().toJS()
    return rVal
  }
)

export const getMapboxStyleUrl: (state: Object) => string | void = createSelector(
  getMapType,
  getAgencyName,
  getMapboxStylesAvailable,
  getMapType,
  getAgencyName,
  getMapboxStylesAvailable,
  (mapType, agencyName, mapboxStyleNames) => {
    if (mapboxStyleNames.includes(mapType)) {
      // TODO: fetching this should use Firebase auth - waiting on this: https://github.com/react-native-mapbox-gl/maps/issues/504
      // For now server allows anyone to read style
      return createMapStyleUrl({ mapType, agencyName })
    }
    // using undefined for URL loads default Streets mapbox style (without demo data, etc)
    return undefined
  }
)

export const getCenterCoordinate: (state: Object) => Object | void = createSelector(
  getCachedRegion,
  getConfig,
  (cachedRegion, config) => {
    let rVal = null
    if (cachedRegion) {
      rVal = {
        latitude: cachedRegion.latitude,
        longitude: cachedRegion.longitude,
      }
    } else if (config) {
      rVal = {
        latitude: config.get('initialLatLng').get('lat'),
        longitude: config.get('initialLatLng').get('lng'),
      }
    }
    return rVal
  }
)

export const getZoomLevel: (state: Object) => Object | void = createSelector(
  getCachedRegion,
  getConfig,
  (cachedRegion, config) => {
    let rVal = null
    if (cachedRegion) {
      rVal = cachedRegion.zoomLevel
    } else if (config && config.has('zoomLevel')) {
      rVal = config.get('zoomLevel')
    } else {
      rVal = DEFAULT_ZOOM_DEPTH
    }
    return rVal
  }
)

// used for offline tiles download
export const getMapboxStyleUrlStreet: (state: Object) => string | void = createSelector(
  getAgencyName,
  getMapboxStylesAvailable,
  (agencyName, mapboxStyleNames) => {
    if (mapboxStyleNames.includes('streets')) {
      // for now, use the map with infrastructure data
      // if they've cached the no-infrastructure version it should still be usable
      return `${firebaseURL}/mapboxBaseStyles/${agencyName}/streets.json`
    }
    // using undefined for URL loads default Streets mapbox style (without demo data, etc)
    return undefined
  }
)
