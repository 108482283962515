/* @flow */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setActiveDataSource, clearCurrentFeatureObject, setToggleMapLayer, createAlert } from '../../common/actions'
import { MenuItem, SplitButton, Tooltip, OverlayTrigger } from 'react-bootstrap'
import * as selectors from '../../common/selectors'
import { startAddNewFeature } from '../../common/actions/EditorActions'
import * as alertTypes from '../../common/constants/AlertTypes'
import { NAVBAR_BACKGROUND_COLOR, GLYPH_COLOR } from '../styles'
import './InfrastructureIconsMenu.css'

const buttonStyle = {
  backgroundPosition: 'left',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  textIndent: '45px',
  paddingTop: '1px',
  paddingBottom: '1px',
  float: 'left',
}
// const menuItemStyle = {
//   backgroundRepeat: 'no-repeat',
//   backgroundSize: 'contain',
//   backgroundPosition: 'left',
//   textAlign: 'left',
//    textIndent: '45px',
//   paddingTop: '1px',
//   paddingBottom: '1px',
//   marginTop: '10px',
// }
const imageStyle = {
  height: '26px',
  margin: '10px',
  align: 'center',
}
const dropdownWarningStyle = {
  fontSize: 22,
  overflow: 'hidden',
  whiteSpace: 'noWrap',
  paddingTop: '2px',
  paddingBottom: '2px',
  paddingLeft: '10px',
  paddingRight: '8px',
}

const mapStateToProps = state => {
  return {
    activeDataSourceName: selectors.getActiveDataSourceName(state),
    gisDataSources: selectors.getEditableGisDataSourcesArray(state),
    toggledGisDataSources: selectors.getEditableAndNotVisibleGisDataSourceNames(state),
    onLoadGisDataSources: selectors.getGisDataSourcesArray(state),
    gisToggleMap: selectors.getGisDataSourceToggleMap(state),
    addingNewFeature: selectors.getAddingNewFeature(state),
    detailWindowOpen: selectors.getDetailWindowOpen(state),
    geometryWindowOpen: selectors.getGeometryEditingWindowOpen(state),
    editWindowOpen: selectors.getEditWindowOpen(state),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setActiveDataSource,
      clearCurrentFeatureObject,
      startAddNewFeature,
      setToggleMapLayer,
      createAlert,
    },
    dispatch
  )

type Props = {
  activeDataSourceName: ?string,
  gisDataSources: Array<Object>,
  toggledGisDataSources: Array<string>,
  onLoadGisDataSources: any[],
  gisToggleMap: Object,
  setActiveDataSource: Function,
  clearCurrentFeatureObject: Function,
  detailWindowOpen: boolean,
  geometryWindowOpen: boolean,
  editWindowOpen: boolean,
  addingNewFeature: boolean,
  startAddNewFeature: Function,
  setToggleMapLayer: Function,
  isTestAccountForRealAgency: boolean,
  createAlert: Function,
  agencyName: string,
}

class InfrastructureIconsMenu extends React.PureComponent<Props> {
  render = () => {
    const { activeDataSourceName, gisDataSources, toggledGisDataSources } = this.props
    const activeDataSource = gisDataSources.filter(src => src.dataSourceName === activeDataSourceName)[0]
    const toolTipAdd =
      this.props.activeDataSourceName && activeDataSource ? (
        <Tooltip id="toolTipAdd">Click to start adding a {this.props.activeDataSourceName}</Tooltip>
      ) : (
        <Tooltip id="toolTipAdd">Please select a feature on the right dropdown to activate add mode </Tooltip>
      )
    const activeDataSourceChanged = layer => {
      // Clear the current geometry when changing layers
      if (!this.props.detailWindowOpen && !this.props.geometryWindowOpen && !this.props.editWindowOpen) {
        if (toggledGisDataSources && toggledGisDataSources.includes(layer)) {
          // active dataSource should not be toggled off
          this.props.setToggleMapLayer(layer, true)
          this.props.clearCurrentFeatureObject()
          this.props.setActiveDataSource(layer)
        } else {
          this.props.clearCurrentFeatureObject()
          this.props.setActiveDataSource(layer)
        }
      }
    }
    const addFeature = () => {
      if (!this.props.detailWindowOpen && !this.props.geometryWindowOpen && !this.props.editWindowOpen) {
        if (this.props.activeDataSourceName) {
          if (this.props.isTestAccountForRealAgency) {
            this.props.createAlert(
              alertTypes.IS_AGI_GEOM_EDIT_ALERT,
              ['Yes', 'Cancel'],
              'Warning for AGI user',
              `You are currently logged into '${this.props.agencyName}', do you want to continue to make an edit?`
            )
          } else {
            this.props.startAddNewFeature()
          }
        }
      }
    }
    // TODO: better align menuItems in the dropdown
    return (
      <OverlayTrigger overlay={toolTipAdd} placement="bottom">
        <SplitButton
          id="infrastructureDropdown"
          onClick={activeDataSource ? () => addFeature() : null}
          style={{
            background: this.props.addingNewFeature ? 'rgba(255,255,0,0.4)' : NAVBAR_BACKGROUND_COLOR,
            color: GLYPH_COLOR,
            fontSize: 22,
            border: 0,
          }}
          title={
            activeDataSource ? (
              <div
                style={{
                  ...buttonStyle,
                  backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/incidentview.appspot.com/o/layerIcons%2F${
                    activeDataSource.dataSourceName
                  }.svg?alt=media')`,
                }}
              >
                {`${activeDataSource.displayName || activeDataSource.dataSourceName}`}
              </div>
            ) : (
              'Select Item...'
            )
          }
        >
          {this.props.onLoadGisDataSources.length === 0 ? (
            <div style={dropdownWarningStyle}>Layers are loading...</div>
          ) : null}
          {gisDataSources &&
            gisDataSources.map(source => (
              <MenuItem
                id="customButton"
                className="block"
                key={source.dataSourceName}
                eventKey={source.dataSourceName}
                onSelect={value => {
                  activeDataSourceChanged(value)
                }}
                //  style={{ display: 'flex', alignContent: 'stretch', flexDirection: 'column' }}
                // style={{
                //   ...menuItemStyle,
                //   // backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/incidentview.appspot.com/o/layerIcons%2F${
                //   //   source.dataSourceName
                //   // }.svg?alt=media')`,
                // }}
              >
                <img
                  style={imageStyle}
                  alt=""
                  src={
                    `https://firebasestorage.googleapis.com/v0/b/incidentview.appspot.com/o/layerIcons%2F${
                      source.dataSourceName
                    }.svg?alt=media`
                      ? `https://firebasestorage.googleapis.com/v0/b/incidentview.appspot.com/o/layerIcons%2F${
                          source.dataSourceName
                        }.svg?alt=media`
                      : null
                  }
                />
                {source.displayName || source.dataSourceName}
              </MenuItem>
            ))}
        </SplitButton>
      </OverlayTrigger>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfrastructureIconsMenu)
