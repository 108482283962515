export const DISPATCH_LAT_LNG = 'dispatchLatLng'
export const SEARCH_LAT_LNG = 'searchLatLng'
export const LOCAL_DATA_POINT = 'localDataPoint'
export const LOCAL_ADDRESS_POINT = 'localAddressPoint'
export const INTERSECTION = 'intersection'
export const GEOCODE = 'geocode'

export default {
  [DISPATCH_LAT_LNG]: 'Dispatch',
  [SEARCH_LAT_LNG]: 'Coordinate Location',
  [LOCAL_DATA_POINT]: 'Local Data Point',
  [LOCAL_ADDRESS_POINT]: 'Local Address Point',
  [INTERSECTION]: 'Intersection',
  [GEOCODE]: 'Online Geocode',
}
