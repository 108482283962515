/* @flow */
import * as Immutable from 'immutable'
import { createSearchResultRecordFromObj } from '../records/SearchResult'
import * as types from '../constants/ActionTypes'
import { createReducerFromHandlers } from './utils'

export const SearchBarState = Immutable.Record({
  active: false,
  searchResults: new Immutable.List([]),
  localDataResults: new Immutable.List([]),
  latLngResult: null,
  searchText: '',
  currentSearchResult: null,
  requestingGeocodedResults: false,
  requestingAddressPointResults: false,
})

const initialState = new SearchBarState()

const handlers = {
  [types.SEARCH_TEXT_UPDATED]: (state, action) => {
    let newState = state.set('searchText', action.searchText)
    if (action.searchText === '') {
      newState = newState
        .set('searchResults', new Immutable.List([]))
        .set('localDataResults', new Immutable.List([]))
        .set('currentSearchResult', null)
        .set('latLngResult', null)
    }
    return newState
  },
  [types.GEOCODE_SEARCH_BAR_REQUEST]: (state, action) => {
    return state.set('requestingGeocodedResults', true)
  },
  [types.GEOCODE_SEARCH_BAR_SUCCESS]: (state, action) => {
    const newSearchResults = new Immutable.List(action.results.map(result => createSearchResultRecordFromObj(result)))
    return state
      .set('searchResults', newSearchResults)
      .set('currentSearchResult', null)
      .set('requestingGeocodedResults', false)
      .set('latLngResult', null)
  },
  // [types.GEOCODE_DISPATCH_SUCCESS]: (state, action) => {
  //   const newSearchResults = new Immutable.List(action.searchResults.map(result => createSearchResultRecordFromObj(result)))
  //   return state
  //     .set('searchResults', newSearchResults)
  //     .set('currentSearchResult', null)
  //     .set('requestingGeocodedResults', false)
  //     .set('latLngResult', null)
  // },
  [types.GEOCODE_SEARCH_BAR_FAILURE]: (state, action) => {
    // TODO: not doing anything with action.error yet
    // probably want to watch for it in a saga that reports to Crashlytics
    return state.set('requestingGeocodedResults', false)
  },
  [types.LOCAL_DATA_SEARCH_BAR_SUCCESS]: (state, action) => {
    const newLocalDataSearchResults = new Immutable.List(
      action.results.map(result => createSearchResultRecordFromObj(result))
    )
    return state
      .set('localDataResults', newLocalDataSearchResults)
      .set('currentSearchResult', null)
      .set('latLngResult', null)
  },
  // [types.SET_DISPATCH_LOCAL_DATA_RESULTS]: (state, action) => {
  //   if (!action.localDataResults) return state
  //   const newLocalDataSearchResults = new Immutable.List(
  //     action.localDataResults.map(result => createSearchResultRecordFromObj(result))
  //   )
  //   return state
  //     .set('localDataResults', newLocalDataSearchResults)
  //     .set('currentSearchResult', null)
  //     .set('latLngResult', null)
  // },
  [types.SET_LAT_LNG_RESULT]: (state, action) =>
    state
      .set('latLngResult', createSearchResultRecordFromObj(action.result))
      .set('localDataResults', new Immutable.List([]))
      .set('searchResults', new Immutable.List([]))
      .set('currentSearchResult', null),
  [types.ACTIVATE_SEARCH_BAR]: (state, action) => state.set('active', true),
  [types.DEACTIVATE_SEARCH_BAR]: (state, action) => state.set('active', false),
  [types.USER_CLICKED_MAP]: (state, action) => state.set('active', false),
  [types.SET_TARGET_LOCATION]: (state, action) => state.set('active', false).set('searchText', action.displayText),
  [types.SET_CURRENT_SEARCH_BAR_RESULT]: (state, action) => state.set('currentSearchResult', action.id),

  //types.RESET_AFTER_TUTORIAL]: (state, action) => action.searchBarState,

  [types.SIGN_IN_SUCCESS]: () => initialState,
  [types.SIGN_OUT]: () => initialState,
}

export default createReducerFromHandlers(initialState, handlers)
