import * as Immutable from 'immutable'
import { createReducerFromHandlers } from './utils'
import * as types from '../constants/ActionTypes'

export const FirebaseState = new Immutable.Record({
  serverTimeOffset: 0,
})

const initialState = new FirebaseState()

const handlers = {
  [types.SET_SERVER_TIME_OFFSET]: (state, action) => state.set('serverTimeOffset', action.serverTimeOffset),
}

export default createReducerFromHandlers(initialState, handlers)

// selectors
export const getServerTimeOffset = state => state.firebase.serverTimeOffset
