/* @flow */
// lifecycle actions
export const APP_START = 'APP_START'
export const APP_EXIT = 'APP_EXIT'
export const ANDROID_BACK_PRESSED = 'ANDROID_BACK_PRESSED'
export const LICENSE_ACCEPTED = 'LICENSE_ACCEPTED'
export const APP_STATE_CHANGED = 'APP_STATE_CHANGED'

// alert
export const CREATE_ALERT = 'CREATE_ALERT'
export const DISMISS_ALERT = 'DISMISS_ALERT'

// auth
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE'
export const SIGN_OUT_REQUEST = 'SIGN_OUT_REQUEST'
export const SIGN_OUT = 'SIGN_OUT'
export const ID_TOKEN_CHANGED = 'ID_TOKEN_CHANGED'
export const AUTHENTICATED = 'AUTHENTICATED'
export const UNAUTHENTICATED = 'UNAUTHENTICATED'
export const GET_USERS_AGENCY_CONFIG_SUCCESS = 'GET_USERS_AGENCY_CONFIG_SUCCESS'
export const REGISTRATION_FLOW_SUCCESS = 'REGISTRATION_FLOW_SUCCESS'
export const REGISTRATION_FLOW_FAILURE = 'REGISTRATION_FLOW_FAILURE'
export const SET_LICENSE_STATE = 'SET_LICENSE_STATE'
export const LOADING_APP = 'LOADING_APP'

// connectivity
export const GAIN_NETWORK_CONNECTION = 'GAIN_NETWORK_CONNECTION'
export const LOST_NETWORK_CONNECTION = 'LOST_NETWORK_CONNECTION'
export const SET_CONNECTION_TYPE = 'SET_CONNECTION_TYPE'
export const SET_WIFI_ONLY = 'SET_WIFI_ONLY'
export const LOST_GPS_SIGNAL = 'LOST_GPS_SIGNAL'

// device
export const SET_DEVICE_DISPLAY_NAME_FROM_USER = 'SET_DEVICE_DISPLAY_NAME_FROM_USER'
export const UPDATE_DEVICE = 'UPDATE_DEVICE'
export const SET_GOOGLE_MAPS_INSTALLED = 'SET_GOOGLE_MAPS_INSTALLED'
export const TOGGLE_KEEP_SCREEN_ACTIVE = 'TOGGLE_KEEP_SCREEN_ACTIVE'

// download
export const START_FILE_DOWNLOAD = 'START_FILE_DOWNLOAD'
export const SELECT_DOWNLOAD_OPTION = 'SELECT_DOWNLOAD_OPTION'
export const SET_DOWNLOAD_OPTION_PERCENT = 'SET_DOWNLOAD_OPTION_PERCENT'
export const FILE_DOWNLOAD_PERCENT = 'FILE_DOWNLOAD_PERCENT'
export const FILE_DOWNLOAD_COMPLETE = 'FILE_DOWNLOAD_COMPLETE'
export const FILE_DOWNLOAD_FAILED = 'FILE_DOWNLOAD_FAILED'
export const CHECK_FOR_UPDATES = 'CHECK_FOR_UPDATES'
export const REMOVE_TEMP_FILES = 'REMOVE_TEMP_FILES'
export const SET_FILE_SIZE = 'SET_FILE_SIZE'
export const TOGGLE_UPDATE_AUTOMATICALLY = 'TOGGLE_UPDATE_AUTOMATICALLY'

// feature
export const SAVE_FEATURE_EDIT_REQUEST = 'SAVE_FEATURE_EDIT_REQUEST'
export const SAVE_FEATURE_EDIT_SUCCESS = 'SAVE_FEATURE_EDIT_SUCCESS'
export const SAVE_FEATURE_EDIT_FAILURE = 'SAVE_FEATURE_EDIT_FAILURE'
export const SUBMIT_FEATURE_ATTRIBUTE_EDITS = 'SUBMIT_FEATURE_ATTRIBUTE_EDITS'
export const DELETE_FEATURE = 'DELETE_FEATURE'
export const FIREBASE_UPDATE_COMPLETE = 'FIREBASE_UPDATE_COMPLETE'
export const COORDINATE_SUBMIT = 'COORDINATE_SUBMIT'

export const FEATURE_EDITS_UPDATED = 'FEATURE_EDITS_UPDATED'
export const FEATURE_EDITS_REMOVED = 'FEATURE_EDITS_REMOVED'

// firebase
export const SET_SERVER_TIME_OFFSET = 'SET_SERVER_TIME_OFFSET'

// GisDataSources
export const SET_GIS_DATA_SOURCES = 'SET_GIS_DATA_SOURCES'

// localData
export const LOAD_LOCAL_DATA = 'LOAD_LOCAL_DATA'

// map
export const SET_TARGET_LOCATION = 'SET_TARGET_LOCATION'
export const SET_TOGGLE_MAP_LAYER = 'SET_TOGGLE_MAP_LAYER'
export const SET_TOGGLE_ALL_MAP_LAYERS = 'SET_TOGGLE_ALL_MAP_LAYERS'
export const REFRESH_MAP = 'REFRESH_MAP'

export const LOCATION_PROVIDER_CHANGED = 'LOCATION_PROVIDER_CHANGED'
export const TOGGLE_TRACK_USER_LOCATION = 'TOGGLE_TRACK_USER_LOCATION'
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION'
export const DID_ZOOM_TO_TARGET_LOCATION = 'DID_ZOOM_TO_TARGET_LOCATION'
export const CANT_ZOOM_TO_TARGET_LOCATION = 'CANT_ZOOM_TO_TARGET_LOCATION'
export const ZOOM_TO_LOCATION = 'ZOOM_TO_LOCATION'
export const DID_ZOOM_TO_LOCATION = 'DID_ZOOM_TO_LOCATION'
export const ZOOM_TO_BOUNDS = 'ZOOM_TO_BOUNDS'
export const DID_ZOOM_TO_BOUNDS = 'DID_ZOOM_TO_BOUNDS'
export const DID_CHANGE_BOUNDS = 'DID_CHANGE_BOUNDS'
export const SHOULD_TRACK_CURRENT_BOUNDS = 'SHOULD_TRACK_CURRENT_BOUNDS'
export const SET_CURRENT_BOUNDS = 'SET_CURRENT_BOUNDS'
export const SET_REGION = 'SET_REGION'

export const SET_BASEMAP_TYPE = 'SET_BASEMAP_TYPE'
export const USER_PANNED_MAP = 'USER_PANNED_MAP'
export const TOGGLE_MAP_COMPONENT = 'TOGGLE_MAP_COMPONENT'
export const GET_MAPBOX_STYLES_SUCCESS = 'GET_MAPBOX_STYLES_SUCCESS'
export const ZOOM_IN = 'ZOOM_IN'
export const ZOOM_OUT = 'ZOOM_OUT'
export const CENTER_MAP = 'CENTER_MAP'

export const MAP_STYLE_LOADED = 'MAP_STYLE_LOADED'
export const SHOW_DYNAMIC_LAYERS = 'SHOW_DYNAMIC_LAYERS'

export const TOGGLE_CURRENT_LOCATION_SIMULATOR = 'TOGGLE_CURRENT_LOCATION_SIMULATOR'
export const USER_CLICKED_MAP = 'USER_CLICKED_MAP'
export const INCREASE_FONT_SIZE = 'INCREASE_FONT_SIZE'
export const DECREASE_FONT_SIZE = 'DECREASE_FONT_SIZE'
export const ACTIVATE_EDIT_TOOL = 'ACTIVATE_EDIT_TOOL'
export const START_ADD_NEW_FEATURE = 'START_ADD_NEW_FEATURE'
export const OPEN_EDIT_WINDOW = 'OPEN_EDIT_WINDOW'
export const SET_DETAIL_WINDOW_HAS_OPENED = 'SET_DETAIL_WINDOW_HAS_OPENED'
export const FEATURE_UNSELECTED = 'FEATURE_UNSELECTED'
export const OPEN_DETAIL_WINDOW = 'OPEN_DETAIL_WINDOW'
export const CLOSE_DETAIL_WINDOW = 'CLOSE_DETAIL_WINDOW'
export const OPEN_GEOMETRY_EDITING_WINDOW = 'OPEN_GEOMETRY_EDITING_WINDOW'
export const SUBMIT_FEATURE_GEOMETRY_EDITS = 'SUBMIT_FEATURE_GEOMETRY_EDITS'
export const CLEAR_FEATURE_GEOMETRY = 'CLEAR_FEATURE_GEOMETRY'
export const CANCEL_FEATURE_GEOMETRY_EDITS = 'CANCEL_FEATURE_GEOMETRY_EDITS'
export const UNDO_FEATURE_GEOMETRY_VERTEX = 'UNDO_FEATURE_GEOMETRY_VERTEX'
export const ADD_EDIT_POINT_LOCATION = 'ADD_EDIT_POINT_LOCATION'
export const MAP_DRAW_UPDATE = 'MAP_DRAW_UPDATE'
export const UPDATE_GIS_DATA_SOURCES = 'UPDATE_GIS_DATA_SOURCES'
export const MAP_DRAW_UPDATE_MULTI = 'MAP_DRAW_UPDATE_MULTI'
export const SET_UNDO_STATUS = 'SET_UNDO_STATUS'
export const CLEAR_CURRENT_FEATURE_OBJECT = 'CLEAR_CURRENT_FEATURE_OBJECT'
export const ADD_TO_POLYGON = 'ADD_TO_POLYGON'
export const REMOVE_FROM_POLYGON = 'REMOVE_FROM_POLYGON'
export const TOGGLE_LINE_LENGTH_EDIT = 'TOGGLE_LINE_LENGTH_EDIT'
export const FEATURE_SELECTED = 'FEATURE_SELECTED'
export const FETCH_FEATURE_INFO_SUCCESS = 'FETCH_FEATURE_INFO_SUCCESS'
export const FETCH_FEATURE_INFO_FAILURE = 'FETCH_FEATURE_INFO_FAILURE'

// mapViewport
export const SET_VIEWPORT = 'SET_VIEWPORT'
export const SET_VIEWPORT_DIMENSIONS = 'SET_VIEWPORT_DIMENSIONS'
export const SET_BEARING = 'SET_BEARING'
export const SET_PITCH = 'SET_PITCH'

// modal
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const POP_MODAL = 'POP_MODAL'
export const OPEN_MODAL_COMPONENT = 'OPEN_MODAL_COMPONENT'

// onlineSyncronizer
// export const CACHE_SUBMISSION = 'CACHE_SUBMISSION'
export const CLEAR_IS_SYNCING = 'CLEAR_IS_SYNCING'
export const COPY_ATTACHMENTS_LOCALLY = 'COPY_ATTACHMENTS_LOCALLY'
export const SET_IS_SYNCING = 'SET_IS_SYNCING'

// searchBar
export const ACTIVATE_SEARCH_BAR = 'ACTIVATE_SEARCH_BAR'
export const DEACTIVATE_SEARCH_BAR = 'DEACTIVATE_SEARCH_BAR'
export const SEARCH_TEXT_UPDATED = 'SEARCH_TEXT_UPDATED'
export const GEOCODE_SEARCH_BAR_REQUEST = 'GEOCODE_SEARCH_BAR_REQUEST'
export const GEOCODE_SEARCH_BAR_SUCCESS = 'GEOCODE_SEARCH_BAR_SUCCESS'
export const LOCAL_DATA_SEARCH_BAR_SUCCESS = 'LOCAL_DATA_SEARCH_BAR_SUCCESS'
export const GEOCODE_SEARCH_BAR_FAILURE = 'GEOCODE_SEARCH_BAR_FAILURE'
export const SET_CURRENT_SEARCH_BAR_RESULT = 'SET_CURRENT_SEARCH_BAR_RESULT'
export const SET_LAT_LNG_RESULT = 'SET_LAT_LNG_RESULT'

// toast
export const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE'
export const CLEAR_TOAST_MESSAGE = 'CLEAR_TOAST_MESSAGE'

// layers
export const SET_ACTIVE_DATA_SOURCE = 'SET_ACTIVE_DATA_SOURCE'

// editForm
export const GET_GIS_FEATURE_SCHEMAS_SUCCESS = 'GET_GIS_FEATURE_SCHEMAS_SUCCESS'
export const INPUT_LAID_OUT = 'INPUT_LAID_OUT'
export const SCROLL_TO_ERROR = 'SCROLL_TO_ERROR'
export const SCROLLED_TO_ERROR = 'SCROLLED_TO_ERROR'
export const DELETE_SELECTED_IMAGES = 'DELETE_SELECTED_IMAGES'
export const ON_MEDIA_SELECTION_CHANGED = 'ON_MEDIA_SELECTION_CHANGED'
