import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { REHYDRATE } from 'redux-persist/constants'
import * as types from '../constants/ActionTypes'
import { appStart } from '../../common/actions/LifecycleActions'
import * as modalActions from '../../common/actions/ModalActions'
import { getHardwareUid, getSystemInfo, getReactVersion } from '../utils/getDeviceInfo' // eslint-disable-line import/no-unresolved
import exitApp from '../utils/exitApp' // eslint-disable-line import/no-unresolved
import * as selectors from '../../common/selectors'
import * as modalNames from '../../common/constants/ModalNames'
import { createAlert } from '../actions/AlertActions'
import * as alertTypes from '../constants/AlertTypes'
import { makePattern } from './utils'
import Sentry from '../utils/sentry' // eslint-disable-line import/no-unresolved

/**
 * When redux-persist rehydrates our store, dispatch APP_START action
 */
function* handleRehydrate() {
  // build up info we need on app start
  const isConnected = yield select(selectors.getIsConnected)
  const licenseAccepted = yield select(selectors.getLicenseAccepted)

  const hardwareUid = getHardwareUid()
  const user = yield select(selectors.getUser)
  Sentry.setUser({ ...user, email: user.email, id: user.uid, hardwareUid })

  yield put(
    appStart({
      hardwareUid,
      isConnected,
      licenseAccepted,
      reactVersion: getReactVersion(),
      systemVersion: getSystemInfo(),
      user,
    })
  )
}

function* handleAppExit() {
  yield call(exitApp)
}

// Handles when the Android OS back button is pressed
function* handleAndroidBackPressed() {
  // If there is a modal open the let the back button close it
  const topModal = yield select(selectors.getTopModal)
  if (topModal) {
    // We don't close the license model if it is not accepted, so ignore the back
    if (topModal.modalName === modalNames.MODAL_LICENSE_AGREEMENT) {
      return true
    }
    yield put(modalActions.closeModal(topModal.modalName))
    return true
  } else {
    // Confirm with the user that we would like to exit
    yield put(
      createAlert(alertTypes.EXIT_APPLICATION_ALERT, ['OK', 'Cancel'], 'Really Exit?', 'Are you sure you want to exit?')
    )
  }
}

export function* handleDismissExitAlert({ selectedOptionIndex, alertName }) {
  // If the user chooses OK then we will exit the application
  if (selectedOptionIndex === 0) {
    yield call(exitApp)
  }
}

export default function* lifecycleSaga() {
  yield all([
    takeEvery(REHYDRATE, handleRehydrate),
    takeEvery(types.APP_EXIT, handleAppExit),
    takeEvery(types.ANDROID_BACK_PRESSED, handleAndroidBackPressed),
    takeEvery(
      makePattern(types.DISMISS_ALERT, { alertName: alertTypes.EXIT_APPLICATION_ALERT }),
      handleDismissExitAlert
    ),
  ])
}
