/* @flow */
import React from 'react'
import Modal from './SimpleModal'
import { Glyphicon } from 'react-bootstrap'
import { Text, View, TouchableHighlight } from 'react-native-web'
import { LICENSE_TEXT } from '../../common/constants/LicenseText'

type Props = {
  closeLicenseAgreement: Function,
  declineLicense: Function,
  acceptLicense: Function,
  interpolatedStyles: Object,
  hasCloseButton: boolean,
}

const acceptText = {
  fontSize: 22,
  justifyContent: 'center',
  color: '#3498db',
}

const declineText = {
  fontSize: 16,
  justifyContent: 'center',
  color: '#bfc6ce',
}

const acceptButtonView = {
  alignSelf: 'stretch',
  flexDirection: 'row',
  marginTop: 6,
  marginRight: 16,
  paddingTop: 6,
  paddingBottom: 6,
  paddingLeft: 11,
  paddingRight: 11,
}

const declineButtonView = {
  alignSelf: 'stretch',
  flexDirection: 'row',
  marginTop: 8,
  marginRight: 16,
  paddingTop: 8,
  paddingBottom: 6,
  paddingLeft: 11,
  paddingRight: 11,
}

const buttonView = {
  alignSelf: 'stretch',
  flexDirection: 'row',
  marginTop: 6,
  marginRight: 16,
  paddingTop: 6,
  paddingBottom: 6,
  paddingLeft: 11,
  paddingRight: 11,
}

class LicenseAgreementModal extends React.Component<Props> {
  render = () => {
    const { closeLicenseAgreement, declineLicense, acceptLicense, interpolatedStyles, hasCloseButton } = this.props
    return (
      <Modal
        title="IncidentView License Agreement"
        closeModal={closeLicenseAgreement}
        interpolatedStyles={interpolatedStyles}
        hasCloseButton={hasCloseButton}
      >
        <div style={{ height: '60vh', overflowY: 'scroll' }}>
          {LICENSE_TEXT.split('\n').map((paragraph, i) => (
            <p key={i}>{paragraph}</p>
          ))}
          {!hasCloseButton ? (
            <div>
              <View style={buttonView}>
                <TouchableHighlight bsStyle={null} onPress={acceptLicense}>
                  <View style={acceptButtonView}>
                    <Glyphicon
                      glyph="ok"
                      style={{
                        justifyContent: 'center',
                        fontSize: 22,
                        marginLeft: 4,
                        marginRight: 4,
                        marginTop: 2,
                        color: '#3498db',
                      }}
                    />
                    <Text style={acceptText}>&nbsp;&nbsp;CLICK TO ACCEPT</Text>
                  </View>
                </TouchableHighlight>
                <TouchableHighlight bsStyle={null} onPress={declineLicense}>
                  <View style={declineButtonView}>
                    <Glyphicon
                      glyph="remove"
                      style={{
                        justifyContent: 'center',
                        fontSize: 16,
                        marginLeft: 4,
                        marginRight: 4,
                        marginTop: 4,
                        color: '#bfc6ce',
                      }}
                    />
                    <Text style={declineText}>&nbsp;&nbsp;DECLINE AND EXIT</Text>
                  </View>
                </TouchableHighlight>
              </View>
            </div>
          ) : null}
        </div>
      </Modal>
    )
  }
}

export default LicenseAgreementModal
