import * as types from '../constants/ActionTypes'

// TODO: doesn't actually have any reducers caring about it yet
export function setToastMessage(message, duration) {
  return {
    type: types.SET_TOAST_MESSAGE,
    message,
    duration,
  }
}

export function clearToastMessage() {
  return {
    type: types.CLEAR_TOAST_MESSAGE,
  }
}
