/* @flow */
import React from 'react'
import { bindActionCreators } from 'redux'
import { View, StyleSheet, Button } from 'react-native-web'
import { connect } from 'react-redux'
import * as selectors from '../../common/selectors'
import { UI_BACKGROUND_LIGHT_COLOR, UI_BACKGROUND_DARK_COLOR } from '../../common/constants'
import { getTopAlert } from '../../common/reducers/alerts'
import {
  createAlert,
  submitFeatureGeometryEdits,
  clearFeatureGeometry,
  cancelFeatureGeometryEdits,
  undoFeatureGeometryVertex,
  addToPolygon,
  removeFromPolygon,
  toggleLineLengthEdit,
  openAddEditPointLocation,
} from '../../common/actions'
import * as alertTypes from '../../common/constants/AlertTypes'
import * as geometryTypes from '../../common/constants/GeometryTypes'
import { Glyphicon, Tooltip, OverlayTrigger } from 'react-bootstrap'

// import Reactotron from 'reactotron-react-js'

const styles = StyleSheet.create({
  buttonContainer: {
    position: 'absolute',
    zIndex: 20,
    top: 68,
    left: 0,
    right: 0,
    bottom: 0,
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    height: 44,
    backgroundColor: UI_BACKGROUND_LIGHT_COLOR,
    borderWidth: 2,
    borderColor: UI_BACKGROUND_DARK_COLOR,
    margin: 4,
  },
  rowTop: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
  },
  alignRight: {
    paddingVertical: 3,
    justifyContent: 'flex-end',
    backgroundColor: 'white',
  },
})

const buttonWrapperProps = { pointerEvents: 'auto', style: styles.button }

const mapStateToProps = state => {
  return {
    geometryEditingWindowOpen: selectors.getGeometryEditingWindowOpen(state),
    lineEditingToggle: selectors.getShouldToggleLineEditing(state),
    currentFeatureObject: selectors.getCurrentFeature(state),
    currentFeatureGeometryType: selectors.getActiveDataSourceGeometryType(state),
    isValidGeometry: selectors.getIsValidGeometry(state),
    featureEditHistory: selectors.getFeatureEditHistory(state),
    modalOpen: selectors.getTopModal(state),
    alertOpen: getTopAlert(state),
    dataSourceName: selectors.getActiveDataSourceName(state),
    addingNewFeature: selectors.getAddingNewFeature(state),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      cancelFeatureGeometryEdits,
      clearFeatureGeometry,
      undoFeatureGeometryVertex,
      submitFeatureGeometryEdits,
      createAlert,
      addToPolygon,
      openAddEditPointLocation,
      removeFromPolygon,
      toggleLineLengthEdit,
    },
    dispatch
  )

type Props = {
  geometryEditingWindowOpen: boolean,
  lineEditingToggle: boolean,
  addingNewFeature: boolean,
  currentFeatureObject: ?Object,
  currentFeatureGeometryType: ?string,
  cancelFeatureGeometryEdits: Function,
  clearFeatureGeometry: Function,
  openAddEditPointLocation: Function,
  undoFeatureGeometryVertex: Function,
  submitFeatureGeometryEdits: Function,
  toggleLineLengthEdit: Function,
  addToPolygon: Function,
  removeFromPolygon: Function,
  modalOpen: Object,
  alertOpen: ?Array<Object>,
  isValidGeometry: boolean,
  createAlert: Function,
  featureEditHistory: ?Array<Object>,
  activeDataSourceName: string,
}

class GeometryEditWindow extends React.PureComponent<Props> {
  render = () => {
    if (!this.props.geometryEditingWindowOpen || this.props.modalOpen || this.props.alertOpen) {
      return null
    }

    const toolTipUndoClear =
      (this.props.featureEditHistory &&
        this.props.featureEditHistory.length > 1 &&
        this.props.currentFeatureGeometryType &&
        this.props.currentFeatureGeometryType === geometryTypes.POLYGON) ||
      this.props.currentFeatureGeometryType === geometryTypes.MULTIPOLYGON ||
      this.props.currentFeatureGeometryType === geometryTypes.LINE ||
      this.props.currentFeatureGeometryType === geometryTypes.LINESTRING ? (
        <Tooltip id="toolTipUndo">Undo the last edit</Tooltip>
      ) : (
        <Tooltip id="toolTipClear">Clear in-progress edits</Tooltip>
      )
    const toolTipLatLng = (
      <Tooltip id="toolTipLatLng"> Set the {this.props.dataSourceName} location via coordinate values </Tooltip>
    )
    const toolTipSave =
      this.props.currentFeatureGeometryType === geometryTypes.POLYGON ||
      this.props.currentFeatureGeometryType === geometryTypes.MULTIPOLYGON ? (
        <Tooltip id="toolTipSave">
          Save edits (Make sure all edited {this.props.dataSourceName} polygons are 'closed'){' '}
        </Tooltip>
      ) : (
        <Tooltip id="toolTipSave">Click to save edits</Tooltip>
      )
    const toolTipCancel = <Tooltip id="toolTipCancel">Cancel editing mode</Tooltip>
    const toolTipAddToPoly = (
      <Tooltip id="toolTipAddToPoly">Click to start adding additional polygons to this entry</Tooltip>
    )
    const toolTipRemovePoly = <Tooltip id="toolTipRemovePoly">Click to remove selected vertex or polygon(s)</Tooltip>
    const toolTipAddToLine = <Tooltip id="toolTipAddToLine">Add to line mode</Tooltip>
    const toolTipMoveLine = <Tooltip id="toolTipMoveLine">Move vertices mode</Tooltip>
    return (
      <View pointerEvents="none" style={styles.buttonContainer}>
        <View style={styles.rowTop}>
          <OverlayTrigger overlay={toolTipCancel} placement="bottom">
            <View {...buttonWrapperProps}>
              <Button
                style={styles.alignRight}
                title="Cancel"
                accessibilityLabel="Cancel"
                onPress={() => {
                  this.props.cancelFeatureGeometryEdits()
                }}
              >
                Cancel
              </Button>
            </View>
          </OverlayTrigger>

          <OverlayTrigger overlay={toolTipUndoClear} placement="bottom">
            <View {...buttonWrapperProps}>
              {this.props.currentFeatureGeometryType === geometryTypes.POLYGON ||
              this.props.currentFeatureGeometryType === geometryTypes.MULTIPOLYGON ||
              this.props.currentFeatureGeometryType === geometryTypes.LINE ||
              this.props.currentFeatureGeometryType === geometryTypes.LINESTRING ? (
                <div>
                  {this.props.currentFeatureObject &&
                  this.props.featureEditHistory &&
                  this.props.featureEditHistory.length > 1 ? (
                    <Button
                      style={styles.alignRight}
                      title="Undo"
                      accessibilityLabel="Undo"
                      onPress={() => {
                        this.props.undoFeatureGeometryVertex()
                      }}
                    >
                      Undo
                    </Button>
                  ) : (
                    <Button
                      style={styles.alignRight}
                      title="Undo"
                      accessibilityLabel="Undo"
                      onPress={() => {}}
                      disabled
                    >
                      Undo
                    </Button>
                  )}
                  {}
                </div>
              ) : (
                <Button
                  style={styles.alignRight}
                  title="Clear"
                  accessibilityLabel="Clear"
                  onPress={() => {
                    this.props.clearFeatureGeometry()
                  }}
                >
                  Clear
                </Button>
              )}
            </View>
          </OverlayTrigger>

          {this.props.currentFeatureGeometryType === geometryTypes.POINT ? (
            <OverlayTrigger overlay={toolTipLatLng} placement="bottom">
              <View {...buttonWrapperProps}>
                <Button
                  style={styles.alignRight}
                  title="lat/lng"
                  accessibilityLabel="Insert Coordinates"
                  onPress={() => {
                    this.props.openAddEditPointLocation()
                  }}
                >
                  Insert Coordinates
                </Button>
              </View>
            </OverlayTrigger>
          ) : null}

          {this.props.featureEditHistory &&
          (this.props.featureEditHistory.length > 1 ||
            (this.props.featureEditHistory.length === 1 && this.props.addingNewFeature)) ? (
            <OverlayTrigger overlay={toolTipSave} placement="bottom">
              <View {...buttonWrapperProps}>
                <Button
                  style={styles.alignRight}
                  title="Save"
                  accessibilityLabel="Save"
                  onPress={() => {
                    if (this.props.isValidGeometry) {
                      this.props.submitFeatureGeometryEdits()
                    } else {
                      let featureGeometryErrorMessage = ''
                      if (this.props.currentFeatureGeometryType === geometryTypes.POINT) {
                        featureGeometryErrorMessage = 'Please click on the map to add a point'
                      } else if (
                        this.props.currentFeatureGeometryType === geometryTypes.LINESTRING ||
                        this.props.currentFeatureGeometryType === geometryTypes.LINE
                      ) {
                        featureGeometryErrorMessage =
                          'Lines must have at least two points.  Please click on the map to add additional points'
                      } else if (
                        this.props.currentFeatureGeometryType === geometryTypes.POLYGON ||
                        this.props.currentFeatureGeometryType === geometryTypes.MULTIPOLYGON
                      ) {
                        featureGeometryErrorMessage =
                          'Please double-click before selecting save. Polygons must have at least three points.'
                      }
                      this.props.createAlert(
                        alertTypes.INVALID_FEATURE_GEOMETRY_ALERT,
                        ['OK'],
                        'Invalid Geometry',
                        featureGeometryErrorMessage
                      )
                    }
                  }}
                >
                  Save
                </Button>
              </View>
            </OverlayTrigger>
          ) : (
            <View {...buttonWrapperProps}>
              <Button style={styles.alignRight} title="Save" accessibilityLabel="Save" onPress={() => {}} disabled>
                Save
              </Button>
            </View>
          )}

          {this.props.currentFeatureGeometryType === geometryTypes.POLYGON ||
          this.props.currentFeatureGeometryType === geometryTypes.MULTIPOLYGON ? (
            <OverlayTrigger overlay={toolTipAddToPoly} placement="bottom">
              <div>
                <View {...buttonWrapperProps}>
                  <Button
                    style={styles.alignRight}
                    title="+"
                    accessibilityLabel="Add"
                    onPress={() => {
                      this.props.addToPolygon()
                    }}
                  >
                    +
                  </Button>
                </View>
              </div>
            </OverlayTrigger>
          ) : null}

          {this.props.currentFeatureGeometryType === geometryTypes.POLYGON ||
          this.props.currentFeatureGeometryType === geometryTypes.MULTIPOLYGON ? (
            <div>
              {this.props.currentFeatureObject ? (
                // &&  this.props.currentFeatureObject.coordinates &&
                //   this.props.currentFeatureObject.coordinates.length > 1
                <OverlayTrigger overlay={toolTipRemovePoly} placement="bottom">
                  <View {...buttonWrapperProps}>
                    <Button
                      style={styles.alignRight}
                      title="-"
                      accessibilityLabel="Remove"
                      onPress={() => {
                        this.props.removeFromPolygon()
                      }}
                    >
                      -
                    </Button>
                  </View>
                </OverlayTrigger>
              ) : (
                <View {...buttonWrapperProps}>
                  <Button style={styles.alignRight} title="-" accessibilityLabel="Remove" onPress={() => {}} disabled>
                    -
                  </Button>
                </View>
              )}
            </div>
          ) : null}

          {(this.props.currentFeatureGeometryType === geometryTypes.LINE ||
            this.props.currentFeatureGeometryType === geometryTypes.LINESTRING) &&
          this.props.lineEditingToggle === false ? (
            <OverlayTrigger overlay={toolTipAddToLine} placement="bottom">
              <div>
                <View {...buttonWrapperProps}>
                  <Button
                    style={styles.alignRight}
                    title={<Glyphicon glyph="pencil" />}
                    accessibilityLabel="Add To Line"
                    onPress={() => this.props.toggleLineLengthEdit()}
                  />
                </View>
              </div>
            </OverlayTrigger>
          ) : null}

          {(this.props.currentFeatureGeometryType === geometryTypes.LINE ||
            this.props.currentFeatureGeometryType === geometryTypes.LINESTRING) &&
          this.props.lineEditingToggle === true ? (
            <OverlayTrigger overlay={toolTipMoveLine} placement="bottom">
              <div>
                <View {...buttonWrapperProps}>
                  <Button
                    style={styles.alignRight}
                    title={
                      <Glyphicon
                        glyph="move"
                        style={{
                          justifyContent: 'center',
                        }}
                      />
                    }
                    accessibilityLabel="Move Line"
                    onPress={() => this.props.toggleLineLengthEdit()}
                  />
                </View>
              </div>
            </OverlayTrigger>
          ) : null}
        </View>
      </View>
    )
  }

  onCancel() {
    this.props.cancelFeatureGeometryEdits()
  }

  onClear() {
    this.props.clearFeatureGeometry()
  }

  onDone() {
    if (this.props.isValidGeometry) {
      this.props.submitFeatureGeometryEdits()
    } else {
      let featureGeometryErrorMessage = ''
      if (this.props.currentFeatureGeometryType === geometryTypes.POINT) {
        featureGeometryErrorMessage = 'Please click on the map to add a point'
      } else if (this.props.currentFeatureGeometryType === geometryTypes.LINESTRING) {
        featureGeometryErrorMessage = 'Please double-click before selecting save. Lines must have at least two points.'
      } else if (
        this.props.currentFeatureGeometryType === geometryTypes.POLYGON ||
        this.props.currentFeatureGeometryType === geometryTypes.MULTIPOLYGON
      ) {
        featureGeometryErrorMessage =
          'Please double-click before selecting save. Polygons must have at least three points.'
      }
      this.props.createAlert(
        alertTypes.INVALID_FEATURE_GEOMETRY_ALERT,
        ['OK'],
        'Invalid Geometry',
        featureGeometryErrorMessage
      )
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeometryEditWindow)
