/* @flow */
import React from 'react'
import Files from 'react-files'
import { View, Text, Button } from 'react-native-web'
import _ from 'lodash'

const RemoveAllButton = ({ length, onPress }) => {
  if (length < 2) {
    return null
  }
  return (
    <View style={{ flexDirection: 'row', margin: 5, justifyContent: 'center' }}>
      <Button title="Remove All" onPress={onPress} />
    </View>
  )
}

const FileList = ({ readonly, files, onRowPress, onRowRemove }) => {
  return files.length > 0 ? (
    <View style={{ flexDirection: 'column' }}>
      {files.map((file, ndx) => {
        const filename = file.fileName ? file.fileName : file.imageName
        return (
          <View key={ndx} style={{ flexDirection: 'row', margin: 5, alignItems: 'center', justifyContent: 'flex-end' }}>
            <Text
              onPress={() => onRowPress(ndx)}
              style={{
                flex: 1,
                backgroundColor: 'white',
                textDecorationLine: 'underline',
                color: 'blue',
              }}
            >
              {filename}
            </Text>
            {!readonly ? <Button style={{ marginRight: 5 }} title={'Remove'} onPress={() => onRowRemove(ndx)} /> : null}
          </View>
        )
      }) // eo map
      }
    </View>
  ) : null
}

const applicationPdf = 'application/pdf'
const applicationImage = 'application/image'

type FileObject = {
  blob?: File,
  fileName: string,
  fileType?: string,
  fileUrl?: string,
}

type Props = {
  formData: ?(any[]),
  onChange: Function,
  name: string,
  formContext: any,
  schema: any,
  readonly: boolean,
}

type State = {
  files: Array<FileObject>,
}

export class IVFilePicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      files: props.formData || [],
    }
  }

  typeFromFileName = (fileName: string) => {
    const ext = fileName
      .split('.')
      .pop()
      .toLowerCase()
    if (ext === 'pdf') {
      return applicationPdf
    }
    if (['jpg', 'png', 'jpeg', 'gif', 'bmp', 'apng', 'svg'].includes(ext)) {
      return applicationImage // TODO: fix up MIME type calculation - application/image doesn't exist
    }
    return undefined
  }

  onRowPress = (ndx: number) => {
    let file = this.state.files[ndx]
    const filename = file.fileName
    let fType = this.typeFromFileName(filename)
    let isImage = fType === applicationImage
    if (fType !== applicationPdf && !isImage) {
      let extension = filename
        .split('.')
        .pop()
        .toLowerCase()
      alert(`Unable to preview file of type ${extension}`)
      return
    }
    const url = file.fileUrl || (file.blob && file.blob.preview.url)
    // For now, open files in new tabs like we do on the FeatureDetailWindow
    window.open(url)
    // TODO: open files within app using FileViewerModal once it's styled appropriately and working for multi-page PDFs
    // TODO: using FileViewModal will also fix bug where we can't preview file that have just been drug onto file picker
    // this.props.formContext.openFileViewerModal(url, isImage)
  }

  // This is passed the new HTML Files
  onFilesChange = (files: Array<File>) => {
    const oldTotalFileObjects = this.state.files
    const idInExistingFiles = id => oldTotalFileObjects.find(f => f.blob && f.blob.id === id)
    const newFiles = files.filter(fl => !idInExistingFiles(fl.id))

    const newFileObjects = newFiles.map(f => {
      return {
        blob: f,
        fileName: f.name,
      }
    })

    const newTotalFileObjects = oldTotalFileObjects.concat(newFileObjects)
    this.setState({ files: newTotalFileObjects })
    this.props.onChange(newTotalFileObjects)
  }

  onFilesError = (error, file) => {
    alert('error code ' + error.code + ': ' + error.message)
  }

  onRowRemove = (index: number) => {
    const files = this.state.files
    const fileToRemove = files[index]
    // Only remove the file from the Files component if it was a newly-added file (not previously uploaded)
    if (fileToRemove.blob) {
      this.refs.files.removeFile(fileToRemove.blob)
    }

    const newFiles = [...files.slice(0, index), ...files.slice(index + 1)]
    this.setState({ files: newFiles })
    this.props.onChange(newFiles)
  }

  filesRemoveAll = () => {
    this.setState({ files: [] })
    this.props.onChange([])
    this.refs.files.removeFiles()
  }

  render() {
    let filesHeight = '44px'
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          borderColor: 'lightgrey',
          borderWidth: 1,
        }}
      >
        <Text style={this.props.formContext.style}>{this.props.schema.title || _.startCase(this.props.name)}</Text>

        <Files
          accepts={['image/*', 'application/pdf']}
          ref="files"
          className="files-dropzone-list"
          style={{
            display: this.props.readonly ? 'none' : 'flex',
            justifyContent: 'center',
            height: filesHeight,
            backgroundColor: 'yellow',
          }}
          onChange={this.onFilesChange}
          onError={this.onFilesError}
          multiple
          maxFiles={10}
          maxFileSize={10000000}
          minFileSize={0}
          clickable
        >
          <View style={{ flex: 1, justifyContent: 'center', backgroundColor: 'lightgrey' }}>
            <Text style={{ color: 'white', textAlign: 'center' }}>Drop Zone</Text>
          </View>
        </Files>

        <FileList
          readonly={this.props.readonly}
          files={this.state.files}
          onRowPress={this.onRowPress.bind(this)}
          onRowRemove={this.onRowRemove.bind(this)}
        />
        {!this.props.readonly && (
          <RemoveAllButton length={this.state.files.length} onPress={this.filesRemoveAll.bind(this)} />
        )}
      </View>
    )
  } // eo render()
} // eo IVFilePicker
