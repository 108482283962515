/* @flow */
import React from 'react'
import { FormGroup, ControlLabel, FormControl, Checkbox } from 'react-bootstrap'
import Modal from './SimpleModal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setDeviceDisplayNameFromUser, toggleKeepScreenActive } from '../../common/actions/DeviceActions'
import * as selectors from '../../common/selectors'
import { setToastMessage } from '../../common/actions/ToastActions'
import { textInputStyle } from '../styles'

const mapStateToProps = state => {
  return {
    deviceName: selectors.getDeviceName(state),
    keepScreenActive: selectors.getKeepScreenActive(state),
    getIsConnected: selectors.getIsConnected(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        setDeviceDisplayNameFromUser,
        setToastMessage,
        toggleKeepScreenActive,
      },
      dispatch
    ),
  }
}

type Props = {
  closeSetting: Function,
  getUpdateMeasureUnitType: Function,
  getUpdateCoordinateLocationUnitType: Function,
  setDeviceDisplayNameFromUser: Function,
  measureUnitType: string,
  measureUnitTypeLabel: string,
  coordinateLocationUnitType: string,
  deviceName: string,
  getIsConnected: boolean,
  notificationsSound: boolean,
  toggleNotificationsSound: Function,
  setToastMessage: Function,
  keepScreenActive: ?boolean,
  toggleKeepScreenActive: Function,
  interpolatedStyles: Object,
}

const SettingModal = ({
  closeSetting,
  getUpdateMeasureUnitType,
  getUpdateCoordinateLocationUnitType,
  setDeviceDisplayNameFromUser,
  measureUnitType,
  measureUnitTypeLabel,
  coordinateLocationUnitType,
  deviceName,
  getIsConnected,
  notificationsSound,
  toggleNotificationsSound,
  setToastMessage,
  keepScreenActive,
  toggleKeepScreenActive,
  interpolatedStyles,
}: Props) => {
  return (
    <Modal interpolatedStyles={interpolatedStyles} title="Settings" closeModal={closeSetting}>
      <FormGroup id="formControlsDeviceName">
        <ControlLabel>Device Name</ControlLabel>
        <FormControl
          type="text"
          value={deviceName}
          onChange={e => setDeviceDisplayNameFromUser(e.target.value)}
          style={textInputStyle}
        />
      </FormGroup>
      <Checkbox checked={keepScreenActive} onChange={toggleKeepScreenActive}>
        Keep screen active
      </Checkbox>
    </Modal>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingModal)
