import { delay } from 'redux-saga'
import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import * as types from '../constants/ActionTypes'
import { SEARCH_LAT_LNG } from '../constants/SearchResultTypes'
import { setTargetLocation } from '../actions/MapActions'
import * as searchBarActions from '../actions/SearchBarActions'
import {
  getSearchBarActive,
  getSearchBarResults,
  //getDispatches,
  getCurrentLocation,
} from '../selectors'
import { geocodeUsingMapbox, getResultsArray } from '../utils/geocode'
import { parseCoordinates } from '../utils/unitTypeConverters'

function* doMapboxGeocodes(action) {
  try {
    const currentLocation = yield select(getCurrentLocation)
    const responseJson = yield call(geocodeUsingMapbox, action.searchText, currentLocation)
    const searchResults = getResultsArray(responseJson, currentLocation)
    yield put(searchBarActions.geocodeSearchBarSuccess(searchResults))
  } catch (err) {
    yield put(searchBarActions.geocodeSearchBarFailure(err))
  }
}

export function* geocodeIfRequirementsMet(action) {
  const searchBarActive = yield select(getSearchBarActive)
  if (action.searchText && action.searchText.length > 2 && searchBarActive) {
    // Wait 400ms since last text update, so we don't spam requests
    yield call(delay, 400)
    // check to see if searchText is actually a set of coordinates
    const parsed = parseCoordinates(action.searchText)
    if (parsed) {
      yield put(
        searchBarActions.setLatLngResult({
          displayText: action.searchText,
          lat: parsed.lat,
          lng: parsed.lng,
          type: SEARCH_LAT_LNG,
          relevance: 1,
        })
      )
    } else {
      // if not, geocode
      yield put(searchBarActions.geocodeSearchBarRequest(action.searchText))
    }
  }
}

// function* updateSearchWithDispatchAddress(action) {
//   const { dispatchId } = action
//   const dispatches = yield select(getDispatches)
//   const { street, city, state, zip } = dispatches.get(dispatchId)
//   const searchText = [street, city, city && state, zip].filter(v => v).join(', ')
//
//   yield put(searchBarActions.searchTextUpdated(searchText))
// }

function* handleSetCurrentSearchBarResult(action) {
  const searchResults = yield select(getSearchBarResults)
  const searchResultsJS = searchResults.toJS()
  const { coordinates, displayText } = searchResultsJS[action.id]

  if (
    coordinates.lat &&
    coordinates.lng &&
    coordinates.lat >= -90 &&
    coordinates.lat <= 90 &&
    coordinates.lng >= -180 &&
    coordinates.lng <= 180
  ) {
    yield put(setTargetLocation(coordinates.lat, coordinates.lng, displayText))
  } else {
    const currentLocation = yield select(getCurrentLocation)
    alert(
      `Incorrect coordinate format: Coordinates must be entered latitude, longitude in 'decimal degree' format. Ex: ${
        currentLocation.lat
      },${currentLocation.lng}`
    )
  }
}

export function* watchSearchTextUpdated() {
  yield takeLatest(types.SEARCH_TEXT_UPDATED, geocodeIfRequirementsMet)
}

export default function* searchBarSaga() {
  yield all([
    fork(watchSearchTextUpdated),
    takeEvery(types.GEOCODE_SEARCH_BAR_REQUEST, doMapboxGeocodes),
    // takeEvery(types.SET_CURRENT_DISPATCH, updateSearchWithDispatchAddress),
    takeEvery(types.SET_CURRENT_SEARCH_BAR_RESULT, handleSetCurrentSearchBarResult),
  ])
}
