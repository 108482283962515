/* @flow */
import React from 'react'
import ReactMapboxGl, { Image as MapImage, Popup } from 'react-mapbox-gl'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as turf from '@turf/turf'
import { openFeatureSelectModal } from '../../common/actions'
import * as mapActions from '../../common/actions/MapActions'
import * as editorActions from '../../common/actions/EditorActions'
import * as selectors from '../../common/selectors'
import * as alertTypes from '../../common/constants/AlertTypes'
import { MAPBOX_ACCESS_TOKEN } from '../../common/constants'
import { TOP_NAVBAR_HEIGHT } from '../components/TopNavbar'
import { DETAIL_WINDOW_WIDTH } from '../containers/FeatureDetailWindow'
import { diff as diffStyles } from 'mapbox-gl-style-spec'
import * as geometryTypes from '../../common/constants/GeometryTypes'
import DrawControl from 'react-mapbox-gl-draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { createAlert } from '../../common/actions/AlertActions'
import Spinner from 'react-spinner'
import { throttle } from 'lodash'
import 'mapbox-gl/dist/mapbox-gl.css'
import '../styles/map.css'
import { setViewport } from '../../common/actions/MapActions'
import { getTopAlert } from '../../common/reducers/alerts'
import customIcons from '../../common/resources/MapboxIcons'
import Sentry from '../../common/utils/sentry' // eslint-disable-line import/no-unresolved

const customIconImages = Object.entries(customIcons).reduce((acc, [iconName, iconBase64]) => {
  const imageElement = new Image() // Image constructor
  imageElement.src = iconBase64
  acc[iconName] = imageElement
  return acc
}, {})

const streetsFooter = {
  position: 'fixed',
  right: 0,
  bottom: 0,
  zIndex: 2,
  padding: 0,
  fontSize: '10px',
  border: 'none',
}
const defaultFooter = {
  position: 'fixed',
  right: 0,
  bottom: 0,
  zIndex: 2,
  padding: 0,
  fontSize: '10px',
  border: 'none',
  color: '#BFBFBF',
}

const zoomDisabledStyle = {
  opacity: 0.4,
}

const mapStateToProps = state => {
  return {
    anyWindowOpen: selectors.getAnyWindowOpen(state),
    detailWindowOpen: selectors.getDetailWindowOpen(state),
    featureSets: selectors.getFeatureSets(state),
    editWindowOpen: selectors.getEditWindowOpen(state),
    agencyName: selectors.getAgencyName(state),
    baseMapType: state.map.baseMapType,
    authToken: selectors.getToken(state),
    currentLocation: state.map.currentLocation,

    zoom: [selectors.getViewportZoom(state)],
    center: selectors.getViewportCenter(state),

    currentFeature: selectors.getCurrentFeature(state),
    addMultiPartPoly: selectors.getCreateMultiPartStatus(state),
    undoStatus: selectors.getUndoStatus(state),
    lineEditingToggle: selectors.getShouldToggleLineEditing(state),
    removeStatus: selectors.getRemoveStatus(state),
    featureEditHistory: selectors.getFeatureEditHistory(state),
    zoomInDisabled: selectors.getZoomInDisabled(state),
    zoomOutDisabled: selectors.getZoomOutDisabled(state),
    currentGeometryType: selectors.getActiveDataSourceGeometryType(state),
    activeDataSourceName: selectors.getActiveDataSourceName(state),
    geometryEditingWindowOpen: selectors.getGeometryEditingWindowOpen(state),
    mapboxStyle: selectors.getMapStyleWithIvLayers(state),
    mapViewport: selectors.getMapViewport(state) && selectors.getMapViewport(state).toJS(),
    fontSizeAdjustments: selectors.getFontSizeAdjustments(state),
    addingNewFeature: selectors.getAddingNewFeature(state),
    mapboxLayerNamesByGisSourceType: selectors.getMapboxLayerNamesByEditableGisSourceType(state),
    gisDataSourceStyleLayerIds: selectors.getGisDataSourceStyleLayerIds(state),
    dynamicSources: selectors.getDynamicSources(state),
    dynamicLayers: selectors.getDynamicLayers(state),
    shouldTrackUserLocation: selectors.getShouldTrackUserLocation(state),
    editGeometryMessage: selectors.getEditGeometryMessage(state),
    showSpinner: selectors.getShouldShowSpinner(state),
    shouldCenterMap: selectors.getShouldCenterMap(state),
    alertOpen: getTopAlert(state),
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setViewport,
      createAlert,
      // setViewportDimensions,
      // zoomIn,
      // zoomOut,
      ...mapActions,
      ...editorActions,
      openFeatureSelectModal,
    },
    dispatch
  )
}

type Props = {
  mapStyleLoaded: Function,
  currentGeometryType: ?string,
  activeDataSourceName: ?string,
  featureSets: {},
  baseMapType: string,
  authToken: string,
  addEditPointLocation: Function,
  mapDrawUpdate: Function,
  mapDrawUpdateMulti: Function,
  setUndoStatus: Function,
  undoStatus: boolean,
  removeStatus: boolean,
  detailWindowOpen: boolean,
  editWindowOpen: boolean,
  lineEditingToggle: boolean,
  mapboxLayerNamesByGisSourceType: ?Object,
  gisDataSourceStyleLayerIds: ?Array<string>,
  openFeatureSelectModal: Function,
  geometryEditingWindowOpen: boolean,
  showSpinner: boolean,
  addingNewFeature: boolean,
  zoomToLocationAction: Function,
  toggleLineLengthEdit: Function,
  removeFromPolygon: Function,
  addToPolygon: Function,
  currentFeature: ?Object,
  addMultiPartPoly: boolean,
  openDetailWindow: Function,
  createAlert: Function,
  featureSelected: Function,
  featureEditHistory: ?Array<Object>,
  editGeometryMessage: string,
  shouldCenterMap: boolean,
  centerMap: Function,
  alertOpen: ?Array<Object>,
}

type State = { initialMapboxStyle: Object, center: ?Array<number>, popupCoords: Object }

class IvReactMapboxGlMap extends React.PureComponent<Props, State> {
  firstStyleLoad: boolean
  _map: Object // mapbox-gl-js instance
  _mapWrapperDiv: Object
  drawControl: Object
  MapComponent: any

  state = {
    initialMapboxStyle: null,
    center: null,
    zoom: [13],
    popupCoords: null,
    showPopup: true,
  }
  styleHasChangedBeforeMapLoaded = false
  originalFeature = undefined
  multiPartEdits = false
  constructor(props) {
    super(props)

    this.MapComponent = ReactMapboxGl({
      accessToken: MAPBOX_ACCESS_TOKEN,
      attributionControl: false, // attribution control is displayed in the AboutModal
      transformRequest: this.transformMapboxRequest,
    })

    this.state.initialMapboxStyle = props.mapboxStyle && props.mapboxStyle.toJS()
    if (props.mapViewport && props.mapViewport.longitude && props.mapViewport.latitude) {
      this.state.center = [props.mapViewport.longitude, props.mapViewport.latitude]
    } else {
      this.state.center = null
    }
  }

  transformMapboxRequest = (url, resourceType) => {
    if (resourceType === 'Tile' && url.startsWith('https://api.incidentview.com')) {
      return {
        url: url,
        headers: { Authorization: `Bearer ${this.props.authToken}` },
      }
    }
  }

  // componentDidMount = () => {
  //   this.setViewportDimensions()
  //
  //   window.addEventListener('resize', () => {
  //     this.setViewportDimensions()
  //   })
  // }

  // setViewportDimensions (shouldResize) {
  //   let dimensions = {
  //     height: this._mapWrapperDiv.offsetHeight,
  //     width: this._mapWrapperDiv.offsetWidth,
  //   }
  //   this.props.setViewportDimensions(dimensions)
  //   if (shouldResize) {
  //     if (this._map) {
  //       this._map.resize()
  //     }
  //   }
  // }

  componentWillReceiveProps(nextProps) {
    const currentStyle = this.props.mapboxStyle
    const nextStyle = nextProps.mapboxStyle
    if (!this.state.initialMapboxStyle && nextStyle) {
      this.setState({ initialMapboxStyle: nextStyle.toJS() })
    } else if (!this._map && nextStyle) {
      // If the style changes before the map is mounted, update initialMapboxStyle
      this.styleHasChangedBeforeMapLoaded = true
    }

    if (this._map && this.styleHasChangedBeforeMapLoaded && nextStyle) {
      this.styleHasChangedBeforeMapLoaded = false
      this.state.initialMapboxStyle && this.diffOrSetStyle(this.state.initialMapboxStyle, nextStyle.toJS())
    }

    if (this._map && currentStyle && nextStyle && !currentStyle.equals(nextStyle)) {
      this.diffOrSetStyle(currentStyle.toJS(), nextStyle.toJS())
    }

    if (nextProps.mapViewport) {
      let stateUpdateObject = {}
      const newCenter = [nextProps.mapViewport.longitude, nextProps.mapViewport.latitude]
      if (!this.state.center || newCenter[0] !== this.state.center[0] || newCenter[1] !== this.state.center[1]) {
        stateUpdateObject['center'] = newCenter
      }
      const newZoom = nextProps.mapViewport.zoom
      if (newZoom !== this.state.zoom[0]) {
        stateUpdateObject['zoom'] = [newZoom]
      }
      if (Object.keys(stateUpdateObject).length) {
        this.setState(stateUpdateObject)
      }
    }
  }
  // in react 17.0 will be UNSAFE_componentWillUpdate. Move or replace??
  componentWillUpdate(nextProps, nextState) {
    //
    // Switching back and forth between line length increase edits and vertex editing
    //
    if (
      this.drawControl &&
      (this.props.currentGeometryType === geometryTypes.LINE ||
        this.props.currentGeometryType === geometryTypes.LINESTRING)
    ) {
      if (nextProps.currentFeature) {
        let currentLineFeature = nextProps.currentFeature
        if (nextProps.currentFeature && !nextProps.currentFeature.id) {
          currentLineFeature.id = Math.random()
            .toString(36)
            .substring(3, 9)
          this.drawControl.draw.deleteAll()
          this.drawControl.draw.add(currentLineFeature)
          this.drawControl.draw.changeMode('direct_select', { featureId: currentLineFeature.id })
        }
        if (nextProps.lineEditingToggle === true) {
          let mode = this.drawControl.draw.getMode()
          if (!this.props.currentFeature && nextProps.currentFeature) {
            if (mode === 'draw_line_string') {
              this.props.mapDrawUpdate(currentLineFeature)
              this.drawControl.draw.changeMode('direct_select', { featureId: currentLineFeature.id })
              this.props.toggleLineLengthEdit()
            }
          } else {
            const fromPoint = this.drawControl.draw.get(currentLineFeature.id)
            try {
              // mapboxGlDraw returns 'from' coordinate that is not even present in the current feature
              // so always throws error however does correctly change the mode
              this.drawControl.draw.changeMode('draw_line_string', {
                featureId: currentLineFeature.id,
                from: fromPoint.geometry.coordinates[fromPoint.geometry.coordinates.length - 1],
              })
            } catch (err) {
              // console.log('Error!', err)
              if (this.props.undoStatus !== nextProps.undoStatus) {
                this.drawControl.draw.changeMode('direct_select', { featureId: currentLineFeature.id })
                this.props.toggleLineLengthEdit()
                this.props.mapDrawUpdate(nextProps.currentFeature)
              }
            }
          }
        } else if (nextProps.lineEditingToggle === false && this.props.lineEditingToggle === true) {
          const currentLineFeature = this.drawControl.draw.getAll().features[0]
          this.drawControl.draw.changeMode('direct_select', { featureId: currentLineFeature.id })
        } else if (this.props.undoStatus === true) {
          this.drawControl.draw.add(nextProps.currentFeature)
          if (nextProps.featureEditHistory.length === 1) {
            this.drawControl.draw.changeMode('draw_line_string', {
              featureId: currentLineFeature.id,
              from: currentLineFeature.geometry.coordinates[currentLineFeature.geometry.coordinates.length - 1],
            })
          }
        }
      }
    }
    //
    // deleting a vertex or poly from multi
    //
    if (this.drawControl && nextProps.removeStatus === true) {
      const deletedFeature = this.drawControl.draw.getSelectedPoints()
      this.drawControl.draw.trash()
      const previousFeature = this.props.currentFeature
      const newFeature = this.drawControl.draw.getAll()
      const newFeatureLength = newFeature.features.length
      let updateCheck = true
      if (previousFeature.geometry.coordinates.length === 1 && newFeature.features && newFeatureLength === 0) {
        this.props.createAlert(
          alertTypes.INVALID_FEATURE_GEOMETRY_ALERT,
          ['OK'],
          'Invalid Geometry',
          'Polygons must have at least three points'
        )
        this.drawControl.draw.add(previousFeature)
      } else if (newFeatureLength < previousFeature.geometry.coordinates.length) {
        // preventing the deletion of a third vertex (polygons must have at least three points) otherwise causes error
        let prevCount = 0
        let currentCount = 0
        for (const geom of previousFeature.geometry.coordinates) {
          if (geom[0].length > 1) {
            for (const coord of geom) {
              if (coord.length === 4) {
                prevCount = prevCount + 1
              }
            }
          }
        }
        for (const feature of newFeature.features) {
          const featureLength = feature.geometry.coordinates[0].length
          if (featureLength === 4) {
            currentCount = currentCount + 1
          }
        }
        if (currentCount < prevCount) {
          // determine that the triangle was deleted on purpose vs third vertex was deleted...
          if (
            deletedFeature &&
            deletedFeature.features.length &&
            deletedFeature.features[0].geometry.coordinates.length === 2
          ) {
            // checking that the deleted feature was a point
            this.props.createAlert(
              alertTypes.INVALID_FEATURE_GEOMETRY_ALERT,
              ['OK'],
              'Invalid Geometry',
              'Polygons must have at least three points'
            )
            updateCheck = false
            this.drawControl.draw.deleteAll()
            this.drawControl.draw.add(previousFeature)
            this.props.mapDrawUpdate(previousFeature)
          }
        }
      }
      if (
        ((newFeature.isArray && newFeature.features[0].geometry.type === 'MultiPolygon') || newFeatureLength > 1) &&
        updateCheck === true
      ) {
        this.props.mapDrawUpdateMulti(newFeature.features)
      } else if (updateCheck === true) {
        this.props.mapDrawUpdate(newFeature.features[0])
      }
      this.props.removeFromPolygon()
    }
    //
    // updating drawControl when pressing 'undo
    //
    if (
      this.drawControl &&
      this.props.geometryEditingWindowOpen &&
      this.props.featureEditHistory.length >= nextProps.featureEditHistory.length
    ) {
      const statusCheck = nextProps.undoStatus
      if (statusCheck) {
        this.drawControl.draw.deleteAll()
        this.drawControl.draw.add(nextProps.currentFeature)
        this.props.setUndoStatus()
        const id = nextProps.currentFeature.id
        if (id) {
          this.drawControl.draw.changeMode('direct_select', { featureId: id })
        }
      }
    }
    //
    // When clicking 'add' for polygon editing (creating multi-polygons)
    //
    if (
      (this.props.currentGeometryType === geometryTypes.POLYGON ||
        this.props.currentGeometryType === geometryTypes.MULTIPOLYGON) &&
      nextProps.addMultiPartPoly &&
      this.props.geometryEditingWindowOpen
    ) {
      const mode = this.drawControl.draw.getMode()
      if (mode === 'draw_polygon') {
        // escaping add mode before poly creation by clicking add button again
        // must select polygon before clicking add again to activate draw mode
        this.drawControl.draw.changeMode('simple_select')
      } else {
        this.originalFeature = this.props.currentFeature
        this.drawControl.draw.changeMode('draw_polygon')
        this.props.addToPolygon()
      }
    }
    // adding new polygon to currentFeature's coordinates
    if (
      !this.props.detailWindowOpen &&
      nextProps.currentFeature &&
      nextProps.currentFeature.properties &&
      !nextProps.currentFeature.properties.guid &&
      this.props.featureEditHistory &&
      nextProps.featureEditHistory &&
      this.props.featureEditHistory.length < nextProps.featureEditHistory.length &&
      this.props.currentFeature &&
      this.props.currentFeature.geometry.coordinates.length === 1
    ) {
      if (this.originalFeature) {
        this.multiPartEdits = true
      }
    }
    if (this.props.alertOpen) {
      const theAlert = this.props.alertOpen.toJS()
      if (theAlert.alertName === 'cannotAddPolygonOutsideAgencyBoundary') {
        this.drawControl.draw.deleteAll()
        setTimeout(() => {
          this.drawControl.draw.changeMode('draw_polygon')
        }, 500) // to prevent a glDraw timing error
      }
    }
  }

  computeWidth = () => {
    let bounds = this._map.getBounds()
    let east = bounds._ne.lng
    let west = bounds._sw.lng

    return east - west
  }

  anyWindowJustClosed = prevProps => {
    return prevProps.anyWindowOpen && !this.props.anyWindowOpen
  }

  newFeatureCenter = feature => {
    if (feature.geometry) {
      let geomType = feature.geometry.type
      if (geomType === 'Point') {
        let [lng, lat] = feature.geometry.coordinates
        return [lng, lat]
      } else if (geomType === 'Line' || geomType === 'LineString') {
        let featurePoints = feature.geometry.coordinates
        let midpoint = turf.midpoint(featurePoints[0], featurePoints.slice(-1)[0])
        let [lng, lat] = midpoint.geometry.coordinates
        return [lng, lat]
      } else if (geomType === 'Polygon' || geomType === 'MultiPolygon') {
        let centroid = turf.centroid(feature)
        let [lng, lat] = centroid.geometry.coordinates
        return [lng, lat]
      }
    } else {
      return this.props.center
    }
  }
  componentDidUpdate(prevProps) {
    if (this._map) {
      const delayResize = throttle(() => {
        this._map.resize() // TODO: remove this
      }, 1000000)
      delayResize()
      this.mapWidth = this.computeWidth()
      let ctr = this.props.center
      if (
        ((this.props.detailWindowOpen && !prevProps.detailWindowOpen) ||
          (this.props.editWindowOpen && this.props.addingNewFeature)) &&
        this.props.shouldCenterMap
      ) {
        this._map.easeTo({ center: ctr, offset: [-(DETAIL_WINDOW_WIDTH / 2), 0] })
        this.props.centerMap()
      } else if (this.anyWindowJustClosed(prevProps)) {
        const newCenter = turf.centroid(prevProps.currentFeature)
        if (newCenter && newCenter.geometry && newCenter.geometry.coordinates) {
          this._map.easeTo({ center: newCenter.geometry.coordinates })
        } else {
          this._map.easeTo({ center: ctr })
        }
      } else if (
        this.props.detailWindowOpen &&
        prevProps.detailWindowOpen &&
        this.props.currentFeature.id !== prevProps.currentFeature.id
      ) {
        this._map.easeTo({ center: ctr, offset: [-(DETAIL_WINDOW_WIDTH / 2), 0] })
      }
    }
    if (prevProps.fontSizeAdjustments !== this.props.fontSizeAdjustments) {
      this._map.resize()
      this.setViewportDimensions()
    }
    if (this.props.geometryEditingWindowOpen && !prevProps.geometryEditingWindowOpen) {
      if (
        this.props.currentFeature &&
        (this.props.currentGeometryType === geometryTypes.POLYGON ||
          this.props.currentGeometryType === geometryTypes.MULTIPOLYGON ||
          this.props.currentGeometryType === geometryTypes.LINE ||
          this.props.currentGeometryType === geometryTypes.LINESTRING)
      ) {
        this.drawControl.draw.add(this.props.currentFeature)
        this.drawControl.draw.changeMode('direct_select', { featureId: this.props.currentFeature.id })
      } else if (this.props.currentGeometryType === geometryTypes.POINT) {
        this._map.getCanvas().style.cursor = 'crosshair'
      } else {
        // reset cursor for gl draw
        this._map.getCanvas().style.cursor = ''
      }
    } else if (!this.props.geometryEditingWindowOpen && prevProps.geometryEditingWindowOpen) {
      this.setState({ showPopup: true })
    }
  }
  diffOrSetStyle = (currentStyle, nextStyle) => {
    if (currentStyle.name !== nextStyle.name) {
      this._map.setStyle(nextStyle, { diff: false })
    } else {
      const changes = diffStyles(currentStyle, nextStyle)
      for (const change of changes) {
        try {
          if (change.command === 'setData') {
            const [sourceId, geoJson] = change.args
            this._map.getSource(sourceId).setData(geoJson)
          } else {
            this._map[change.command](...change.args)
          }
        } catch (error) {
          // if a change fails, fall back to setting the full style
          this._map.setStyle(nextStyle, { diff: false })
          break
        }
      }
    }
  }

  parseStringifiedProperties = feature => {
    const updatedFeature = turf.feature(feature.geometry, feature.properties)
    if (!feature.properties.featureSetGuid) {
      return updatedFeature
    }
    if (this.props.featureSets[feature.properties.featureSetGuid]) {
      const featureSchema = this.props.featureSets[feature.properties.featureSetGuid].feature_schema
      Object.keys(updatedFeature.properties).forEach(k => {
        const fieldDef = featureSchema.properties[k]
        if (fieldDef) {
          const val = updatedFeature.properties[k]
          if (fieldDef.type === 'array') {
            let asArray = JSON.parse(val)
            if (Array.isArray(asArray)) {
              updatedFeature.properties[k] = asArray
            }
          }
        }
      })
    }
    return updatedFeature
  }

  onClick = (map, e) => {
    const latitude = e.lngLat.lat
    const longitude = e.lngLat.lng
    // const screenCoordX = e.point.x
    // const screenCoordY = e.point.y
    if (
      this.props.geometryEditingWindowOpen &&
      !(
        this.props.currentGeometryType === geometryTypes.POLYGON ||
        this.props.currentGeometryType === geometryTypes.MULTIPOLYGON ||
        this.props.currentGeometryType === geometryTypes.LINE ||
        this.props.currentGeometryType === geometryTypes.LINESTRING
      ) // using mapbox-gl-draw for polygons, selecting other features when using draw.add causes errors
    ) {
      // TODO might reinstate the map move is the feature is lost on selection
      // if (this.props.currentGeometryType === geometryTypes.POINT) {
      //   this.props.zoomToLocationAction(latitude, longitude)
      // }
      this.props.addEditPointLocation(
        latitude,
        longitude,
        this.props.currentGeometryType,
        this.props.activeDataSourceName
      )
      this.setState({ showPopup: false })
    } else {
      const { mapboxLayerNamesByGisSourceType, gisDataSourceStyleLayerIds, geometryEditingWindowOpen } = this.props
      if (geometryEditingWindowOpen) {
        this.setState({ showPopup: false })
        if (
          this.props.currentGeometryType === geometryTypes.LINE ||
          this.props.currentGeometryType === geometryTypes.LINESTRING
        ) {
          let inProgressEdits = this.drawControl.draw.getAll().features[0]
          if (inProgressEdits) {
            this.props.mapDrawUpdate(inProgressEdits)
          }
        }
      }
      if (mapboxLayerNamesByGisSourceType && !this.props.geometryEditingWindowOpen) {
        const boundingBoxFeatures = [[e.point.x - 5, e.point.y + 5], [e.point.x + 5, e.point.y - 5]]
        const identifiedFeatures = map.queryRenderedFeatures(boundingBoxFeatures, {
          layers: gisDataSourceStyleLayerIds,
        })
        // If a dataSource consists of multiple layers, multiple features may return, so remove duplicates
        const featuresDeduplicated = identifiedFeatures.reduce((acc, feat) => {
          const existingGuids = acc.map(f => f.properties.guid)
          if (!existingGuids.includes(feat.properties.guid)) {
            acc.push(feat)
          }
          return acc
        }, [])
        if (featuresDeduplicated && featuresDeduplicated.length > 0) {
          if (featuresDeduplicated.length === 1 && !this.props.addingNewFeature) {
            let identifiedFeature = featuresDeduplicated[0]
            identifiedFeature = this.parseStringifiedProperties(identifiedFeature)
            const featureSetGuid = identifiedFeature.properties.featureSetGuid
            const sourceName =
              featureSetGuid && this.props.featureSets[featureSetGuid]
                ? this.props.featureSets[featureSetGuid].data_source_name
                : undefined
            this.props.featureSelected({ feature: identifiedFeature, dataSourceName: sourceName })
          } else if (!this.props.geometryEditingWindowOpen) {
            const featuresWithSourceName = featuresDeduplicated.map(f => {
              const sourceLayer = f.sourceLayer ? f.sourceLayer : f.source
              let sourceName
              for (const featureSetGuid in this.props.featureSets) {
                const theDataSourceName = this.props.featureSets[featureSetGuid].data_source_name
                if (sourceLayer && sourceLayer.includes(theDataSourceName)) {
                  sourceName = this.props.featureSets[featureSetGuid].data_source_name
                  break
                } else {
                  sourceName = sourceLayer.includes('edited') ? sourceLayer.replace('--edited', '') : sourceLayer
                  if (sourceName.includes('__')) {
                    sourceName = sourceName.split('__').pop()
                  }
                  break
                }
              }
              if (f.properties.hasOwnProperty('attachments')) {
                f.properties.attachments = JSON.parse(f.properties.attachments)
              }
              // `f` has many more properties than we want so create a smaller object (improves speed)
              return {
                type: 'Feature',
                properties: f.properties,
                geometry: f.geometry,
                sourceName,
              }
            })
            this.props.openFeatureSelectModal(featuresWithSourceName)
          }
        }
      }
    }
  }

  handleStyleLoad = map => {
    this._map = map
    this.props.mapStyleLoaded()
    map.dragRotate.disable()
    map.touchZoomRotate.disable()
    map.on('mousemove', e => {
      const boundingBoxFeatures = [[e.point.x - 5, e.point.y + 5], [e.point.x + 5, e.point.y - 5]]
      const lat = e.lngLat.lat.toFixed(4)
      const lng = e.lngLat.lng.toFixed(4)
      if (!this.props.geometryEditingWindowOpen) {
        const hoverFeatures = map.queryRenderedFeatures(boundingBoxFeatures, {
          layers: this.props.gisDataSourceStyleLayerIds,
        })
        map.getCanvas().style.cursor = hoverFeatures.length ? 'pointer' : ''
      }
      document.getElementById('info').innerHTML = `lng: ${lng} \u00A0\u00A0 lat: ${lat}`
      if (this.props.geometryEditingWindowOpen) {
        this.setState({ popupCoords: e.lngLat })
      }
    })
    map.on('draw.update', e => {
      if (this.multiPartEdits) {
        const newFeatures = this.drawControl.draw.getAll()
        this.props.mapDrawUpdateMulti(newFeatures.features)
      } else if (
        this.props.currentFeature &&
        this.props.currentFeature.geometry &&
        this.props.currentFeature.geometry.type &&
        this.props.currentFeature.geometry.type === geometryTypes.MULTIPOLYGON
      ) {
        // after adding a poly to a multi-part, making edits, without user uncombining (double click)
        const newFeature = this.drawControl.draw.getAll()
        this.props.mapDrawUpdateMulti(newFeature.features)
      } else if (this.props.currentGeometryType !== 'LineString' || this.props.currentGeometryType !== 'Line') {
        this.props.mapDrawUpdate(e.features[0])
      }
    })
    map.on('draw.selectionchange', e => {
      if (this.drawControl && this.props.currentGeometryType === geometryTypes.MULTIPOLYGON) {
        const mode = this.drawControl.draw.getMode()
        if (mode === 'simple_select') {
          this.drawControl.draw.uncombineFeatures()
        }
      }
    })
    map.on('draw.uncombine', e => {
      this.multiPartEdits = true
    })
  }

  getDefaultDrawMode = geomType => {
    if (
      this.props.addingNewFeature &&
      (geomType === geometryTypes.POLYGON || geomType === geometryTypes.MULTIPOLYGON)
    ) {
      return 'draw_polygon'
    } else if (
      this.props.addingNewFeature &&
      (geomType === geometryTypes.LINESTRING || geomType === geometryTypes.LINE)
    ) {
      return 'draw_line_string'
    } else {
      return 'simple_select'
    }
  }

  determineDrawUpdate = drawEvent => {
    if (this.props.currentFeature) {
      const newCoords = drawEvent.features[0].geometry.coordinates
      const oldCoords = this.props.currentFeature.geometry.coordinates
      if (newCoords.toString() === oldCoords.toString()) {
        return
      } else {
        if (
          this.props.currentFeature.geometry &&
          this.props.currentFeature.geometry.type &&
          this.props.currentFeature.geometry.type === geometryTypes.MULTIPOLYGON
        ) {
          const newFeature = this.drawControl.draw.getAll()
          return this.props.mapDrawUpdateMulti(newFeature.features)
        } else {
          const newFeature = this.drawControl.draw.getAll()
          if (newFeature && newFeature.features && newFeature.features.length > 1) {
            return this.props.mapDrawUpdateMulti(newFeature.features)
          } else {
            return this.props.mapDrawUpdate(newFeature.features)
          }
        }
      }
    } else {
      return this.props.mapDrawUpdate(drawEvent.features[0])
    }
  }

  onStyleImageMissing = (map, event) => {
    const iconName = event.id
    Sentry.captureMessage(
      `StyleImageMissing: ${iconName} is missing from agency's spritesheet, but is required by a layer in the map.`
    )
  }

  setViewportOnMoveEnd = (map, event) => {
    // only set viewport state if a human propagated the event through panning, zooming, etc.
    if (event.originalEvent) {
      const center = map.getCenter()
      const zoom = map.getZoom()

      this.props.setViewport({
        longitude: center.lng,
        latitude: center.lat,
        zoom,
      })
    }
  }

  render = () => {
    let center = this.props.center
    const { MapComponent } = this
    return (
      <div
        ref={_div => {
          this._mapWrapperDiv = _div
        }}
        style={{
          position: 'absolute',
          width: '100%',
          height: `calc(100% - ${TOP_NAVBAR_HEIGHT})`,
          flex: 1,
          backgroundColor: 'black',
        }}
      >
        <div className="zoomButtonsContainer" style={{ bottom: 12 }}>
          <button
            className={`zoomButtonPlus ${this.props.baseMapType}`}
            style={this.props.zoomInDisabled ? zoomDisabledStyle : undefined}
            onClick={!this.props.zoomInDisabled ? this.props.zoomIn : null}
          >
            +
          </button>
          <button
            className={`zoomButtonMinus ${this.props.baseMapType}`}
            style={this.props.zoomOutDisabled ? zoomDisabledStyle : undefined}
            onClick={!this.props.zoomOutDisabled ? this.props.zoomOut : null}
          >
            -
          </button>
        </div>
        <footer
          style={this.props.baseMapType === 'streets' ? streetsFooter : defaultFooter}
          id="info"
          className="footer"
        />
        {this.state.initialMapboxStyle && this.state.center && (
          <MapComponent
            style={this.state.initialMapboxStyle}
            center={center}
            bearing={0}
            pitch={0}
            zoom={this.props.zoom}
            containerStyle={{ height: '100%', width: '100%' }}
            onClick={this.onClick}
            // onHover={this.onHover}
            onStyleLoad={this.handleStyleLoad}
            movingMethod="easeTo"
            onMoveEnd={this.setViewportOnMoveEnd.bind(this)}
            onStyleImageMissing={this.onStyleImageMissing}
          >
            {Object.entries(customIconImages).map(([iconName, imageElement]) => (
              <MapImage key={iconName} id={iconName} data={imageElement} />
            ))}
            {this.props.showSpinner && !this.props.addingNewFeature && (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  top: '50%',
                  transform: this.props.detailWindowOpen ? `translate(-${DETAIL_WINDOW_WIDTH / 2}px, 0px)` : null,
                }}
              >
                <Spinner style={{ width: '10vmax', height: '10vmax' }} />
              </div>
            )}
            {this.props.geometryEditingWindowOpen &&
              (this.props.currentGeometryType === geometryTypes.POLYGON ||
                this.props.currentGeometryType === geometryTypes.MULTIPOLYGON ||
                this.props.currentGeometryType === geometryTypes.LINE ||
                this.props.currentGeometryType === geometryTypes.LINESTRING) && (
                <DrawControl
                  ref={drawControl => {
                    this.drawControl = drawControl
                  }}
                  clickBuffer={4}
                  defaultMode={this.getDefaultDrawMode(this.props.currentGeometryType)}
                  controls={{
                    point: false,
                    line_string: false,
                    polygon: false,
                    trash: false,
                    combine_features: false,
                    uncombine_features: false,
                  }}
                  onDrawCreate={e => this.determineDrawUpdate(e)}
                />
              )}
            {this.props.editGeometryMessage &&
            this.state.popupCoords &&
            this.state.showPopup &&
            this.props.geometryEditingWindowOpen ? (
              <Popup coordinates={this.state.popupCoords} style={{ opacity: '0.8' }}>
                <h1 style={{ fontSize: '12px', margin: '0px' }}>{this.props.editGeometryMessage}</h1>
              </Popup>
            ) : null}
            )}
          </MapComponent>
        )}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IvReactMapboxGlMap)
