import * as Sentry from '@sentry/browser'

export default {
  setUser: obj => {
    Sentry.configureScope(scope => {
      scope.setUser(obj)
    })
  },
  captureMessage: Sentry.captureMessage,
  captureException: Sentry.captureException,
  captureEvent: Sentry.captureEvent,
}
