import * as Immutable from 'immutable'
import { CREATE_ALERT, DISMISS_ALERT } from '../constants/ActionTypes'
import { createReducerFromHandlers } from './utils'
import { createSelector } from 'reselect'

const Alert = Immutable.Record({
  // eslint-disable-line new-cap
  alertName: null, // unique identifier for this alert
  title: null,
  message: null,
  options: Immutable.List([]),
})

const initialState = Immutable.List([])

const handlers = {
  [CREATE_ALERT]: (state, action) => {
    const { alertName, title, message, options } = action
    const alert = new Alert({ alertName, title, message, options: Immutable.List(options) })
    return state.push(alert)
  },
  [DISMISS_ALERT]: (state, action) => state.filter(a => a.alertName !== action.alertName),
}

export default createReducerFromHandlers(initialState, handlers)

// selectors
const getAlerts = state => state.alerts
export const getTopAlert = createSelector(
  getAlerts,
  alerts => (alerts.size ? alerts.last() : null)
)
