/* @flow */
import { createSelector } from 'reselect'
import * as Immutable from 'immutable'

export const getMapViewport = (state: Object): Immutable.Map<string, *> => state.mapViewport

// export const getViewportZoom = (state: Object): number => {
//   // let cachedRegion = state.mapViewport.get('cachedRegion')
//   // if (cachedRegion){
//   //   return cachedRegion.zoom
//   // }
//
//   return state.mapViewport && state.mapViewport.get('zoom')
// }

export const getViewportZoom = createSelector(
  state => state.mapViewport,
  mapViewport => {
    return mapViewport && mapViewport.get('zoom')
  }
)

export const getViewportCenter = createSelector(
  state => state.mapViewport,
  mapViewport => {
    let lngLat = [mapViewport.get('longitude'), mapViewport.get('latitude')]
    return lngLat
  }
)

export const getViewportWidth = (state: Object): number => state.mapViewport && state.mapViewport.get('width')

export const getViewportHeight = (state: Object): number => state.mapViewport && state.mapViewport.get('height')
export const getZoomInDisabled = createSelector(
  getViewportZoom,
  zoom => zoom === 20
)

export const getZoomOutDisabled = createSelector(
  getViewportZoom,
  zoom => zoom === 0
)
